<template>
  <div>
    <v-toolbar
      :absolute="$vuetify.breakpoint.mdAndUp"
      dense
      :class="{
        'abs-right pt-1': $vuetify.breakpoint.smAndUp,
        'd-flex ': $vuetify.breakpoint.lgAndDown,
        'justify-end': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
        'justify-center': $vuetify.breakpoint.smAndDown,
      }"
      elevation="0"
    >
      <v-btn ref="openModalBtn" small color="primary" @click="openVitalSignsManualEntryModal">
        {{ $t('entryData') }}
      </v-btn>
    </v-toolbar>

    <VitalSignsManualEntry
      v-if="showVitalSignsManualEntry"
      ref="vitalSignsManualEntry"
      :patient-id="patientId"
      :default-observations="defaultObservations"
      :show-manual-entry="showVitalSignsManualEntry"
      @updateChartData="updateChartData"
      @closeManualEntryModal="closeManualEntryModal"
    />
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import VitalSignsManualEntry from './VitalSignsManualEntry.vue';
import filterMixin from '../filterMixin';

import { ActivityTypes } from '@/components/PatientMonitoring/constants';

export default {
  name: 'ManualEntryButton',
  components: { VitalSignsManualEntry },
  mixins: [filterMixin, translationMixin],

  props: {
    patientId: {
      type: Number,
      required: true,
    },
    activityTypeCodes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      defaultObservations: null,
      showVitalSignsManualEntry: false,
    };
  },

  created() {
    this.defaultObservations = this.getManualEntryDefaultObservations(this.activityTypeCodes);
  },

  methods: {
    getManualEntryDefaultObservations: function (activityTypeCodes) {
      const observations = {
        [ActivityTypes.APR]: [
          { name: 'Systolic', code: ActivityTypes.APR, value: null },
          { name: 'Diastolic', code: ActivityTypes.APR, value: null },
        ],
        [ActivityTypes.CFR]: [{ name: 'Pulse', code: ActivityTypes.CFR, value: null }],
        [ActivityTypes.SAT]: [{ name: 'Saturation', code: ActivityTypes.SAT, value: null }],
        [ActivityTypes.RES]: [{ name: 'RespiratoryRate', code: ActivityTypes.RES, value: null }],
        [ActivityTypes.BDT]: [{ name: 'BodyTemperature', code: ActivityTypes.BDT, value: null }],
        [ActivityTypes.WEI]: [{ name: 'Weight', code: ActivityTypes.WEI, unitType: 'kg', value: null }],
        [ActivityTypes.GLY]: [{ name: 'CapillaryGlycemia', code: ActivityTypes.GLY, value: null }],
      };

      return Object.keys(observations)
        .filter((key) => activityTypeCodes.includes(key))
        .reduce((acc, key) => {
          acc[key] = observations[key];
          return acc;
        }, {});
    },

    openVitalSignsManualEntryModal: function () {
      Object.values(this.defaultObservations).forEach((observations) => {
        observations.forEach((obs) => {
          obs.value = null;

          if (obs.name === 'Weight') {
            obs.unitType = 'kg';
          } else if (obs.name === 'BodyTemperature') {
            obs.unitType = '°C';
          }
        });
      });

      this.showVitalSignsManualEntry = !this.showVitalSignsManualEntry;
    },

    updateChartData: function (activityCodes) {
      this.$emit('updateChartData', activityCodes);
    },

    closeManualEntryModal: function () {
      this.showVitalSignsManualEntry = false;
    },
  },
};
</script>

<style scoped>
.abs-right {
  right: 4px;
}
</style>
