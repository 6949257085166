<template>
  <v-dialog
    v-if="showManualEntry"
    ref="manualEntryDialog"
    value="showManualEntry"
    scrollable
    persistent
    max-width="700px"
  >
    <v-card>
      <ErrorModal :error="error" @close-error-modal="error = null" />
      <WaitModal :show="isProcessing" />

      <ModalTitle :title="$t('manualEntry')" />

      <v-card-text>
        <v-form ref="manualEntryForm" lazy-validation>
          <v-row>
            <v-col cols="12">
              <DateTimePickerField
                ref="manualEntryDateTime"
                v-model="dataDateTime"
                :max-date="new Date()"
                :label="$t('dataDateTime')"
                picker-type="dateTime"
                required
              />
            </v-col>
          </v-row>

          <div v-for="(activityType, activityTypeIndex) in activityTypesVal" :key="activityTypeIndex">
            <v-divider class="mb-6" />

            <v-row dense class="mb-5">
              <v-col cols="11">
                <v-autocomplete
                  ref="observationType"
                  v-model="activityType.code"
                  class="required-indicator mb-2"
                  :items="formattedDefaultObservations"
                  :label="$t('activityType')"
                  :rules="[validationRules.required]"
                  filled
                  hide-details
                  item-text="text"
                  item-value="value"
                  :item-disabled="isItemDisabled"
                  :disabled="formattedDefaultObservations.length <= 1"
                  @change="setObservationsToCreate()"
                />
              </v-col>

              <v-col v-if="formattedDefaultObservations.length > 1" class="d-flex align-center" cols="auto">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      :disabled="activityTypesVal.length === 1"
                      color="primary"
                      icon
                      @click="deleteActivityTypeObservation(activityType)"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('delete') }}
                </v-tooltip>
              </v-col>
            </v-row>

            <template v-if="activityType.code">
              <v-row
                v-for="(observation, obsIndex) in observationsToCreate.filter((obs) => obs.code === activityType.code)"
                :key="obsIndex"
                dense
              >
                <v-col cols="11">
                  <v-text-field
                    ref="manualEntryValue"
                    v-model="observation.value"
                    class="required-indicator"
                    :label="$t(`${observation.name}Value`)"
                    :rules="[
                      validationRules.required,
                      observation.name === 'Saturation' ? validationRules.atMost(100) : validationRules.atMost(999),
                      validationRules.atLeast(0),
                      ['BodyTemperature', 'CapillaryGlycemia', 'Weight'].includes(activityType.name)
                        ? true
                        : validationRules.integer,
                    ]"
                    :suffix="observation.unitType ? observation.unitType : $t(`${observation.name}Suffix`)"
                    :append-icon="['Weight', 'BodyTemperature'].includes(observation.name) ? 'mdi-autorenew' : ''"
                    type="number"
                    hide-spin-buttons
                    filled
                    dense
                    @click:append="
                      observation.unitType =
                        observation.name === 'Weight'
                          ? observation.unitType === 'kg'
                            ? 'lb'
                            : 'kg'
                          : observation.unitType === '°C'
                          ? '°F'
                          : '°C'
                    "
                  />
                </v-col>
              </v-row>
            </template>
          </div>

          <RequiredFieldsLegend />
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          v-if="Object.keys(defaultObservations).length > 1"
          small
          color="primary"
          @click="addActivityTypeObservation"
          >{{ $t('addManualEntryData') }}</v-btn
        >

        <v-spacer></v-spacer>

        <v-btn text @click="closeManualEntryModal">{{ $t('cancel') }}</v-btn>

        <SaveButton
          :is-loading="isLoading"
          :is-processing="isProcessing"
          :show-wait-modal="showWaitModal"
          :handle-click="saveManualEntryData"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SaveButton from '@/components/SaveButton.vue';
import patientMonitoringService from '@/services/patientMonitoringService';
import translationMixin from '@/translationMixin';
import validationRulesMixin from '@/validationRulesMixin';
import { format } from 'date-fns';

export default {
  name: 'VitalSignsManualEntry',
  components: { SaveButton },
  mixins: [translationMixin, validationRulesMixin],
  props: {
    patientId: {
      type: Number,
      required: true,
    },

    defaultObservations: {
      type: Object,
      required: true,
    },

    showManualEntry: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dataDateTime: format(new Date().setSeconds(0), 'yyyy-MM-dd HH:mm:ss'),
      isProcessing: false,
      isLoading: false,
      showWaitModal: false,
      error: null,
      activityTypesVal: [],
      observationsToCreate: [],
    };
  },

  computed: {
    formattedDefaultObservations: function () {
      return Object.keys(this.defaultObservations)
        .map((key) => ({
          text: this.$t(key + 'FullName'),
          value: key,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },

  created() {
    if (Object.keys(this.defaultObservations).length === 1) {
      const defaultObservation = Object.values(this.defaultObservations)[0];
      this.activityTypesVal = defaultObservation;
      this.setObservationsToCreate();
    } else {
      this.addActivityTypeObservation();
    }
  },

  methods: {
    saveManualEntryData: async function () {
      if (!this.$refs.manualEntryForm.validate()) return;

      this.isProcessing = true;

      try {
        this.convertData();

        const data = {
          patientId: this.patientId,
          dateTime: format(new Date(this.dataDateTime), 'yyyy-MM-dd HH:mm:ssxxx'),
          observations: this.observationsToCreate,
        };

        await patientMonitoringService.saveManualEntryVitalSigns(data);

        this.$emit(
          'updateChartData',
          this.activityTypesVal.map((obs) => obs.code)
        );
        this.closeManualEntryModal();
      } catch (error) {
        this.error = error;
      }

      this.isProcessing = false;
    },

    deleteActivityTypeObservation: function (activityType) {
      const indexToRemove = this.activityTypesVal.indexOf(activityType);
      this.activityTypesVal.splice(indexToRemove, 1);
    },

    addActivityTypeObservation: function () {
      this.activityTypesVal.push({});
    },

    isItemDisabled: function (item) {
      return this.activityTypesVal.some((x) => x.code === item.value);
    },

    setObservationsToCreate: function () {
      this.observationsToCreate = this.activityTypesVal
        .filter((x) => !!x.code)
        .flatMap((activity) => this.defaultObservations[activity.code]);
    },

    convertData: function () {
      this.observationsToCreate.forEach((x) => {
        if (x?.unitType === 'lb') {
          x.value = parseFloat((x.value / 2.205).toFixed(2));
        }

        if (x?.unitType === '°F') {
          x.value = parseFloat(((x.value - 32) / 1.8).toFixed(2));
        }
      });
    },

    closeManualEntryModal: function () {
      this.$emit('closeManualEntryModal');
    },
  },
};
</script>
