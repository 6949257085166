export const chartColor = {
  correctFirstDataColor: '#4A94E6',
  correctSecondaryDataColor: '#F39308',
  hoveredCorrectFirstDataColor: '#327BCE',
  hoveredCorrectSecondaryDataColor: '#E38A07',
  mainBorderLegendColor: '#5A9CE5',
  secondaryBorderLegendColor: '#F2B04E',
  incorrectDataColor: '#ff0000',
  hoveredIncorrectDataColor: '#DE0000',
  textLabel: '#fff',
  backgroundThreshold: 'transparent',
  delimiterBorderColor: 'rgba(0, 0, 0, 0.25)',

  systolic: '#0965cb',
  diastolic: '#56a1f5',
  cardiacFrequency: '#4CAF50',
  saturation: '#F39308',
  respiratoryRate: '#3F51B5',
  bodyTemperatyre: '#bb14bb',
  ECG: '#69BCFF',
  unknownCode: '#333333',
};

export const chartTypes = {
  monitoringBarChart: 'monitoringBarChart',
  monitoringLineChart: 'monitoringLineChart',
  monitoringLabelLineChart: 'monitoringLabelLineChart',
  realtimeLineChart: 'realtimeLineChart',
};

export const filterTypes = {
  last24H: '24h',
};

export const legendTypes = {
  threshold: 'threshold',
  title: 'title',
};

export const thresholdType = {
  alertTriggered: 'alertTriggered',
  biggerThan: 'biggerThan',
  lesserThan: '  lesserThan',
  variation: 'variation',
};

export const defaultTooltipConfiguration = {
  // Currently unused but if we ever need to reuse the default tooltip, this configuration will approximately make the tooltip look like the custom one

  boxWidth: 10,
  boxHeight: 10,
  boxPadding: 3,
  backgroundColor: 'rgba(0, 0, 0, 0.75)',

  titleFont: {
    size: 13,
    family: "'Roboto', sans-serif",
    weight: 'bold',
  },

  titleAlign: 'center',
  titleMarginBottom: 10,

  bodyFont: {
    size: 12,
    family: "'Roboto', sans-serif",
  },

  bodyAlign: 'center',
  padding: 10,
  cornerRadius: 10,

  footerFont: {
    size: 12,
    family: "'Roboto', sans-serif",
    weight: 'bold',
  },

  footerAlign: 'center',
  footerMarginTop: 2,
};
