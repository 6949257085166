<template>
  <v-card class="monitoring-details-chart-card">
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="!chartParameters" class="pt-8" />

    <template v-if="chartParameters">
      <ManualEntryButton
        v-if="hasPatientIotDevice === false"
        :patient-id="patientId"
        :activity-type-codes="[activityTypes.WEI]"
        @updateChartData="getWeightData"
      />

      <MonitoringChartContainer
        ref="monitoringLabelLineChart"
        :activity-code="activityTypes.WEI"
        :activity-name="activityName"
        :chart-duration="chartDuration"
        :chart-type="chartType"
        :filters="filters"
        :from-notification="fromNotification"
        :is-processing="showWaitModal"
        :selected-unit="selectedUnitWeight"
        :thresholds="patientThresholds"
        :unique-key="activityTypes.WEI"
        :values="patientValues"
      />

      <v-row class="px-4 no-print">
        <v-col cols="auto">
          <v-select
            ref="unitWeight"
            v-model="selectedUnitWeight"
            item-text="name"
            item-value="code"
            :label="$t('unitWeight')"
            outlined
            dense
            hide-details
            :items="availableUnitsWeight"
            @change="convertUnitWeight()"
          />
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
import patientMonitoringService from '@/services/patientMonitoringService';
import translationMixin, { LanguageVue } from '@/translationMixin';
import ManualEntryButton from '../ManualEntry/ManualEntryButton.vue';
import filterMixin from '../filterMixin';
import MonitoringChartContainer from '../Chart/MonitoringChartContainer.vue';
import { chartTypes } from '@/components/PatientMonitoring/Chart/utils/constants.js';

import { ActivityMonitoringValues, ActivityTypes, unitTypes } from '@/components/PatientMonitoring/constants';

export default {
  name: 'PatientWeight',
  components: { ManualEntryButton, MonitoringChartContainer },
  mixins: [filterMixin, translationMixin],

  props: {
    activityId: { type: Number, default: null },
    activityName: { type: String, default: null },
    filters: { type: Object, default: null },
    patientId: { type: Number, default: null },
  },

  data() {
    return {
      activityTypes: ActivityTypes,
      chartParameters: null,
      chartType: chartTypes.monitoringLabelLineChart,
      patientData: null,
      patientValues: [],
      patientThresholds: [],
      originalPatientValues: null,
      hasPatientIotDevice: null,
      chartDuration: null,
      showWaitModal: false,
      error: null,
      requestFilters: null,
      fromNotification: false,
      selectedUnitWeight: unitTypes.kg,
      availableUnitsWeight: [],
    };
  },

  computed: {
    dateFrom() {
      return this.calculateChartDate('start');
    },
    dateTo() {
      return this.calculateChartDate('end');
    },
  },

  watch: {
    patientId: async function () {
      this.patientThresholds = [];
      await this.getWeightData();
    },

    filters: async function () {
      await this.getWeightData();
    },

    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          if ((oldVal && oldVal.fromNotification) || (newVal && newVal.fromNotification)) {
            this.$emit('update:request-filters', newVal);
            this.fromNotification = true;
          }
        }
      },
      deep: true,
    },
  },

  async created() {
    LanguageVue.$on('projectLanguage', this.setAvailableWeightUnits);

    this.fromNotification = this.requestFilters !== null;
    this.setAvailableWeightUnits();
    await this.getWeightData();
  },

  beforeDestroy() {
    LanguageVue.$off('projectLanguage', this.setAvailableWeightUnits);
  },

  methods: {
    async getWeightData() {
      this.showWaitModal = true;

      this.patientValues = [];

      this.requestFilters = {
        ...this.setRequestFilters(),
        statusLevelIds: this.filters?.statusLevelIds,
      };

      try {
        const monitoredActivityTypeCodes = [ActivityTypes.WEI];
        this.chartParameters = await patientMonitoringService.getPatientMonitoringChartParameters({
          ...this.requestFilters,
          monitoredActivityTypeCodes,
        });

        this.hasPatientIotDevice = this.chartParameters.hasPatientIotDevice;
        this.chartDuration = this.chartParameters.duration;

        this.patientData = await patientMonitoringService.getPatientWeight({
          ...this.requestFilters,
          ...this.chartParameters,
        });

        this.originalPatientValues = this.patientData.results.flat();
        this.patientValues = this.originalPatientValues;

        const thresholds = this.patientData.thresholds[ActivityMonitoringValues.Weight];

        if (this.selectedUnitWeight === unitTypes.lbs) {
          this.convertUnitWeight(thresholds);
        } else {
          this.setThresholdsOnUnitType(thresholds);
        }
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    convertUnitWeight: function (thresholds = JSON.parse(JSON.stringify(this.patientThresholds))) {
      this.patientValues = this.originalPatientValues.map((weight) => {
        return {
          time: weight.time,
          values: weight.values.map((weightValue) => {
            return {
              ...weightValue,
              value:
                weightValue.value && this.selectedUnitWeight === unitTypes.lbs
                  ? parseFloat((weightValue.value * 2.2).toFixed(1))
                  : parseFloat(weightValue.value.toFixed(1)),
            };
          }),
        };
      });

      this.setThresholdsOnUnitType(thresholds);
    },

    setThresholdsOnUnitType: function (thresholds) {
      const finalThresholds = thresholds.map((threshold) => {
        if (this.selectedUnitWeight === unitTypes.lbs && threshold.unit === unitTypes.kg) {
          threshold.value = parseFloat((threshold.value * 2.2).toFixed(1));
          threshold.convertedFrom = threshold.unit;
          threshold.convertedUnit = this.selectedUnitWeight;
        } else if (
          this.selectedUnitWeight === unitTypes.kg &&
          threshold.unit === unitTypes.kg &&
          threshold.convertedFrom
        ) {
          threshold.value = parseFloat((threshold.value / 2.2).toFixed(1));
          threshold.convertedUnit = null;
          threshold.convertedFrom = false;
        } else if (this.selectedUnitWeight === unitTypes.kg && threshold.unit === unitTypes.lbs) {
          threshold.value = parseFloat((threshold.value / 2.2).toFixed(1));
          threshold.convertedFrom = threshold.unit;
          threshold.convertedUnit = this.selectedUnitWeight;
        } else if (
          this.selectedUnitWeight === unitTypes.lbs &&
          threshold.unit === unitTypes.lbs &&
          threshold.convertedFrom
        ) {
          threshold.value = parseFloat((threshold.value * 2.2).toFixed(1));
          threshold.convertedUnit = null;
          threshold.convertedFrom = false;
        }

        return threshold;
      });

      if (JSON.stringify(this.patientThresholds) !== JSON.stringify(finalThresholds)) {
        this.patientThresholds = finalThresholds;
      }
    },

    setAvailableWeightUnits: function () {
      this.availableUnitsWeight = [
        {
          code: unitTypes.kg,
          name: this.$t('kilogram'),
        },
        {
          code: unitTypes.lbs,
          name: this.$t('pound'),
        },
      ];
    },
  },
};
</script>
