import Vue from 'vue';

import {
  addDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import { frCA, enCA } from 'date-fns/locale';

import { ActivityMonitoringValues, ActivityTypes, ObservationTypes } from '@/components/PatientMonitoring/constants.js';
import { chartColor, chartTypes, legendTypes, thresholdType } from './constants';
import { StatsDuration } from '@/components/PatientMonitoring/constants.js';

import translationMixin from '@/translationMixin';

const translationInstance = new Vue({
  mixins: [translationMixin],
});

const createDataMonitoringWorkerUrl = '/static/workers/monitoringChartWorker.js';

const chartDefaultYAxisOnActivityCode = {
  [ActivityTypes.APR]: { yMin: 0, yMax: 220, yStepSize: 20 },
  [ActivityTypes.CFR]: { yMin: 0, yMax: 180, yStepSize: 20 },
  [ActivityTypes.SAT]: { yMin: 70, yMax: 110, yStepSize: 5 },
  [ActivityTypes.RES]: { yMin: 0, yMax: 36, yStepSize: 2 },
  [ActivityTypes.BDT]: { yMin: 34, yMax: 42, yStepSize: 1 },
  [ActivityTypes.GLY]: { yMin: 0, yMax: 30, yStepSize: 3 },
  [ActivityTypes.WEI]: { yMin: 0, yMax: 150, yStepSize: 10 },
  [ActivityTypes.ECG]: { yMin: -1, yMax: 1, yStepSize: 0.2 },
};

function buildThresholdAnnotationTooltip(threshold, monitoringValue, lessOrGreater, unit, thresholdConvertedFrom) {
  const translatedUnit = translationInstance.$t(unit);
  const translatedMonitoringValue = translationInstance.$t(`patientChartUnit.threshold.${monitoringValue}`);
  const translatedConvertedFrom = translationInstance.$t(thresholdConvertedFrom);

  const convertedFromTranslation = `${
    thresholdConvertedFrom && translatedConvertedFrom !== translatedUnit
      ? `(${translationInstance.$t('chartThreshold.convertedFrom')} ${translatedConvertedFrom} ${translationInstance.$t(
          'chartThreshold.convertedTo'
        )} ${translatedUnit})`
      : ''
  }`;

  return `${translationInstance.$t(
    'patientChartUnit.thresholdValue'
  )} ${translatedMonitoringValue} ${lessOrGreater} ${threshold}${
    translatedUnit.includes('°') || translatedUnit.includes('%') ? '' : ' '
  }${translatedUnit} ${convertedFromTranslation}`;
}

function createAnnotationThreshold(
  activityCode,
  showOrHideAnnotationTooltipCallback,
  chartOptions,
  threshold,
  monitoringValue,
  thresholdIndex,
  thresholdType,
  color,
  thresholdConvertedFrom,
  unit
) {
  const lessOrGreaterThan = thresholdType === 'biggerThan' ? '>' : thresholdType === 'lesserThan' ? '<' : null;

  if (lessOrGreaterThan) {
    chartOptions.plugins.annotation.annotations[thresholdIndex] = getAnnotationContentForThreshold(
      showOrHideAnnotationTooltipCallback,
      threshold,
      thresholdIndex,
      monitoringValue,
      lessOrGreaterThan,
      color,
      unit ?? `${activityCode}Unit`,
      thresholdConvertedFrom
    );
  }
}

function getTooltipStatsInfo(data, activityCode) {
  const yValues = data.raw.y;
  const valuesCount = data.raw.count;
  const averageValue = data.raw.average;

  const chartUnit = translationInstance.$t(activityCode + 'Unit');

  const spaceLabelValue =
    translationInstance.$t(chartUnit) === '%' || translationInstance.$t(chartUnit) === '°C' ? '' : ' ';

  return [
    {
      observationType: data.raw.observationType,
      maximum: translationInstance
        .$t('spaceColon')
        .replace('{label}', translationInstance.$t('maximum'))
        .replace('{value}', `${Math.max(...yValues)}${spaceLabelValue}${chartUnit}`),
      average: translationInstance
        .$t('spaceColon')
        .replace('{label}', translationInstance.$t('average'))
        .replace('{value}', `${averageValue}${spaceLabelValue}${chartUnit}`),
      minimum: translationInstance
        .$t('spaceColon')
        .replace('{label}', translationInstance.$t('minimum'))
        .replace('{value}', `${Math.min(...yValues)}${spaceLabelValue}${chartUnit}`),
      count: translationInstance
        .$t('spaceColon')
        .replace('{label}', translationInstance.$t('valuesNumber'))
        .replace('{value}', valuesCount),
    },
  ];
}

function getAnnotationContentForThreshold(
  showOrHideAnnotationTooltipCallback,
  threshold,
  thresholdIndex,
  monitoringValue,
  lessOrGreater,
  color,
  unit,
  thresholdConvertedFrom
) {
  return {
    display: true,
    type: 'line',
    mode: 'horizontal',
    borderDash: monitoringValue === ActivityMonitoringValues.Diastolic ? [7, 7] : [13, 7],
    yMin: threshold,
    yMax: threshold,
    borderColor: color,
    borderWidth: monitoringValue === ActivityMonitoringValues.Diastolic ? 1.1 : 1.2,
    drawTime: 'beforeDatasetsDraw',
    label: {
      display: false,
      drawTime: 'afterDatasetsDraw',
      backgroundColor: color,
      threshold: threshold,
      lessOrGreater: lessOrGreater,
      unit: unit,
      thresholdConvertedFrom: thresholdConvertedFrom,
      monitoringValue: monitoringValue,
      content: buildThresholdAnnotationTooltip(threshold, monitoringValue, lessOrGreater, unit, thresholdConvertedFrom),
    },
    enter: (data, event) => {
      showOrHideAnnotationTooltipCallback(data, event, thresholdIndex, true);
    },
    leave: (data, event) => {
      showOrHideAnnotationTooltipCallback(data, event, thresholdIndex, false);
    },
  };
}

const observationTypeColors = {
  [ObservationTypes.Systolic]: '#0965cb',
  [ObservationTypes.Diastolic]: '#56a1f5',
  [ObservationTypes.Pulse]: '#4CAF50',
  [ObservationTypes.Saturation]: '#F39308',
  [ObservationTypes.RespiratoryRate]: '#3F51B5',
  [ObservationTypes.BodyTemperature]: '#bb14bb',
};

function showOrHideAnnotationTooltip(chartOptions, data, event, thresholdIndex, showAnnotationTooltip) {
  chartOptions.plugins.annotation.annotations[thresholdIndex].borderWidth = showAnnotationTooltip ? 2 : 1;
  chartOptions.plugins.annotation.annotations[thresholdIndex].label.display = showAnnotationTooltip;

  chartOptions.plugins.annotation.annotations[thresholdIndex].label.position = `${
    (event.x / data.chart.chartArea.width) * 100
  }%`;
}

function getChartTooltipLabel(data) {
  if (data.chart.config._config.type === 'line' || data.dataset.type === 'line') {
    return getLineChartTooltipLabel(
      data.dataset.vitalSignLabel,
      data.formattedValue,
      data.chart.config._config?.options?.selectedUnit ?? `${data.dataset.activityCode}Unit`
    );
  } else if (data.chart.config._config.type === 'bar' && data.dataset.activityCode !== ActivityTypes.ECG) {
    return getTooltipStatsInfo(data, data.dataset.activityCode);
  }

  return '';
}

function getLineChartTooltipLabel(label, value, chartUnit) {
  const spaceLabelValue =
    translationInstance.$t(chartUnit) === '%' || translationInstance.$t(chartUnit) === '°C' ? '' : ' ';

  return (
    `${translationInstance.$t(label)}${translationInstance.getLanguage() === 'fr' ? ' : ' : ': '}${value}` +
    spaceLabelValue +
    translationInstance.$t(chartUnit)
  );
}

function getDatasetColorOnCode(code) {
  switch (code) {
    case ActivityMonitoringValues.Systolic:
    case ObservationTypes.Systolic:
      return chartColor.systolic;

    case ActivityMonitoringValues.Diastolic:
    case ObservationTypes.Diastolic:
      return chartColor.diastolic;

    case ActivityTypes.CFR:
    case ActivityMonitoringValues.BeatsPerMin:
    case ObservationTypes.Pulse:
      return chartColor.cardiacFrequency;

    case ActivityTypes.SAT:
    case ActivityMonitoringValues.Saturation:
    case ObservationTypes.Saturation:
      return chartColor.saturation;

    case ActivityTypes.RES:
    case ActivityMonitoringValues.Breathing:
    case ObservationTypes.RespiratoryRate:
      return chartColor.respiratoryRate;

    case ActivityTypes.BDT:
    case ActivityMonitoringValues.BodyTemperature:
    case ObservationTypes.BodyTemperature:
      return chartColor.bodyTemperatyre;

    case ActivityTypes.ECG:
      return chartColor.ECG;

    case ActivityTypes.WEI:
    case ActivityTypes.GLY:
    case ObservationTypes.Weight:
    case ObservationTypes.CapillaryGlycemia:
      return chartColor.correctFirstDataColor;

    default:
      return chartColor.unknownCode;
  }
}

function getDatetimeTranslation(data) {
  const defaultTranslation = translationInstance.getLanguage() === 'fr' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd hh:mm aa';
  const ecgTranslation =
    translationInstance.getLanguage() === 'fr' ? 'yyyy-MM-dd HH:mm:ss.SSS' : 'yyyy-MM-dd HH:mm:ss.SSS aa';

  return format(
    new Date(data[0].raw.x),
    data[0].dataset.type === 'line' && data[0].dataset.activityCode === ActivityTypes.ECG
      ? ecgTranslation
      : defaultTranslation
  );
}

function getFormattedLabelDate(data) {
  const duration = data[0].chart.config._config?.options?.duration;

  switch (duration) {
    case StatsDuration.H24:
      return translationInstance.getLanguage() === 'fr'
        ? format(new Date(data[0].raw.x), 'yyyy-MM-dd HH:mm:ss.SSS')
        : format(new Date(data[0].raw.x), 'yyyy-MM-dd HH:mm:ss.SSS aa');

    case StatsDuration.HOUR:
      return translationInstance.getLanguage() === 'fr'
        ? format(new Date(data[0].raw.x), 'yyyy-MM-dd HH:mm:ss')
        : format(new Date(data[0].raw.x), 'yyyy-MM-dd hh:mm:ss b');

    case StatsDuration.DAY:
      return format(new Date(data[0].raw.x), 'yyyy-MM-dd');

    case StatsDuration.WEEK: {
      let language = translationInstance.getLanguage() === 'fr' ? frCA : enCA;
      let dateTimeFormat = translationInstance.getLanguage() === 'fr' ? 'd MMMM yyyy' : 'MMMM d,  yyyy';

      return (
        translationInstance.$t('weekOf') + ' ' + format(new Date(data[0].raw.x), dateTimeFormat, { locale: language })
      );
    }

    case StatsDuration.MONTH: {
      let monthTitle = format(new Date(data[0].raw.x), 'MMMM yyyy', {
        locale: translationInstance.getLanguage() === 'fr' ? frCA : enCA,
      });

      return monthTitle.charAt(0).toUpperCase() + monthTitle.slice(1);
    }

    case StatsDuration.YEAR:
      return format(new Date(data[0].raw.x), 'yyyy');
  }
}

const monitoringChartDefaultYAxis = {
  [ActivityTypes.APR]: { yMin: 0, yMax: 220, yStepSize: 20 },
  [ActivityTypes.CFR]: { yMin: 0, yMax: 180, yStepSize: 20 },
  [ActivityTypes.SAT]: { yMin: 70, yMax: 110, yStepSize: 5 },
  [ActivityTypes.RES]: { yMin: 0, yMax: 36, yStepSize: 2 },
  [ActivityTypes.BDT]: { yMin: 34, yMax: 42, yStepSize: 1 },
  [ActivityTypes.GLY]: { yMin: 0, yMax: 30, yStepSize: 3 },
  [ActivityTypes.WEI]: { yMin: 0, yMax: 150, yStepSize: 10 },
  [ActivityTypes.ECG]: { yMin: -1, yMax: 1, yStepSize: 0.2 },
};

function getMonitoringChartSeriesNames(activityTypeCode) {
  const chartLegendOnActivityCode = {
    [ActivityTypes.APR]: [ObservationTypes.Systolic, ObservationTypes.Diastolic],
    [ActivityTypes.CFR]: [ObservationTypes.Pulse],
    [ActivityTypes.SAT]: [ObservationTypes.Saturation],
    [ActivityTypes.RES]: [ObservationTypes.RespiratoryRate],
    [ActivityTypes.BDT]: [ObservationTypes.BodyTemperature],
    [ActivityTypes.GLY]: [ObservationTypes.CapillaryGlycemia],
    [ActivityTypes.WEI]: [ObservationTypes.Weight],
    [ActivityTypes.ECG]: [ObservationTypes.ECG],
  };

  return chartLegendOnActivityCode[activityTypeCode];
}

function removeChartTime(currentDate, timeFilterType, timeFilterValue) {
  if (timeFilterType === 'days') {
    timeFilterValue *= 24;
  }

  return new Date(currentDate.setHours(currentDate.getHours() - timeFilterValue));
}

function getVitalSignsChartColor(code) {
  switch (code) {
    case ActivityMonitoringValues.Systolic:
    case ObservationTypes.Systolic:
      return '#0965cb';

    case ActivityMonitoringValues.Diastolic:
    case ObservationTypes.Diastolic:
      return '#56a1f5';

    case ActivityTypes.CFR:
    case ActivityMonitoringValues.BeatsPerMin:
    case ObservationTypes.Pulse:
      return '#4CAF50';

    case ActivityTypes.SAT:
    case ActivityMonitoringValues.Saturation:
    case ObservationTypes.Saturation:
      return '#F39308';

    case ActivityTypes.RES:
    case ActivityMonitoringValues.Breathing:
    case ObservationTypes.RespiratoryRate:
      return '#3F51B5';

    case ActivityTypes.BDT:
    case ActivityMonitoringValues.BodyTemperature:
    case ObservationTypes.BodyTemperature:
      return '#bb14bb';

    case ActivityTypes.ECG:
      return '#69BCFF';

    case ActivityTypes.WEI:
    case ActivityTypes.GLY:
    case ObservationTypes.Weight:
    case ObservationTypes.CapillaryGlycemia:
      return chartColor.correctFirstDataColor;

    default:
      return '#333333';
  }
}

function showOrHideSelectedElement(chartData, chartOptions, legendItem) {
  const datasetIndex = legendItem.datasetIndex;
  const chartMonitoringValue = chartData.datasets[datasetIndex].monitoringValue;

  const newDatasetState = !chartData.datasets[datasetIndex].hidden;
  chartData.datasets[datasetIndex].hidden = newDatasetState;

  if (chartMonitoringValue?.toLowerCase()?.includes('threshold')) {
    const annotationKeysToHide = Object.keys(chartOptions.plugins.annotation.annotations).filter((key) =>
      key.startsWith(chartMonitoringValue)
    );

    annotationKeysToHide.forEach((annotationKey) => {
      chartOptions.plugins.annotation.annotations[annotationKey].display = !newDatasetState;
    });
  } else if (chartMonitoringValue === thresholdType.alertTriggered) {
    chartData.datasets
      .filter((x) => !!x.data?.length)
      .forEach((y) => {
        if (y.stack === 'Has Triggered Alert') {
          y.hidden = newDatasetState;
        }

        y?.datalabels
          ? (y.datalabels.display = (data) =>
              newDatasetState ? !data.dataset.data[data.dataIndex]?.hasTriggeredAnAlert : true)
          : undefined;

        y.data.forEach((x, i) => {
          if (x.hasTriggeredAnAlert && y?.pointRadius) y.pointRadius[i] = newDatasetState ? 0 : 1.5;
        });
      });
  } else {
    if (chartOptions.activityCode === ActivityTypes.ECG && chartData.datasets.some((x) => x.type === 'line')) {
      chartData.datasets
        .filter((x) => x.monitoringValue === chartMonitoringValue && x.type === 'line')
        .forEach((y) => {
          y.hidden = newDatasetState;
        });
    } else {
      chartData.datasets
        .filter((x) => x.monitoringValue === chartMonitoringValue)
        .forEach((y) => {
          y.hidden = newDatasetState;
        });
    }
  }
}

function updateAndGetLabelChartThresholdsLegendItems(
  activityCode,
  showOrHideAnnotationTooltipCallback,
  chartOptions,
  thresholds
) {
  const monitoringValue = getMonitoringChartSeriesNames(activityCode);

  thresholds.forEach((monitoringValueThreshold, thresholdIndex) => {
    createAnnotationThreshold(
      activityCode,
      showOrHideAnnotationTooltipCallback,
      chartOptions,
      monitoringValueThreshold.value,
      monitoringValue,
      `${monitoringValue}Threshold${thresholdIndex}`,
      monitoringValueThreshold.type,
      chartColor.correctFirstDataColor,
      monitoringValueThreshold.convertedFrom,
      monitoringValueThreshold.convertedUnit ?? monitoringValueThreshold.unit
    );
  });

  const chartThresholds = {
    label: `${thresholds.length > 1 ? 'plural' : 'singular'}${monitoringValue}Threshold`,
    borderWidth: 1.5,
    borderColor: chartColor.correctFirstDataColor,
    backgroundColor: chartColor.backgroundThreshold,
    borderDash: [12, 5],
    hidden: false,
    legendType: legendTypes.threshold,
    monitoringValue: `${monitoringValue}Threshold`,
  };

  return [chartThresholds];
}

function getSegmentBorderColor(ctx, correctDataColor) {
  const p0Color = ctx.p0.options.backgroundColor;
  const p1Color = ctx.p1.options.backgroundColor;

  return p0Color === chartColor.incorrectDataColor && p1Color === chartColor.incorrectDataColor
    ? chartColor.incorrectDataColor
    : correctDataColor;
}

function updateAndGetLineChartThresholdsLegendItems(
  activityCode,
  showOrHideAnnotationTooltipCallback,
  chartOptions,
  thresholds
) {
  const chartThresholds = Object.entries(thresholds).map(([monitoringValue, monitoringValueThresholds]) => {
    const color = getVitalSignsChartColor(monitoringValue);

    monitoringValueThresholds.forEach((threshold, thresholdIndex) => {
      createAnnotationThreshold(
        activityCode,
        showOrHideAnnotationTooltipCallback,
        chartOptions,
        threshold.value,
        monitoringValue,
        `${monitoringValue}Threshold${thresholdIndex}`,
        threshold.type,
        color,
        threshold.convertedFrom,
        threshold.unit
      );
    });

    const untranslatedLabel = `${
      monitoringValueThresholds.length > 1 ? 'plural' : 'singular'
    }${monitoringValue}Threshold`;

    return {
      label: translationInstance.$t(untranslatedLabel),
      untranslatedLabel: untranslatedLabel,
      backgroundColor: chartColor.backgroundThreshold,
      borderWidth: 1.5,
      borderColor: color,
      borderDash: monitoringValue === ActivityMonitoringValues.Diastolic ? [6, 5] : [12, 5],
      hidden: false,
      legendType: legendTypes.threshold,
      monitoringValue: `${monitoringValue}Threshold`,
      type: 'line',
    };
  });

  return chartThresholds;
}

function updateAndGetChartThresholdsLegendItems(
  chartType,
  activityCode,
  showOrHideAnnotationTooltipCallback,
  chartOptions,
  newThresholds
) {
  switch (chartType) {
    case chartTypes.monitoringLineChart:
    case chartTypes.monitoringBarChart:
      return updateAndGetLineChartThresholdsLegendItems(
        activityCode,
        showOrHideAnnotationTooltipCallback,
        chartOptions,
        newThresholds
      );

    case chartTypes.monitoringLabelLineChart:
      return updateAndGetLabelChartThresholdsLegendItems(
        activityCode,
        showOrHideAnnotationTooltipCallback,
        chartOptions,
        newThresholds
      );

    default:
      return [];
  }
}

function buildDateX2AxisTicks(axis) {
  const midTimestamp = (axis.chart.scales.x2.min + axis.chart.scales.x2.max) / 2;
  const midDatetime = new Date(midTimestamp);

  axis.ticks = [
    {
      value: midDatetime.getTime(),
    },
  ];
}

function updateChartThresholds(
  showOrHideAnnotationTooltipCallback,
  chartType,
  chartData,
  chartOptions,
  activityCode,
  newThresholds,
  oldThresholds
) {
  if (chartData.datasets && JSON.stringify(newThresholds) !== JSON.stringify(oldThresholds)) {
    const lastTitleIndex = chartData.datasets?.findLastIndex((x) => x.legendType === legendTypes.title);

    if (isNaN(parseFloat(lastTitleIndex))) return;

    const newDatasets = [
      ...updateAndGetChartThresholdsLegendItems(
        chartType,
        activityCode,
        showOrHideAnnotationTooltipCallback,
        chartOptions,
        newThresholds
      ),
    ];

    chartData.datasets
      .filter((dataset) => !!dataset.hidden)
      .forEach((hiddenDataset) => {
        if (hiddenDataset.monitoringValue?.toLowerCase()?.includes(legendTypes.threshold)) {
          const annotationKeysToHide = Object.keys(chartOptions.plugins.annotation.annotations).filter((key) =>
            key.startsWith(hiddenDataset.monitoringValue)
          );

          annotationKeysToHide.forEach((annotationKey) => {
            chartOptions.plugins.annotation.annotations[annotationKey].display = !hiddenDataset.hidden;
          });
        }
      });

    chartData.datasets = chartData.datasets.filter((x) => x.legendType !== legendTypes.threshold);
    chartData.datasets.splice(lastTitleIndex + 1, 0, ...newDatasets);
  }
}

function updateYAxisLimits(chartOptions, yAxisLimits) {
  chartOptions.scales.y.min = yAxisLimits.yMin;
  chartOptions.scales.y.max = yAxisLimits.yMax;
  chartOptions.scales.y.ticks.stepSize = yAxisLimits.yStepSize;
}

function getLineChartAxisVisibleTicks(visibleXAxis, time) {
  const minute = time.split(' ')[0].split(':')[1];
  let visibleMinutes = ['00'];

  if (visibleXAxis) {
    const xAxisTimeDifferenceSS = differenceInSeconds(new Date(visibleXAxis.max), new Date(visibleXAxis.min));

    if (xAxisTimeDifferenceSS < 300) return time;

    const xAxisTimeDifference = differenceInMinutes(new Date(visibleXAxis.max), new Date(visibleXAxis.min));

    if (xAxisTimeDifference <= 60) {
      return time;
    }

    if (differenceInHours(new Date(visibleXAxis.max), new Date(visibleXAxis.min)) <= 7) {
      visibleMinutes = ['00', '15', '30', '45'];
    }

    if (visibleMinutes.includes(minute)) {
      return time;
    }
  }
}

function getBarChartXAxisValues(value, duration, useValueDateForDay = false) {
  const valueDate = new Date(value);
  const valueDateForDay = new Date(`${value}T00:00:00`);

  const isValidDate = useValueDateForDay
    ? valueDateForDay instanceof Date && !isNaN(valueDateForDay)
    : valueDate instanceof Date && !isNaN(valueDate);

  if (!isValidDate) {
    return value;
  }

  const language = translationInstance.getLanguage() === 'fr' ? frCA : enCA;

  const dateTimeFormat = translationInstance.getLanguage() === 'fr' ? 'd MMM' : 'MMM do';
  const dateToFormat = useValueDateForDay ? valueDateForDay : valueDate;

  switch (duration) {
    case StatsDuration.DAY:
      return format(dateToFormat, dateTimeFormat, { locale: language });

    case StatsDuration.WEEK:
      return translationInstance.$t('weekOf') + ' ' + format(valueDate, dateTimeFormat, { locale: language });

    case StatsDuration.MONTH: {
      const monthFormat = format(valueDate, 'MMMM yyyy', { locale: language });
      return monthFormat.charAt(0).toUpperCase() + monthFormat.slice(1);
    }

    default:
      return value;
  }
}

function getBarChartX2AxisValues(value, duration) {
  const language = translationInstance.getLanguage() === 'fr' ? frCA : enCA;
  const valueDate = new Date(value);

  if (duration === StatsDuration.DAY && valueDate instanceof Date && !isNaN(valueDate)) {
    const monthFormatForX2 = format(valueDate, 'MMMM yyyy', { locale: language });
    return monthFormatForX2.charAt(0).toUpperCase() + monthFormatForX2.slice(1);
  }

  return value;
}

function updateAxisUnit(chartOptions, xMax, xMin) {
  const min = xMin;
  const max = xMax;

  chartOptions.scales.x.min = min;
  chartOptions.scales.x.max = max;

  chartOptions.scales.x2.min = min;
  chartOptions.scales.x2.max = max;

  const xAxisTimeDifferenceSS = differenceInSeconds(new Date(max), new Date(min));
  const xAxisTimeDifferenceHH = differenceInHours(new Date(max), new Date(min));

  if (xAxisTimeDifferenceSS < 6) {
    chartOptions.scales.x.time.unit = 'millisecond';
    chartOptions.scales.x.ticks.maxTicksLimit = 8;
  } else if (xAxisTimeDifferenceSS < 300) {
    chartOptions.scales.x.time.unit = 'second';
    chartOptions.scales.x.ticks.maxTicksLimit = 14;
  } else if (xAxisTimeDifferenceHH < 7) {
    chartOptions.scales.x.time.unit = 'minute';
    chartOptions.scales.x.ticks.maxTicksLimit = 14;
  } else {
    chartOptions.scales.x.time.unit = 'hour';
    chartOptions.scales.x.ticks.maxTicksLimit = 30;
  }
}

function getChartDatasetStructure(chartType, activityCode, result) {
  switch (chartType) {
    case chartTypes.monitoringLabelLineChart:
      return {
        label: null,
        activityCode: activityCode,
        vitalSignLabel: `patientChartUnit.${result.observationType}`,
        borderWidth: 2,
        backgroundColor: result.backgroundColor,
        borderColor: chartColor.backgroundThreshold,
        data: result.data,
        legendType: 'data',
        monitoringValue: result.observationType,
        pointRadius: 0,
        pointHitRadius: 9,

        datalabels: {
          display: true,
          backgroundColor: (data) => data.dataset.backgroundColor,
          borderRadius: 4,
          anchor: 'center',
          align: 'center',
          clamp: true,
          clip: true,
          color: chartColor.textLabel,
          formatter: (val) => val.y,
          offset: 0,
          textAlign: 'center',
          padding: {
            top: 1,
            bottom: 1,
            right: 4,
            left: 4,
          },
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        normalized: true,
        spanGaps: true,
        parsing: false,
        segment: {
          borderColor: (ctx) => getSegmentBorderColor(ctx, chartColor.correctFirstDataColor),
        },
      };

    case chartTypes.monitoringLineChart:
      if (activityCode === ActivityTypes.ECG) {
        return {
          label: null,
          type: 'bar',
          data: result.data,
          vitalSignLabel: `patientChartUnit.${result.observationType}`,
          backgroundColor: getVitalSignsChartColor(activityCode),
          borderWidth: 0,
          base: -0.5,
          hidden: false,
          legendType: 'data',
          categoryPercentage: 0.6,
          monitoringValue: result.observationType,
          minBarLength: 6,
          maxBarThickness: 15,
          activityCode: activityCode,
          spanGaps: true,
          normalized: true,
          parsing: false,
          ...(!!result.stack && { stack: result.stack }),
          segment: {
            borderColor: (ctx) => getSegmentBorderColor(ctx, getVitalSignsChartColor(activityCode)),
          },
        };
      }

      return {
        label: null,
        observationType: translationInstance.$t(`patientChartUnit.${result.observationType}`),
        vitalSignLabel: `patientChartUnit.${result.observationType}`,
        data: result.data,
        pointRadius: result.pointRadius,
        borderColor: result.borderColor,
        backgroundColor: result.backgroundColor,
        monitoringValue: result.observationType,
        borderWidth: 2,
        pointStyle: 'circle',
        pointHoverRadius: 5,
        legendType: 'data',
        activityCode: activityCode,
        normalized: true,
        parsing: false,
        spanGaps: true,
        segment: {
          borderColor: (ctx) => getSegmentBorderColor(ctx, getVitalSignsChartColor(result.observationType)),
        },
      };

    case chartTypes.monitoringBarChart:
      return {
        label: null,
        activityCode: activityCode,
        backgroundColor: result.backgroundColor,
        hoverBackgroundColor: result.hoverBackgroundColor,
        borderRadius: 6,
        borderSkipped: false,
        monitoringValue: result.observationType,
        categoryPercentage: 0.6,
        minBarLength: 6,
        hidden: false,
        data: result.data,
        maxBarThickness: 15,
        legendType: 'data',
        ...(!!result.stack && { stack: result.stack }),
      };

    default:
      return {};
  }
}

function updateChartValues(chartData, chartOptions, chartType, values, activityCode) {
  return new Promise((resolve, reject) => {
    chartData.datasets = chartData.datasets.filter((x) => x.legendType !== 'data');
    const fetchDataWorker = new Worker(createDataMonitoringWorkerUrl);

    fetchDataWorker.onerror = (error) => {
      console.error(`Worker error while creating the ${activityCode} chart (${chartType}):`, error);
      reject(error);
    };

    const observationTypes = getMonitoringChartSeriesNames(activityCode);

    fetchDataWorker.postMessage({
      activityCode: activityCode,
      chartType: chartType,
      chartTypes: chartTypes,
      correctFirstDataColor: chartColor.correctFirstDataColor,
      incorrectDataColor: chartColor.incorrectDataColor,
      observationTypeColors: observationTypeColors,
      observationTypes: observationTypes,
      values: values,
    });

    fetchDataWorker.onmessage = (event) => {
      try {
        let newDatasetData = [];

        event.data.results.forEach((x) => {
          newDatasetData.push(getChartDatasetStructure(chartType, activityCode, x));
        });

        const maxChartData = event.data.maxChartData;
        const minChartData = event.data.minChartData;

        const isDataBiggerThanLimit =
          maxChartData >=
          chartOptions.scales.y.max -
            (activityCode === ActivityTypes.WEI ? 20 : activityCode === ActivityTypes.BDT ? 1 : 5);

        const isDataLesserThanLimit =
          minChartData <=
          chartOptions.scales.y.min +
            (activityCode === ActivityTypes.WEI ? 20 : activityCode === ActivityTypes.BDT ? 1 : 5);

        if (
          activityCode === ActivityTypes.ECG ||
          (activityCode !== ActivityTypes.WEI && !isDataBiggerThanLimit && !isDataLesserThanLimit)
        ) {
          updateYAxisLimits(chartOptions, chartDefaultYAxisOnActivityCode[activityCode]);
        } else {
          if (isDataBiggerThanLimit && isFinite(maxChartData)) {
            const roundedMaxValue = Math.ceil(maxChartData / 10) * 10;
            chartOptions.scales.y.max = roundedMaxValue + 10;
          }

          if (isDataLesserThanLimit && isFinite(minChartData)) {
            const roundedMinValue = Math.floor(minChartData / 10) * 10;
            chartOptions.scales.y.min = Math.max(0, roundedMinValue - 10);
          }
        }

        chartData.datasets
          .filter((dataset) => !!dataset.hidden)
          .forEach((hiddenDataset) => {
            if (hiddenDataset.monitoringValue === thresholdType.alertTriggered) {
              newDatasetData
                .filter((x) => !!x.data?.length)
                .forEach((y) => {
                  if (y?.datalabels) {
                    y.datalabels.display = (data) =>
                      hiddenDataset.hidden ? !data.dataset.data[data.dataIndex]?.hasTriggeredAnAlert : true;
                  } else {
                    y?.data?.forEach((x, i) => {
                      if (x?.hasTriggeredAnAlert && y?.pointRadius) y.pointRadius[i] = 0;
                    });
                  }
                });
            } else {
              newDatasetData
                .filter((x) => x.monitoringValue === hiddenDataset.monitoringValue)
                .forEach((y) => {
                  y.hidden = hiddenDataset.hidden;
                });
            }
          });

        chartData.datasets.push(...newDatasetData);

        resolve();
      } catch (error) {
        console.log(error);
      }
    };
  });
}

function updateAnnotationsTranslations(chartOptions) {
  Object.values(chartOptions.plugins.annotation.annotations)?.forEach((annotationValue) => {
    const threshold = annotationValue.label.threshold;
    const lessOrGreater = annotationValue.label.lessOrGreater;
    const unit = annotationValue.label.unit;
    const monitoringValue = annotationValue.label.monitoringValue;
    const convertedFrom = annotationValue.label.thresholdConvertedFrom;

    annotationValue.label.content = buildThresholdAnnotationTooltip(
      threshold,
      monitoringValue,
      lessOrGreater,
      unit,
      convertedFrom
    );
  });
}

function setChartXLimits(chartOptions, xMaxDate, xMinDate) {
  chartOptions.scales.x.max = xMaxDate;
  chartOptions.scales.x.min = xMinDate;

  chartOptions.scales.x2.max = xMaxDate;
  chartOptions.scales.x2.min = xMinDate;
}

function setChartZoomLimits(chartOptions) {
  chartOptions.plugins.zoom.limits = {
    x: {
      max: new Date(chartOptions.scales.x.max),
      min: new Date(chartOptions.scales.x2.min),
    },
  };
}

function updateLineChartXAxisType(chartOptions, xMax, xMin) {
  const xAxisTimeDifferenceSS = differenceInSeconds(new Date(xMax), new Date(xMin));
  const xAxisTimeDifferenceHH = differenceInHours(new Date(xMax), new Date(xMin));

  if (xAxisTimeDifferenceSS < 6) {
    chartOptions.scales.x.time.unit = 'millisecond';
    chartOptions.scales.x.ticks.maxTicksLimit = 8;
  } else if (xAxisTimeDifferenceSS < 300) {
    chartOptions.scales.x.time.unit = 'second';
    chartOptions.scales.x.ticks.maxTicksLimit = translationInstance.getLanguage() === 'fr' ? 14 : 9;
  } else if (xAxisTimeDifferenceHH < 7) {
    chartOptions.scales.x.time.unit = 'minute';
    chartOptions.scales.x.ticks.maxTicksLimit = translationInstance.getLanguage() === 'fr' ? 14 : 9;
  } else {
    chartOptions.scales.x.time.unit = 'hour';
    chartOptions.scales.x.ticks.maxTicksLimit = translationInstance.getLanguage() === 'fr' ? 28 : 15;
  }
}

function updateXAxisLimitsForStatChart(chartOptions, filters, duration) {
  let xMaxDate;
  let xMinDate;

  if (filters.timeFilterType === 'range') {
    xMaxDate = filters.dateTo;
    xMinDate = filters.dateFrom;
  } else {
    xMaxDate = new Date();
    xMinDate = new Date(removeChartTime(new Date(), filters.timeFilterType, filters.timeFilterValue));
  }

  switch (duration) {
    case StatsDuration.HOUR:
      chartOptions.scales.x.time.unit = 'hour';
      chartOptions.scales.x2.time.unit = 'day';

      xMaxDate = new Date(xMaxDate).setMinutes(30, 0);
      xMinDate = new Date(xMinDate).setMinutes(-30, 0);

      break;

    case StatsDuration.DAY:
      chartOptions.scales.x.time.unit = 'day';
      chartOptions.scales.x2.time.unit = 'month';

      xMaxDate = new Date(xMaxDate).setHours(12, 0, 0);
      xMinDate = new Date(xMinDate).setHours(-12, 0, 0);

      break;

    case StatsDuration.WEEK: {
      chartOptions.scales.x.time.unit = 'week';
      chartOptions.scales.x2.time.unit = 'month';

      xMaxDate = addDays(startOfWeek(new Date(xMaxDate).setHours(0, 0, 0), { weekStartsOn: 0 }), 4);
      xMinDate = addDays(startOfWeek(subWeeks(new Date(xMinDate).setHours(0, 0, 0), 1), { weekStartsOn: 0 }), 3);
      break;
    }

    case StatsDuration.MONTH: {
      chartOptions.scales.x.time.unit = 'month';
      chartOptions.scales.x2.display = false;

      xMinDate = new Date(xMinDate);
      xMinDate.setMonth(xMinDate.getMonth() - 1);
      xMinDate.setDate(12);
      xMinDate.setHours(0, 0, 0, 0);
      xMinDate = xMinDate.getTime();

      xMaxDate = new Date(xMaxDate);
      xMaxDate.setMonth(xMaxDate.getMonth());
      xMaxDate.setDate(22);
      xMaxDate.setHours(0, 0, 0, 0);
      xMaxDate = xMaxDate.getTime();

      break;
    }

    case StatsDuration.YEAR:
      chartOptions.scales.x.time.unit = 'year';
      chartOptions.scales.x2.display = false;

      xMinDate = new Date(xMinDate);
      xMinDate.setFullYear(xMinDate.getFullYear() - 1);
      xMinDate.setMonth(5);
      xMinDate.setDate(1);
      xMinDate.setHours(0, 0, 0, 0);
      xMinDate = xMinDate.getTime();

      xMaxDate = new Date(xMaxDate);
      xMaxDate.setFullYear(xMaxDate.getFullYear());
      xMaxDate.setMonth(7);
      xMaxDate.setDate(1);
      xMaxDate.setHours(0, 0, 0, 0);
      xMaxDate = xMaxDate.getTime();

      break;
  }

  chartOptions.scales.x.min = xMinDate;
  chartOptions.scales.x.max = xMaxDate;
  chartOptions.scales.x2.min = xMinDate;
  chartOptions.scales.x2.max = xMaxDate;

  setChartZoomLimits(chartOptions);
}

function getChartLegendTriggeredAlertValue(uniqueKey) {
  return {
    label: 'patientChartUnit.triggeredAlert',
    borderColor: chartColor.incorrectDataColor,
    backgroundColor: chartColor.incorrectDataColor,
    legendType: thresholdType.alertTriggered,
    hidden: false,
    monitoringValue: thresholdType.alertTriggered,
    uniqueReference: uniqueKey,
  };
}

class BaseChart {
  constructor(activityCode, chartType, options = {}) {
    this.activityCode = activityCode;
    this.chartType = chartType;
    this.options = options;
    this.uniqueKey = this.options?.uniqueKey ?? this.activityCode;
  }

  createChartDatasets() {
    return {
      datasets: getMonitoringChartSeriesNames(this.activityCode).map((datasetTitle) => {
        const color = getDatasetColorOnCode(datasetTitle);

        return {
          label: `chartLegend.${datasetTitle}`,
          backgroundColor: color,
          borderColor: color,
          hidden: false,
          legendType: legendTypes.title,
          monitoringValue: datasetTitle,
        };
      }),
    };
  }

  createChartOptions() {
    return {
      animation: false,
      activityCode: this.activityCode,
      chartUniqueKey: this.uniqueKey,
      selectedUnit: this.options?.selectedUnit ?? null,
      parsing: this.chartType === chartTypes.monitoringBarChart,

      scales: {
        y: {
          beginAtZero: false,
          ticks: {},
          title: {
            display: true,
          },
        },
        x: {
          display: true,
          offset: false,
          stacked:
            this.chartType === chartTypes.monitoringBarChart || this.activityCode === ActivityTypes.ECG ? true : null,
          type: 'time',
          ticks: {
            source: 'auto',
            autoSkip: true,
            autoSkipPadding: 3,
            maxRotation: this.chartType === chartTypes.monitoringBarChart ? 45 : 0,
            minRotation: 0,
            callback: (time) => (this.options.xAxisCallback ? this.options.xAxisCallback(time) : time),
          },
          time: {
            unit: 'hour',
            displayFormats: {
              day: 'yyyy-MM-dd',
              hour: translationInstance.getLanguage() === 'fr' ? 'HH:mm' : 'h:mm aa',
              minute: translationInstance.getLanguage() === 'fr' ? 'HH:mm' : 'h:mm aa',
              second: translationInstance.getLanguage() === 'fr' ? 'HH:mm:ss' : 'HH:mm:ss aa',
              millisecond: translationInstance.getLanguage() === 'fr' ? 'HH:mm:ss.SSS' : 'HH:mm:ss.SSS aa',
            },
          },
        },
        x2: {
          display: true,
          type: 'time',
          position: 'bottom',
          afterBuildTicks: (axis) => this.getX2AxisTicks(axis),
          grid: {
            display: false,
            color: chartColor.delimiterBorderColor,
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            callback: (time) => (this.options.x2AxisCallback ? this.options.x2AxisCallback(time) : time),
          },
          time: {
            unit: 'day',
            displayFormats: {
              day: 'yyyy-MM-dd',
              year: 'yyyy',
            },
          },
          title: {
            display: true,
          },
        },
      },
      plugins: {
        crosshair: {
          line: {
            color: '#DC630D',
            width: 1,
          },
          sync: {
            enabled: this.chartType !== chartTypes.monitoringLabelLineChart,
            group: this.options?.chartGroup ?? this.chartType,
            suppressTooltips: false,
          },
        },
        datalabels: {
          display: this.chartType === chartTypes.monitoringLabelLineChart,
        },
        decimation: {
          enabled: this.activityCode === ActivityTypes.ECG,
          algorithm: 'lttb',
          samples: 750,
        },
        ...(this.chartType !== chartTypes.realtimeLineChart && {
          htmlLegend: {
            containerID: `${this.uniqueKey}-monitoring-chart-legend-container`,
          },
        }),
        legend: {
          onClick: (legendItem) => this.options.showOrHideSelectedElementCallback?.(legendItem),
          display: false,
        },
        tooltip: {
          enabled: false,
          mode: 'customVerticalTooltipMode',
          intersect: false,

          callbacks: {
            title: (data) =>
              this.chartType === chartTypes.monitoringBarChart ||
              (this.activityCode === ActivityTypes.ECG && data[0].chart.config._config.type === 'bar')
                ? getFormattedLabelDate(data)
                : getDatetimeTranslation(data),
            label: (data) => getChartTooltipLabel(data),
            afterLabel: (data) => this.options?.afterLabelCallback?.(data) || '',
            footer: (data) => {
              if (this.options?.footerCallback) {
                return this.options?.footerCallback(data);
              }

              if (![chartTypes.monitoringLineChart, chartTypes.monitoringLabelLineChart].includes(this.chartType)) {
                return;
              }

              if (data[0]?.raw?.userManualEntry) {
                return translationInstance
                  .$t('valueManuallyEnteredByUser')
                  .replace('{{userName}}', data[0]?.raw?.userManualEntry);
              }

              if (data[0]?.raw?.isManualEntryValueByPatient) {
                return translationInstance.$t('valueManuallyEnteredByPatient');
              }
            },
          },
        },
        zoom: {
          zoom: {
            pinch: {
              enabled: true,
            },
            wheel: {
              enabled: true,
            },
            mode: 'x',
            onZoom: ({ chart }) => {
              this.options?.onZoomCallback?.(chart);
            },
          },
          pan: {
            enabled: true,
            mode: 'x',
            onPan: ({ chart }) => {
              if (chart.canvas.style.cursor !== 'grab') {
                chart.canvas.style.cursor = 'grab';
              }

              this.options?.onPanCallback?.(chart);
            },
            onPanComplete: ({ chart }) => {
              if (chart.canvas.style.cursor === 'grab') {
                chart.canvas.style.cursor = 'default';
              }

              this.options?.onPanCompleteCallback?.(chart);
            },
          },
        },
        annotation: {
          annotations: {},
        },
      },

      responsive: true,
      maintainAspectRatio: false,
    };
  }

  getX2AxisTicks(axis) {
    buildDateX2AxisTicks(axis);
  }
}

export {
  BaseChart,
  buildDateX2AxisTicks,
  getBarChartXAxisValues,
  getBarChartX2AxisValues,
  getChartLegendTriggeredAlertValue,
  getLineChartAxisVisibleTicks,
  getMonitoringChartSeriesNames,
  getVitalSignsChartColor,
  setChartXLimits,
  showOrHideSelectedElement,
  updateChartThresholds,
  updateChartValues,
  removeChartTime,
  setChartZoomLimits,
  updateAxisUnit,
  updateAndGetChartThresholdsLegendItems,
  updateYAxisLimits,
  updateXAxisLimitsForStatChart,
  updateAnnotationsTranslations,
  monitoringChartDefaultYAxis,
  showOrHideAnnotationTooltip,
  updateLineChartXAxisType,
};
