<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="'1200px'">
      <v-card>
        <ModalTitle :title="$t('patientIOTDevices')" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <div>
            <PatientAssignDetails ref="patientDetails" class="mb-4" :patient-info="patientInfo" />

            <list-item-selector
              :first-data-grid-name="patientId ? `patient-${patientId}-iots-assigned-grid` : null"
              :second-data-grid-name="patientId ? `patient-${patientId}-iots-to-assign-grid` : null"
              :columns-assigned="columnsAssigned"
              :columns-available="columnsAvailable"
              :all-items="allIots"
              :assigned-items.sync="formData.assignedIots"
              :available-title="$t('availableIOT')"
              :assigned-title="$t('currentPatientIOT')"
              :data-loading="showWaitModal"
            >
            </list-item-selector>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="updatePatientIOT"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import PatientAssignDetails from '@/components/Patient/PatientAssignDetails';
import ListItemSelector from '@/components/ListItemsSelector.vue';
import SaveButton from '@/components/SaveButton.vue';
import iotService from '@/services/iotService';
import { IOTDeviceTypeCodes } from '../PatientMonitoring/constants';
import { IOTDeviceCompanies } from './constants';

export default {
  name: 'PatientIOTDevices',

  components: {
    PatientAssignDetails,
    ListItemSelector,
    SaveButton,
  },
  mixins: [translation, accessibility],

  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    patientInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loadedPatientId: null,

      error: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      allIots: [],
      formData: {
        assignedIots: [],
      },
      originalFormData: {},
      statuses: [],
      statusesIds: [],
    };
  },
  computed: {
    columnsAssigned: function () {
      return [
        {
          text: 'iotDeviceType',
          value: 'iotDeviceType.name',
          filterable: true,
        },
        {
          text: 'iotDeviceSerialNumber',
          value: 'serie',
          filterable: true,
        },
        {
          text: 'iotDeviceKit',
          value: 'kit',
          filterable: true,
        },
      ];
    },

    columnsAvailable: function () {
      return [
        {
          text: 'iotDeviceType',
          value: 'iotDeviceType.name',
          filterable: true,
        },
        {
          text: 'iotDeviceSerialNumber',
          value: 'serie',
          filterable: true,
        },
        {
          text: 'iotDeviceKit',
          value: 'kit',
          filterable: true,
        },
      ];
    },
  },

  watch: {
    patientId: function () {
      this.init();
    },
    show() {
      if (this.show) {
        this.init();
      } else {
        this.loadedPatientId = null;
      }
    },
  },
  methods: {
    init: function () {
      if (!this.patientId || !this.show || this.loadedPatientId === this.patientId) {
        return;
      }

      this.loadedPatientId = this.patientId;
      this.formData.assignedIots = [];
      this.statuses = [];

      this.loadIots();
    },

    async loadIots() {
      this.showWaitModal = true;
      const data = {
        statusesIds: this.statusesIds,
        excludeAssociatedDevices: true,
        excludeNonAssociableDevices: true,
      };

      try {
        let [allIots, patientIots] = await Promise.all([
          iotService.getIots(data),
          iotService.getPatientIOTDevices(this.patientId),
        ]);

        this.allIots = allIots.map((item) => {
          return {
            id: item.id,
            iotDeviceType: item.iotdeviceModel.type,
            iotDeviceModel: item.iotdeviceModel.name,
            kit: item.kit,
            serie: item.serie,
            status: item.status.name,
          };
        });

        this.formData.assignedIots = patientIots.map((item) => {
          return {
            id: item.id,
            iotDeviceType: item.iotdeviceModel.type,
            iotDeviceModel: item.iotdeviceModel.name,
            kit: item.kit,
            serie: item.serie,
            status: item.status.name,
            disabled:
              item.iotdeviceModel.type.code === IOTDeviceTypeCodes.CAMERA ||
              item.iotdeviceModel.type.code === IOTDeviceTypeCodes.RADAR ||
              item.iotdeviceModel.type.code === IOTDeviceTypeCodes.INCONTINENCE ||
              item.iotdeviceModel.type.code === IOTDeviceTypeCodes.XPILL ||
              item.iotdeviceModel.type.code === IOTDeviceTypeCodes.VIRTUALASSISTANT ||
              (item.iotdeviceModel.type.code === IOTDeviceTypeCodes.BALANCE &&
                item.iotDeviceCompany === IOTDeviceCompanies.WITHINGS),
          };
        });

        this.allIots.push(...this.formData.assignedIots);
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    closeDialog: function (refresh) {
      this.$emit('update:show', false);
      if (refresh) {
        this.$emit('saved');
      }
    },

    async updatePatientIOT() {
      if (this.isProcessing) return;

      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        var data = {
          iotDevicesIds: this.formData.assignedIots
            .filter((iot) => iot.iotDeviceType.code !== 'CA' && iot.iotDeviceType.code !== 'RD')
            .map((x) => x.id),
        };

        await iotService.updatePatientIOTDevice(this.patientId, data);
        this.closeDialog(true);
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
      this.isProcessing = false;
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>
