var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"monitoring-details-chart-card"},[_c('ErrorModal',{attrs:{"error":_vm.error},on:{"close-error-modal":function($event){_vm.error = null}}}),_c('WaitModal',{staticClass:"pt-8",attrs:{"show":!_vm.chartParameters}}),(_vm.chartParameters)?[(_vm.chartParameters.hasPatientIotDevice === false)?_c('ManualEntryButton',{attrs:{"patient-id":_vm.patientId,"activity-type-codes":_vm.orderedActivities.map((activity) => activity.code)},on:{"updateChartData":_vm.updateChartDataOnManualEntry}}):_vm._e(),_vm._l((_vm.orderedActivities),function(activity,chartIndex){return _c('div',{key:chartIndex,staticClass:"py-5"},[_c('MonitoringChartContainer',{ref:`chart-${activity.id}`,refInFor:true,attrs:{"activity-code":activity.code,"activity-id":activity.id,"activity-name":activity.name,"chart-group":_vm.chartGroup,"chart-type":_vm.chartParameters.duration === '24h' ? _vm.chartTypes.monitoringLineChart : _vm.chartTypes.monitoringBarChart,"chart-duration":_vm.chartParameters.duration,"filters":_vm.filters,"from-notification":_vm.fromNotification,"is-processing":_vm.isChartProcessing(activity.id),"patient-id":_vm.patientId,"scroll-to-view":chartIndex > 0 && _vm.selectedActivityId == activity.id,"thresholds":_vm.vitalSignsData[activity.id]
            ? _vm.vitalSignsData[activity.id].thresholds
              ? _vm.vitalSignsData[activity.id].thresholds
              : {}
            : {},"unique-key":_vm.getChartUniqueKey(activity, chartIndex),"values":_vm.vitalSignsData[activity.id]
            ? _vm.vitalSignsData[activity.id].results
              ? _vm.vitalSignsData[activity.id].results
              : []
            : []},on:{"isChartLoading":_vm.onChartLoading}})],1)})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }