<template>
  <v-card class="monitoring-details-chart-card">
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="!chartParameters" class="pt-8" />

    <template v-if="chartParameters">
      <ManualEntryButton
        v-if="hasPatientIotDevice === false"
        :patient-id="patientId"
        :activity-type-codes="[activityTypes.GLY]"
        @updateChartData="getCapillaryGlycemiaData"
      />

      <MonitoringChartContainer
        ref="monitoringLabelLineChart"
        :activity-code="activityTypes.GLY"
        :activity-name="activityName"
        :chart-duration="chartDuration"
        :chart-type="chartType"
        :filters="filters"
        :from-notification="fromNotification"
        :is-processing="showWaitModal"
        :thresholds="patientThresholds"
        :unique-key="activityTypes.GLY"
        :values="patientValues"
      />
    </template>
  </v-card>
</template>

<script>
import patientMonitoringService from '@/services/patientMonitoringService';
import MonitoringChartContainer from '../Chart/MonitoringChartContainer.vue';
import ManualEntryButton from '../ManualEntry/ManualEntryButton.vue';
import filterMixin from '../filterMixin';
import translationMixin from '@/translationMixin';
import { ActivityMonitoringValues, ActivityTypes } from '@/components/PatientMonitoring/constants';
import { chartTypes } from '@/components/PatientMonitoring/Chart/utils/constants.js';

export default {
  name: 'PatientCapillaryGlycemia',
  components: { ManualEntryButton, MonitoringChartContainer },
  mixins: [filterMixin, translationMixin],

  props: {
    activityId: { type: Number, default: null },
    activityName: { type: String, default: null },
    filters: { type: Object, default: null },
    patientId: { type: Number, default: null },
  },

  data() {
    return {
      activityTypes: ActivityTypes,
      chartParameters: null,
      chartType: chartTypes.monitoringLabelLineChart,
      patientData: null,
      patientValues: [],
      patientThresholds: [],
      hasPatientIotDevice: null,
      chartDuration: null,
      showWaitModal: false,
      error: null,
      requestFilters: null,
      fromNotification: false,
    };
  },

  computed: {
    dateFrom() {
      return this.calculateChartDate('start');
    },
    dateTo() {
      return this.calculateChartDate('end');
    },
  },

  watch: {
    patientId: async function () {
      this.patientThresholds = [];
      await this.getCapillaryGlycemiaData();
    },

    filters: async function () {
      await this.getCapillaryGlycemiaData();
    },

    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          if ((oldVal && oldVal.fromNotification) || (newVal && newVal.fromNotification)) {
            this.$emit('update:request-filters', newVal);
            this.fromNotification = true;
          }
        }
      },
      deep: true,
    },
  },

  async created() {
    this.fromNotification = this.requestFilters !== null;
    await this.getCapillaryGlycemiaData();
  },

  methods: {
    async getCapillaryGlycemiaData() {
      this.requestFilters = this.setRequestFilters();
      this.patientValues = [];

      this.requestFilters = {
        ...this.requestFilters,
        statusLevelIds: this.filters?.statusLevelIds,
      };

      try {
        this.showWaitModal = true;

        const monitoredActivityTypeCodes = [ActivityTypes.GLY];
        this.chartParameters = await patientMonitoringService.getPatientMonitoringChartParameters({
          ...this.requestFilters,
          monitoredActivityTypeCodes,
        });

        this.hasPatientIotDevice = this.chartParameters.hasPatientIotDevice;
        this.chartDuration = this.chartParameters.duration;

        this.patientData = await patientMonitoringService.getPatientCapillaryGlycemia({
          ...this.requestFilters,
          ...this.chartParameters,
        });

        this.patientValues = this.patientData.results.flat();

        const thresholds = this.patientData.thresholds[ActivityMonitoringValues.CapillaryGlycemia];

        if (JSON.stringify(this.patientThresholds) !== JSON.stringify(thresholds)) {
          this.patientThresholds = thresholds;
        }
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },
  },
};
</script>
