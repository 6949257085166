<template>
  <v-container fluid>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row align="center">
      <v-col v-if="patientId !== undefined && $vuetify.breakpoint.xs" cols="auto">
        <v-btn exact icon :to="{ name: 'PatientMonitoringGrid' }"><v-icon>mdi-chevron-left</v-icon></v-btn>
      </v-col>
      <v-col>
        <PageTitle :title="$t('patientMonitoring')" />
      </v-col>
      <PatientPrintInfo :patient-id="patientId" />
    </v-row>

    <template v-if="patientId !== undefined">
      <WaitModal :show="isFetching" />

      <MonitoringDetails
        v-if="!isFetching"
        ref="monitoringDetails"
        :patient-id="patientId"
        :detail-type="detailType"
        :detail-id="detailId"
        :filters="filters"
        :patient-info="selectedPatientInfo"
        :activities="selectedPatientActivities"
        :is-fetching="isFetching"
        @update:detail-type="detailTypeUpdated"
      />
    </template>

    <v-row v-show="!patientId" :class="{ 'mt-5': $vuetify.breakpoint.smAndUp }" class="no-print">
      <v-col :cols="$vuetify.breakpoint.xs ? undefined : 'auto'">
        <v-select
          :id="getIdByName('autoRefreshInterval')"
          ref="autoRefreshInterval"
          v-model="autoRefreshInterval"
          :label="$t('autoRefreshInterval')"
          :items="autoRefreshIntervals"
          item-text="label"
          item-value="value"
          outlined
          hide-details
          :dense="$vuetify.breakpoint.xs"
        />
      </v-col>
    </v-row>

    <div v-show="!patientId && $vuetify.breakpoint.smAndUp">
      <v-row>
        <v-col>
          <PatientMonitoringFilter
            :show-incontinence="showIncontinence"
            :configuration-values="initGridConfiguration"
            :is-disabled="isProcessing"
            @filter="onMonitoringFilter"
          />
        </v-col>
      </v-row>

      <DataGrid
        ref="dataGrid"
        class="monitoring-grid"
        :items="patientResults"
        :columns="columns"
        :show-select="false"
        :grid-name="gridName"
        :items-per-page="10"
        :data-loading="isFetching"
        @row:click="(item) => viewPatientMonitoringDetail(item.id)"
        @configuration-changed="dataGridConfigurationChanged"
        @updatePatientMonitoringGridConfig="updatePatientMonitoringGridConfig"
      >
        <template #item.active="{ item }">
          <v-icon ref="activeIcon">{{ item.active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank' }}</v-icon>
        </template>

        <template v-for="activity in activities" #[`item.activity._${activity.id}`]="{ item }">
          <td
            :ref="`activityTd_${activity.id}`"
            :key="`activityTd_${activity.id}`"
            class="text-center"
            @click.stop="() => itemClicked({ item: item, activityId: activity.id })"
          >
            <PatientMonitoringIcon :ref="`patientMonitoringIcon_${activity.id}`" :item-type="activity" :item="item" />
          </td>
        </template>

        <template #item.newMessageCount="{ item }">
          <td
            ref="viewMessage"
            class="text-center"
            @click.stop="viewPatientMonitoringDetail(item.id, monitoringTypes.conversations)"
          >
            <v-badge
              ref="badge"
              :value="item.newMessageCount != 0"
              overlap
              color="primary"
              top
              offset-x="6"
              offset-y="12"
              bordered
            >
              <span ref="messageCount" slot="badge">{{ item.newMessageCount }}</span>
              <v-icon ref="chatIcon" color="#55b1ff">mdi-chat</v-icon>
            </v-badge>
          </td>
        </template>
      </DataGrid>
      <StatusLevelLegend :show-incontinence="showIncontinence" />
    </div>

    <div v-if="!patientId && $vuetify.breakpoint.xs">
      <v-row dense class="my-2">
        <v-col cols="auto">
          <v-btn icon @click="showLegendSheet = !showLegendSheet"><v-icon>mdi-information</v-icon></v-btn>
        </v-col>
        <v-col>
          <v-text-field
            v-model="mobileGridSearchValue"
            dense
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
            outlined
          >
          </v-text-field>
        </v-col>

        <v-col cols="auto">
          <v-btn icon @click="showFilterSheet = !showFilterSheet">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels multiple>
            <v-expansion-panel v-for="item in filteredPatientResults" :key="item.id">
              <v-expansion-panel-header>
                <template #default>
                  <v-row align="center">
                    <v-col cols="auto">
                      <PatientMonitoringIcon :item-type="getPatientWorstActivity(item)" :item="item" no-badge />
                    </v-col>
                    <v-col class="d-flex flex-column">
                      <span>{{ `${item.lastName}, ${item.firstName}` }}</span>
                      <span v-if="item.roombed" class="mt-2 text--secondary text-body-2">{{
                        `${$t('roombed')}: ${item.roombed}`
                      }}</span>
                    </v-col>
                    <v-col cols="auto">
                      <div v-if="item.newMessageCount" class="pr-7">
                        <v-badge
                          ref="badge"
                          :value="item.newMessageCount != 0"
                          overlap
                          color="primary"
                          bordered
                          :content="item.newMessageCount"
                        >
                          <v-btn icon @click.stop="viewPatientMonitoringDetail(item.id, monitoringTypes.conversations)"
                            ><v-icon ref="chatIcon" color="#55b1ff">mdi-chat</v-icon></v-btn
                          >
                        </v-badge>
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col class="patient-monitoring-details">
                    <div
                      v-for="activity in activities"
                      :key="activity.id"
                      v-ripple
                      class="patient-monitoring-details__item ml-11 pa-1"
                      @click.stop="() => itemClicked({ item: item, activityId: activity.id })"
                    >
                      <div>
                        <PatientMonitoringIcon :item-type="activity" :item="item" />
                      </div>
                      <div class="text--secondary">{{ activity.displayName }}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-if="!filteredPatientResults.length" class="text--secondary text-center">
            {{ $t('noDataAvailable') }}
          </div>
        </v-col>
      </v-row>
    </div>

    <v-bottom-sheet v-model="showLegendSheet">
      <v-card>
        <v-card-title>{{ $t('legend') }}</v-card-title>
        <v-card-text>
          <StatusLevelLegend :show-incontinence="showIncontinence" />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>

    <v-navigation-drawer v-model="showFilterSheet" fixed temporary right touchless>
      <v-card flat>
        <v-card-title>{{ $t('filters') }}</v-card-title>
        <v-card-text>
          <PatientMonitoringFilter
            :show-incontinence="showIncontinence"
            :is-disabled="isProcessing"
            :configuration-values="initGridConfiguration"
            @filter="onMonitoringFilter"
          />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import DataGrid from '@/components/DataGrid';
import PatientMonitoringIcon from './PatientMonitoringIcon';
import PatientMonitoringFilter from './PatientMonitoringFilter';
import MonitoringDetails from './MonitoringDetails.vue';
import StatusLevelLegend from './StatusLevelLegend';
import { containsString } from '@/utils/stringUtils';
import { ActivityTypes, MonitoringTypes } from './constants';
import patientMonitoringService from '@/services/patientMonitoringService';
import userService from '@/services/userService';
import PatientPrintInfo from '../Patient/PatientPrintInfo.vue';

export default {
  name: 'PatientMonitoringGrid',
  components: {
    DataGrid,
    MonitoringDetails,
    PatientMonitoringIcon,
    PatientMonitoringFilter,
    PatientPrintInfo,
    StatusLevelLegend,
  },
  mixins: [translationMixin, accessibility],
  props: {
    patientId: {
      type: Number,
      default: undefined,
    },
    detailType: {
      type: [String, Number],
      default: undefined,
    },
    detailId: {
      type: Number,
      default: undefined,
    },
    notificationDatetime: {
      type: String,
      default: undefined,
    },
    alertTime: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      gridName: 'patient_monitoring_grid',
      error: null,
      isProcessing: false,
      patientResults: null,
      activities: [],
      statusLevels: [],
      activityDefinitions: [],
      filters: {
        timeFilterType: null,
        timeFilterValue: null,
        statusLevelIds: null,
        cohortPatients: null,
        notificationDatetime: this.notificationDatetime,
        alertTime: this.alertTime,
      },
      showIncontinence: true,
      mobileGridSearchValue: '',
      showLegendSheet: false,
      showFilterSheet: false,
      userGridConfiguration: {},

      initGridConfiguration: null,
      monitoringTypes: {
        conversations: MonitoringTypes.CONVERSATIONS,
      },
      autoRefreshInterval: null,
      autoRefreshTimer: null,
      isFetching: false,
    };
  },
  computed: {
    columns: function () {
      let result = [
        {
          text: 'lastName',
          value: 'lastName',
          filterable: true,
        },
        {
          text: 'firstName',
          value: 'firstName',
          filterable: true,
        },
        {
          text: 'roombed',
          value: 'roombed',
          filterable: true,
        },
        {
          text: 'mrn',
          value: 'mrn',
          filterable: false,
          visible: true,
        },
        {
          text: 'nasm',
          value: 'nasm',
          filterable: true,
          visible: false,
        },
        {
          text: 'birthDate',
          value: 'birthDate',
          filterable: true,
          visible: false,
        },
        {
          text: 'facility',
          value: 'facility',
          filterable: true,
          visible: false,
        },
        {
          text: 'organization',
          value: 'organization',
          filterable: true,
          visible: false,
        },
        {
          text: 'monitoringStart',
          value: 'startDate',
          filterable: true,
          visible: false,
        },
        {
          text: 'active',
          value: 'active',
          filterable: true,
          visible: false,
        },
        {
          text: 'address',
          value: 'address',
          filterable: true,
          visible: false,
        },
        {
          text: 'city',
          value: 'city',
          filterable: true,
          visible: false,
        },
        {
          text: 'province',
          value: 'province',
          filterable: true,
          visible: false,
        },
        {
          text: 'country',
          value: 'country',
          filterable: true,
          visible: false,
        },
        {
          text: 'postalCode',
          value: 'postalCode',
          filterable: true,
          visible: false,
        },
        {
          text: 'phone',
          value: 'phone',
          filterable: true,
          visible: false,
        },
        {
          text: 'email',
          value: 'email',
          filterable: true,
          visible: false,
        },
        {
          text: 'patientType',
          value: 'patientType',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdBy',
          value: 'created_by',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdDate',
          value: 'created_at',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedDate',
          value: 'updated_at',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedBy',
          value: 'updated_by',
          filterable: true,
          visible: false,
        },
        {
          text: 'id',
          value: 'id',
          filterable: true,
          visible: false,
        },
      ];

      result = result.concat(this.activityDefinitions);

      result.push({
        text: 'communications',
        value: 'newMessageCount',
        filterable: false,
        visible: true,
        sortable: false,
        align: 'center',
      });

      return result;
    },
    selectedPatientInfo() {
      let patient = this.patientResults?.find((x) => x.id === this.patientId);
      return patient
        ? {
            firstName: patient.firstName,
            lastName: patient.lastName,
            roombed: patient.roombed,
          }
        : null;
    },
    selectedPatientActivities() {
      const patient = this.patientResults?.find((x) => x.id === this.patientId);
      const monitoringActivityIds = new Set(
        Object.values(patient?.monitoring || {}).flatMap((item) => item.activityId)
      );
      const filteredActivities = this.activities.filter((activity) => monitoringActivityIds.has(activity.id));

      return patient ? filteredActivities : undefined;
    },
    filteredPatientResults() {
      if (!this.activities.length) {
        return [];
      }

      if (!this.mobileGridSearchValue) {
        return this.patientResults;
      }
      return this.patientResults.filter((x) => {
        let name = `${x.lastName}, ${x.firstName}`;
        return (
          containsString(name, this.mobileGridSearchValue) ||
          (x.roombed && containsString(x.roombed, this.mobileGridSearchValue))
        );
      });
    },
    autoRefreshIntervals() {
      return [
        {
          label: this.$t('autoRefreshNone'),
          value: null,
        },
        {
          label: this.$t('autoRefreshOneMinute'),
          value: 60000,
        },
        {
          label: this.$t('autoRefreshFiveMinutes'),
          value: 5 * 60 * 1000,
        },
        {
          label: this.$t('autoRefreshFifteenMinutes'),
          value: 15 * 60 * 1000,
        },
      ];
    },
  },
  watch: {
    '$vuetify.breakpoint.xs': function (newValue) {
      if (!newValue) {
        this.showFilterSheet = false;
        this.showLegendSheet = false;
      }
    },

    autoRefreshInterval() {
      this.updateAutoRefreshTimer();
      this.saveAutoRefreshInterval();
    },

    $route() {
      this.updateAutoRefreshTimer();
    },
  },

  created: async function () {
    this.isProcessing = true;
    this.isFetching = true;

    const userGridConfigData = await userService.getUserGridConfiguration(this.gridName);

    let statusLevelIds = null;
    let cohortPatients = null;

    if (userGridConfigData?.configuration) {
      this.initGridConfiguration = userGridConfigData?.configuration;

      statusLevelIds =
        this.initGridConfiguration?.statusLevelIds?.length > 0 ? this.initGridConfiguration.statusLevelIds : null;
      cohortPatients = this.initGridConfiguration?.cohortIds?.length > 0 ? this.initGridConfiguration.cohortIds : null;

      this.userGridConfiguration = this.initGridConfiguration;
    }

    this.filters = {
      timeFilterType: null,
      timeFilterValue: null,
      statusLevelIds: statusLevelIds,
      cohortPatients: cohortPatients,
      notificationDatetime: this.notificationDatetime,
      alertTime: this.alertTime,
    };

    this.init();
    this.loadAutoRefreshInterval();
  },

  destroyed() {
    if (this.autoRefreshTimer) {
      clearInterval(this.autoRefreshTimer);
    }
  },
  methods: {
    async init() {
      try {
        await this.getPatientsMonitoringSummary();
      } finally {
        this.isProcessing = false;
      }
    },

    updatePatientMonitoringGridConfig: async function (configurations) {
      this.isProcessing = true;

      const configToSave = {
        ...configurations,
        cohortIds: this.userGridConfiguration?.cohortIds ?? [],
        statusLevelIds: this.userGridConfiguration?.statusLevelIds ?? [],
      };

      try {
        await userService.updateUserGridConfiguration(this.gridName, configToSave);
      } catch (error) {
        console.log(`Error while saving using configuration in ${this.gridName}: ${error}`);
      }

      this.isProcessing = false;
    },

    async onMonitoringFilter(filters) {
      this.showFilterSheet = false;
      this.filters = filters;
      this.isProcessing = true;
      this.init();

      this.userGridConfiguration = this.userGridConfiguration ?? {};
      this.userGridConfiguration.cohortIds = filters?.cohortPatients ?? [];
      this.userGridConfiguration.statusLevelIds = filters?.statusLevelIds ?? [];

      this.updatePatientMonitoringGridConfig(this.userGridConfiguration);
    },

    getPatientsMonitoringSummary: async function () {
      this.activities = [];
      this.isFetching = true;
      try {
        let data = await patientMonitoringService.getPatientMonitoringSummary(this.filters);
        let rawPatients = data.patientsResults;

        this.activities = data.activities
          .sort((a, b) => a.activityType.order - b.activityType.order || a?.displayName?.localeCompare(b.displayName))
          .map((mt) => {
            return {
              id: mt.id,
              name: mt.name,
              displayName: mt.displayName,
              activityTypeCode: mt.activityType.code,
              alerts: mt?.parameters?.alerts,
              order: mt.activityType.order,
            };
          });

        this.statusLevels = data.statusLevels;

        const activitiesMap = Object.fromEntries(
          this.activities.map(({ id, activityTypeCode }) => [id, activityTypeCode])
        );

        const statusLevelMap = Object.fromEntries(this.statusLevels.map(({ id, code }) => [id, code]));

        this.activityDefinitions = this.activities.map((x) => {
          return {
            text: x.displayName,
            value: `activity._${x.id}`,
            filterable: false,
            visible: true,
            sortable: false,
            align: 'center',
          };
        });

        this.patientResults = rawPatients
          .filter((r) => r.result.length != 0)
          .map((r) => {
            var patient = Object.assign({}, r.patient);
            patient.country = r.patient.country ? r.patient.country.name : '';
            patient.created_by = r.patient.created_by ? r.patient.created_by.username : '';
            patient.gender = r.patient.gender ? r.patient.gender.name : '';
            patient.patientType = r.patient.patientType ? r.patient.patientType.name : '';
            patient.province = r.patient.province ? r.patient.province.name : '';
            patient.updated_by = r.patient.updated_by ? r.patient.updated_by.username : '';
            patient.roombed = r.patient.roombed ? this.getRoombedText(r.patient.roombed) : '';
            patient.facility = r.patient.facility ? r.patient.facility.name : '';
            patient.organization = r.patient.organization ? r.patient.organization.name : '';
            patient.newMessageCount = r.patient.newMessageCount;
            patient.monitoring = {};

            for (let i = 0; i < r.result.length; i++) {
              var keyId = r.result[i].activityId;
              var keyName = `_${keyId}`;
              var valueId = r.result[i].statusLevelId;
              var valueCode = statusLevelMap[valueId];

              patient.monitoring[keyName] = {
                valueCode: valueCode,
                alertCount: r.result[i].alertCount,
                activityId: keyId,
                activityTypeCode: activitiesMap[keyId],
              };
            }
            return patient;
          });
      } catch (error) {
        this.error = error;
      } finally {
        this.isFetching = false;
      }
    },

    itemClicked: function ({ item, activityId }) {
      if (item.monitoring[`_${activityId}`]) {
        this.viewPatientMonitoringDetail(item.id, activityId);
      }
    },

    viewPatientMonitoringDetail: function (patientId, detailType) {
      this.$router.push({ name: 'PatientMonitoringGrid', params: { patientId: patientId, detailType: detailType } });
    },

    detailTypeUpdated(detailType) {
      this.$router.push({
        name: 'PatientMonitoringGrid',
        params: { patientId: this.patientId, detailType: detailType },
      });
    },

    dataGridConfigurationChanged(config) {
      let incontinenceActivities = this.activities.filter((x) => x.activityTypeCode === ActivityTypes.INC);
      if (!incontinenceActivities) {
        return;
      }
      let incontinenceKeys = incontinenceActivities.map((activity) => `activity._${activity.id}`);
      this.showIncontinence = config.visibility?.some((x) => incontinenceKeys.includes(x.key) && x.visible);
    },

    getPatientWorstActivity(patientMonitoringItem) {
      if (this.activities.length === 0) {
        return {};
      }

      let orderedStatusLevels = [...this.statusLevels].sort((a, b) => a.priority - b.priority);

      for (let iStatus = 0; iStatus < orderedStatusLevels.length; iStatus++) {
        const statusPriority = orderedStatusLevels[iStatus].code;

        for (let index = 0; index < this.activities.length; index++) {
          const monitoring = this.activities[index];
          if (patientMonitoringItem.monitoring[`_${monitoring.id}`]?.valueCode === statusPriority) {
            return monitoring;
          }
        }
      }
      return this.activities[0];
    },

    updateAutoRefreshTimer() {
      if (this.autoRefreshTimer) {
        clearInterval(this.autoRefreshTimer);
      }

      if (this.autoRefreshInterval && !this.patientId) {
        this.autoRefreshTimer = setInterval(() => {
          this.init();
        }, this.autoRefreshInterval);
      }
    },

    saveAutoRefreshInterval() {
      document.cookie = `autoRefreshInterval=${this.autoRefreshInterval ?? 0}`;
    },
    getCookie(name) {
      var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) return match[2];
    },
    loadAutoRefreshInterval() {
      const value = Number(this.getCookie('autoRefreshInterval'));
      if (this.autoRefreshIntervals.some((x) => x.value === value)) {
        this.autoRefreshInterval = value;
      } else {
        this.autoRefreshInterval = null;
      }
    },
    getRoombedText(roombed) {
      return roombed.bed ? `${roombed.room} - ${roombed.bed}` : roombed.room;
    },
  },
};
</script>
<style scoped>
.monitoring-grid :deep(td) {
  cursor: pointer;
}

.patient-monitoring-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 8px;
}
.patient-monitoring-details__item {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 24px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
}
</style>
