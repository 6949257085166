<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <div class="ml-12 pt-3 mb-1 font-weight-medium text-body-1">{{ activityName }}</div>

    <div class="position-relative d-flex align-center">
      <div class="position-relative chart-container">
        <template v-if="vitalSignsActivityCodes.includes(activityCode)">
          <MonitoringElectrocardiogramChart
            v-if="activityCode === activityTypes.ECG"
            ref="monitoringElectrocardiogramChart"
            :activity-code="activityCode"
            :activity-name="activityName"
            :chart-group="chartGroup"
            :duration="chartDuration"
            :filters="filters"
            :is-processing="isProcessing"
            :patient-id="patientId"
            :scroll-to-view="scrollToView"
            :unique-key="uniqueKey"
            :values="values"
            @isChartLoading="onChartLoading"
            @onError="handleError"
          />

          <MonitoringLineChart
            v-else-if="chartDuration === '24h'"
            ref="monitoringLineChart"
            :activity-code="activityCode"
            :chart-group="chartGroup"
            :unique-key="uniqueKey"
            :filters="filters"
            :from-notification="fromNotification"
            :is-processing="isProcessing"
            :scroll-to-view="scrollToView"
            :thresholds="thresholds"
            :values="values"
            @isChartLoading="onChartLoading"
            @onError="handleError"
          />

          <MonitoringBarChart
            v-else
            ref="monitoringBarChart"
            :activity-code="activityCode"
            :chart-group="chartGroup"
            :unique-key="uniqueKey"
            :duration="chartDuration"
            :filters="filters"
            :is-processing="isProcessing"
            :scroll-to-view="scrollToView"
            :thresholds="thresholds"
            :values="values"
            @isChartLoading="onChartLoading"
            @onError="handleError"
          />
        </template>

        <MonitoringLabelLineChart
          v-else-if="[activityTypes.GLY, activityTypes.WEI].includes(activityCode)"
          ref="monitoringLabelLineChart"
          :activity-code="activityCode"
          :activity-name="activityName"
          :chart-duration="chartDuration"
          :chart-type="chartType"
          :filters="filters"
          :from-notification="fromNotification"
          :is-processing="isProcessing"
          :selected-unit="selectedUnit"
          :thresholds="thresholds"
          :values="values"
        />

        <WaitModal :show="isProcessing" class="wait-modal-absolute" />
      </div>

      <div
        :id="`${uniqueKey}-monitoring-chart-legend-container`"
        class="monitoring-chart-legend-container mb-2"
        :class="vitalSignsActivityCodes.includes(activityCode) ? 'width-205' : ' width-195'"
      />

      <ChartTooltip ref="chartTooltip" :unique-key="uniqueKey" />
    </div>
  </div>
</template>

<script>
import ChartTooltip from './ChartTooltip.vue';

import MonitoringBarChart from './MonitoringBarChart.vue';
import MonitoringElectrocardiogramChart from './MonitoringElectrocardiogramChart.vue';
import MonitoringLabelLineChart from './MonitoringLabelLineChart.vue';
import MonitoringLineChart from './MonitoringLineChart.vue';

import { ActivityTypes, VitalSignsActivityCodes } from '@/components/PatientMonitoring/constants';
import { chartTypes } from '@/components/PatientMonitoring/Chart/utils/constants.js';

export default {
  name: 'MonitoringChartContainer',
  components: {
    ChartTooltip,
    MonitoringBarChart,
    MonitoringElectrocardiogramChart,
    MonitoringLabelLineChart,
    MonitoringLineChart,
  },

  props: {
    activityCode: { type: String, required: true },
    activityId: { type: Number, default: null },
    activityName: { type: String, required: true },
    chartDuration: { type: String, required: true },
    chartGroup: { type: String, default: null },
    chartType: { type: String, required: true },
    filters: { type: Object, required: true },
    fromNotification: { type: Boolean, default: false },
    isProcessing: { type: Boolean, required: true },
    patientId: { type: Number, default: null },
    scrollToView: { type: Boolean, defaut: false },
    selectedUnit: { type: String, default: undefined },
    thresholds: { type: [Array, Object], default: null },
    uniqueKey: { type: String, required: true },
    values: { type: Array, required: true },
  },

  data() {
    return {
      activityTypes: ActivityTypes,
      chartTypes: chartTypes,
      error: null,
      vitalSignsActivityCodes: VitalSignsActivityCodes,
    };
  },

  methods: {
    onChartLoading: function (state = false) {
      this.$emit('isChartLoading', this.activityId, state);
    },

    handleError: function (error) {
      this.error = error;
    },
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.chart-container {
  flex: 1;
  min-width: 0;
}

.monitoring-chart-legend-container {
  flex-shrink: 0;
}

.width-205 {
  width: 205px;
}

.width-195 {
  width: 195px;
}

.wait-modal-absolute {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
</style>

<style lang="scss">
div.monitoring-chart-legend-container {
  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      user-select: none;
      margin-left: 10px;
      margin-bottom: 3px;
      font-size: 14px;

      span {
        flex-shrink: 0;
        height: 18px;
        width: 25px;
        margin-right: 8px;
        border-width: 2px;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
</style>
