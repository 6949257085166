<template>
  <v-container>
    <!-- <ConfirmationModal
      :question="$t('deleteIotQuestion')"
      :title="$t('delete')"
      :show="showDelete"
      @cancel="showDelete = false"
      @ok="deleteSelectedIot"
    /> -->

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('iot') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.iotDevice)">
      <v-col>
        <v-btn ref="addIot" color="primary" @click="editIotAction(null)">
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <DataGrid
          ref="Grid"
          :items="iots"
          :columns="columns"
          grid-name="iots_grid"
          :show-select="false"
          :items-per-page="25"
          :data-loading="showWaitModal"
        >
          <template #item.associated="{ item }">
            <v-simple-checkbox v-model="item.associated" disabled />
          </template>

          <template #item.actions="{ item }">
            <v-menu :ref="`actionMenu_${item.id}`">
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-if="$can(permissions.actions.change, permissions.subjects.iotDevice)">
                  <v-list-item :ref="`editIot_${item.id}`" @click="editIotAction(item.id)">
                    <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                  </v-list-item>
                  <template
                    v-if="
                      $can(permissions.actions.change, permissions.subjects.patientIotDevice) && isIotConfigurable(item)
                    "
                  >
                    <v-divider></v-divider>
                    <v-list-item :ref="`editIotConfiguration_${item.id}`" @click="editIotConfAction(item)">
                      <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
                      <v-list-item-title>{{ $t('configure') }}</v-list-item-title>
                    </v-list-item>
                  </template>

                  <!-- <v-divider v-if="$can(permissions.actions.delete, permissions.subjects.iotDevice)" /> -->
                </template>
                <!-- <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.iotDevice)"
                  :ref="`deleteIot_${item.id}`"
                  @click="deleteAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <IotModal :show.sync="showEditModal" :iot-id="selectedIotId" @refresh="init()" />
    <IotRoomModal :iot-id="selectedIotId" :show.sync="showRoomModal" @refresh="init()" />
    <IotPatientConfiguration :iot-id="selectedIotId" :show.sync="showPatientDeviceModal" @refresh="init()" />
  </v-container>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import IotModal from './IotModal';
import IotRoomModal from './IotRoomModal';
import IotPatientConfiguration from './IotPatientConfiguration';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import iotService from '@/services/iotService';
import permissionMixin from '@/auth/permissionMixin';
import { IOTDeviceTypeCodes } from '../PatientMonitoring/constants';
import { IOTDeviceCompanies } from './constants';

export default {
  name: 'IotGrid',
  components: {
    DataGrid,
    IotRoomModal,
    IotModal,
    IotPatientConfiguration,
  },
  mixins: [translationMixin, accessibility, permissionMixin],
  props: {},
  data() {
    return {
      showDelete: false,
      showEditModal: false,
      showRoomModal: false,
      showPatientDeviceModal: false,
      error: null,
      selectedIotId: null,
      showWaitModal: false,
      iots: [],
    };
  },

  computed: {
    columns() {
      let columns = [
        {
          text: 'iotDeviceType',
          value: 'iotDeviceType.name',
          filterable: true,
        },
        {
          text: 'iotDeviceCompany',
          value: 'iotDeviceCompany',
          filterable: true,
        },
        {
          text: 'iotDeviceModel',
          value: 'iotDeviceModel',
          filterable: true,
        },
        {
          text: 'iotDeviceKit',
          value: 'kit',
          filterable: true,
        },
        {
          text: 'iotDeviceSerialNumber',
          value: 'serie',
          filterable: true,
        },
        {
          text: 'iotDeviceVersion',
          value: 'version',
          filterable: true,
        },
        {
          text: 'iotDeviceStatus',
          value: 'status',
          filterable: true,
        },
        {
          text: 'iotDeviceAssociated',
          value: 'associated',
          filterable: true,
        },
        {
          text: 'createdDate',
          value: 'createdDate',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdBy',
          value: 'createdBy',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedDate',
          value: 'modifiedDate',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedBy',
          value: 'modifiedBy',
          filterable: true,
          visible: false,
        },
      ];
      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.iotDevice)
        // || this.$can(this.permissions.actions.delete, this.permissions.subjects.iotDevice)
      ) {
        columns.push({
          text: 'actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },
  created: function () {
    this.init();
  },

  methods: {
    init: function () {
      this.iots = [];
      this.showWaitModal = true;
      this.showDelete = false;
      this.showEditModal = false;
      this.showPatientDeviceModal = false;

      this.getIotAction();
    },

    async getIotAction() {
      const data = {
        statusesIds: [],
        excludeAssociatedDevices: false,
      };

      this.showWaitModal = true;

      try {
        let iots = await iotService.getIots(data);
        this.iots = iots.map((item) => {
          return {
            id: item.id,
            iotDeviceType: item.iotdeviceModel.type,
            iotDeviceCompany: item.iotdeviceModel.company.name,
            iotDeviceModel: item.iotdeviceModel.name,
            kit: item.kit,
            serie: item.serie,
            version: item.version,
            status: item.status.name,
            associated: item.associated,
            createdDate: item.createdAt,
            createdBy: item.createdBy != null ? item.createdBy.username : '',
            modifiedDate: item.updatedAt,
            modifiedBy: item.updatedBy != null ? item.updatedBy.username : '',
          };
        });
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    editIotAction: function (id) {
      this.selectedIotId = id;
      this.showEditModal = true;
    },

    editIotConfAction: function (item) {
      this.selectedIotId = item.id;

      let deviceCode = item.iotDeviceType.code;

      if (
        deviceCode !== IOTDeviceTypeCodes.CAMERA &&
        deviceCode !== IOTDeviceTypeCodes.RADAR &&
        deviceCode !== IOTDeviceTypeCodes.INCONTINENCE
      ) {
        this.showPatientDeviceModal = true;
      } else {
        this.showRoomModal = true;
      }
    },

    // deleteAction: function (id) {
    //   this.selectedIotId = id;
    //   this.showDelete = true;
    // },

    isIotConfigurable(item) {
      return (
        item.iotDeviceType.code === IOTDeviceTypeCodes.VIRTUALASSISTANT ||
        item.iotDeviceType.code === IOTDeviceTypeCodes.CAMERA ||
        item.iotDeviceType.code === IOTDeviceTypeCodes.RADAR ||
        item.iotDeviceType.code === IOTDeviceTypeCodes.XPILL ||
        (item.iotDeviceType.code === IOTDeviceTypeCodes.BALANCE &&
          item.iotDeviceCompany === IOTDeviceCompanies.WITHINGS) ||
        item.iotDeviceType.code === IOTDeviceTypeCodes.INCONTINENCE
      );
    },

    // async deleteSelectedIot() {
    //   this.showDelete = false;
    //   this.showWaitModal = true;

    //   try {
    //     await iotService.deleteIot(this.selectedIotId);

    //     this.init();
    //   } catch (error) {
    //     this.error = error;
    //   }
    //   this.showWaitModal = false;
    // },
  },
};
</script>

<style scoped></style>
