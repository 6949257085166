import { render, staticRenderFns } from "./PatientActivityModal.vue?vue&type=template&id=06f58852&scoped=true&"
import script from "./PatientActivityModal.vue?vue&type=script&lang=js&"
export * from "./PatientActivityModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f58852",
  null
  
)

export default component.exports