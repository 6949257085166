<template>
  <div :id="`${uniqueKey}-chartjs-tooltip`" :ref="`${uniqueKey}-chartjs-tooltip`" class="chartjs-tooltip elevation-4">
    <div :id="`${uniqueKey}-tooltip-title`" :ref="`${uniqueKey}-tooltip-title`" class="tooltip-title" />
    <div :id="`${uniqueKey}-tooltip-label`" :ref="`${uniqueKey}-tooltip-label`" class="tooltip-label" />
    <div :id="`${uniqueKey}-tooltip-footer`" :ref="`${uniqueKey}-tooltip-footer`" class="tooltip-footer" />
  </div>
</template>

<script>
export default {
  name: 'ChartTooltip',

  props: {
    uniqueKey: { type: String, required: true },
  },
};
</script>

<style scoped>
.chartjs-tooltip {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  background: white;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  padding: 10px;
  z-index: 10;
}

.tooltip-title {
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px;
}

.tooltip-label {
  font-size: 12px;
}

.tooltip-footer {
  text-align: center;
  padding-top: 3px;
  font-size: 12px;
}

.tooltip-left::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
  border-width: 5px 5px 5px 0;
  border-style: solid;
  border-color: transparent var(--chart-tooltip-color, rgba(0, 0, 0, 0.75)) transparent transparent;
}

.tooltip-right::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  border-width: 5px 0 5px 5px;
  border-style: solid;
  border-color: transparent transparent transparent var(--chart-tooltip-color, rgba(0, 0, 0, 0.75));
}
</style>
