export default {
  en: {
    'user.users': 'Users',
    'user.user': 'User',
    'user.confirmDeleteUser': 'Are you sure you want to delete this user?',
    'user.createNewUser': 'Create New User',
    'user.editUser': 'Edit User',
    'user.email': 'Email',
    'user.createdAt': 'Created At',
    'user.lastLogin': 'Last login',
    'user.accessGroups': 'Access Groups',
    'user.facilities': 'Facilities',
    'user.resendInvitation': 'Send a new invitation email',
    'user.userInvitationResent': 'The invitation email has been resent',
    'user.confirmRemoveAccess':
      'By removing access groups, you may lose some functionality. Are you sure you want to proceed?',
    'userPreferences.emailNotificationsQuestion': 'Receive email notifications',
    'userPreferences.SMSNotificationsQuestion': 'Receive text message notifications',
    'userPreferences.SMSNotificationsQuestion.noPhoneNumber':
      'You will not receive any SMS notifications because no phone number is associated with your account.',

    //Two-Factor Authentication
    'mfa.description':
      'With Two-Step Verification, you can add an extra layer of security to your account in case your password is stolen.',
    'mfa.followSteps': 'Follow the steps below to activate Two-Step Verification',
    'mfa.downloadApp':
      "Download a Two-Step Verification app if you don't have one. You can download the free Google Authenticator app to proceed with two-step verification.",
    'mfa.androidDevices': 'Android devices',
    'mfa.iOSDevices': 'Apple devices (iPhone, iPad)',
    'mfa.googleAuthenticatorAndroid': 'Google Authenticator for Android',
    'mfa.googleAuthenticatorIOS': 'Google Authenticator on the App Store',
    'mfa.scanQRCode': 'Scan this QR code with your verification app',
    'mfa.enterCode': 'Enter the code generated by your verification app',
    'mfa.confirmDeactivate': 'Do you really want to deactivate Two-Step Verification?',
    'mfa.active': 'Active',
    'mfa.inactive': 'Inactive',
    'mfa.activate': 'Activate',
    'mfa.deactivate': 'Deactivate',
    'password.oldPassword': 'Old password',
  },
  fr: {
    'user.users': 'Utilisateurs',
    'user.user': 'Utilisateur',
    'user.confirmDeleteUser': 'Voulez-vous supprimer cet utilisateur?',
    'user.createNewUser': 'Créer un nouvel utilisateur',
    'user.editUser': "Modifier l'utilisateur",
    'user.email': 'Courriel',
    'user.createdAt': 'Date de création',
    'user.lastLogin': 'Dernière connexion',
    'user.accessGroups': "Groupes d'accès",
    'user.facilities': 'Installations',
    'user.resendInvitation': 'Envoyer un nouveau courriel d’invitation',
    'user.userInvitationResent': "Le courriel d'invitation a été renvoyé",
    'user.confirmRemoveAccess':
      "En retirant des groupes d'accès vous risquez de perdre certaines fonctionnalités. Désirez-vous vraiment enregistrer?",
    'userPreferences.emailNotificationsQuestion': 'Recevoir les notifications par courriel',
    'userPreferences.SMSNotificationsQuestion': 'Recevoir les notifications par messagerie texte',
    'userPreferences.SMSNotificationsQuestion.noPhoneNumber':
      "Vous ne recevrez aucune notifications par SMS car aucun numéro de téléphone n'est associé à votre compte.",

    //Two-Factor Authentication
    'mfa.description':
      "La vérification en deux étapes vous permet d'ajouter un niveau de sécurité supplémentaire afin de protéger votre compte en cas de vol de votre mot de passe.",
    'mfa.followSteps': 'Veuillez suivre la procédure suivante pour activer la vérification en deux étapes',
    'mfa.downloadApp':
      "Téléchargez une application de vérification en deux étapes si vous n'en avez pas une. Vous pouvez télécharger l'application gratuite Google Authenticator pour effectuer la vérification en deux étapes.",
    'mfa.androidDevices': 'Appareils Android',
    'mfa.iOSDevices': 'Appareils Apple (iPhone, iPad)',
    'mfa.googleAuthenticatorAndroid': 'Google Authenticator pour Android',
    'mfa.googleAuthenticatorIOS': "Google Authenticator dans l'App Store",
    'mfa.scanQRCode': 'Scannez ce code QR avec votre application de vérification',
    'mfa.enterCode': "Entrez le code de généré par l'application de vérification",
    'mfa.confirmDeactivate': 'Voulez-vous vraiment désactiver la vérification en deux étapes?',
    'mfa.active': 'Activée',
    'mfa.inactive': 'Désactivée',
    'mfa.activate': 'Activer',
    'mfa.deactivate': 'Désactiver',
    'password.oldPassword': 'Ancien mot de passe',
  },
};
