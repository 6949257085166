<template>
  <LabelLineChart
    ref="labelLineChart"
    :class="activityCode === activityTypes.WEI ? 'height-500' : 'height-545'"
    :chart-data="chartData"
    :chart-options="chartOptions"
  />
</template>

<script>
import { Line as LabelLineChart } from 'vue-chartjs';

import ChartJS from './utils/chartConfig.js';

import { LineController, LineElement, PointElement } from 'chart.js';

import {
  BaseChart,
  buildDateX2AxisTicks,
  getChartLegendTriggeredAlertValue,
  getLineChartAxisVisibleTicks,
  setChartXLimits,
  monitoringChartDefaultYAxis,
  removeChartTime,
  showOrHideAnnotationTooltip,
  showOrHideSelectedElement,
  updateAxisUnit,
  updateChartThresholds,
  updateChartValues,
  updateYAxisLimits,
  updateAndGetChartThresholdsLegendItems,
  updateAnnotationsTranslations,
} from './utils/chartUtils';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { addHours, addMinutes } from 'date-fns';
import { ActivityTypes, unitTypes } from '@/components/PatientMonitoring/constants.js';

import translationMixin, { LanguageVue } from '@/translationMixin';

ChartJS.register(ChartDataLabels, LineController, LineElement, PointElement);

export default {
  name: 'MonitoringLabelLineChart',
  components: { LabelLineChart },
  mixins: [translationMixin],

  props: {
    activityCode: { type: String, required: true },
    chartDuration: { type: String, required: true },
    chartType: { type: String, required: true },
    filters: { type: Object, required: true },
    fromNotification: { type: Boolean, default: false },
    isProcessing: { type: Boolean, required: true },
    selectedUnit: { type: String, default: undefined },
    thresholds: { type: Array, required: true },
    values: { type: Array, required: true },
  },

  data() {
    const baseChart = new BaseChart(this.activityCode, this.chartType, {
      afterLabelCallback: this.getVariationDetails,
      selectedUnit: this.selectedUnit,
      showOrHideSelectedElementCallback: this.showOrHideSelectedElementCallback,
      xAxisCallback: this.updateAxisVisibleTicks,
    });

    return {
      activityTypes: ActivityTypes,
      chartData: baseChart.createChartDatasets(),
      chartOptions: baseChart.createChartOptions(),

      error: null,
      spacingMinutes: this.getSpaceMinutes(),
    };
  },

  watch: {
    chartDuration: function () {
      const isDuration24h = this.chartDuration === '24h';
      this.chartOptions.scales.x2.grid.display = !isDuration24h;

      if (!isDuration24h) {
        this.chartOptions.scales.x2.afterBuildTicks = null;
      } else {
        this.chartOptions.scales.x2.afterBuildTicks = (axis) => buildDateX2AxisTicks(axis);
      }
    },
    filters: function () {
      this.setSpaceMinutes();
      this.updateXAxisLimits();
    },
    selectedUnit: function (newUnit, oldUnit) {
      this.chartOptions.selectedUnit = newUnit;
      if (newUnit === oldUnit) return;

      if (newUnit === unitTypes.lbs && oldUnit === unitTypes.kg) {
        this.chartOptions.scales.y.max = Math.ceil((this.chartOptions.scales.y.max * 2.205) / 10) * 10;
        this.chartOptions.scales.y.min = Math.ceil((this.chartOptions.scales.y.min * 2.205) / 10) * 10;
      } else if (newUnit === unitTypes.kg && oldUnit === unitTypes.lbs) {
        this.chartOptions.scales.y.max = Math.floor(this.chartOptions.scales.y.max / 2.205 / 10) * 10;
        this.chartOptions.scales.y.min = Math.floor(this.chartOptions.scales.y.min / 2.205 / 10) * 10;
      }

      this.setYAxisTranslation(newUnit);
    },
    thresholds: function (newThresholds, oldThresholds) {
      updateChartThresholds(
        this.showOrHideAnnotationTooltipCallback,
        this.chartType,
        this.chartData,
        this.chartOptions,
        this.activityCode,
        newThresholds,
        oldThresholds
      );
    },
    values: function (newValues, oldValues) {
      this.updateChartValuesIfNewValues(this.chartData, newValues, oldValues);
    },
  },

  created() {
    LanguageVue.$on('projectLanguage', this.updateAllChartTranslations);
    this.initializeChartConfig();
    this.initializeChartDatasets();
  },

  mounted() {
    if (this.scrollToView) {
      this.$el.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  },

  beforeDestroy() {
    LanguageVue.$off('projectLanguage', this.updateAllChartTranslations);
  },

  methods: {
    initializeChartConfig: function () {
      this.setTranslations();
      this.updateXAxisLimits();
      updateYAxisLimits(this.chartOptions, monitoringChartDefaultYAxis[this.activityCode]);
    },

    setTranslations: function () {
      this.chartOptions.scales.x.time.displayFormats = {
        hour: this.getLanguage() === 'fr' ? 'HH:mm' : 'h:mm aa',
        minute: this.getLanguage() === 'fr' ? 'HH:mm' : 'h:mm aa',
        second: this.getLanguage() === 'fr' ? 'HH:mm:ss' : 'HH:mm:ss aa',
        millisecond: this.getLanguage() === 'fr' ? 'HH:mm:ss.SSS' : 'HH:mm:ss.SSS aa',
      };

      this.setYAxisTranslation(this.selectedUnit ?? `${this.activityCode}Unit`);
    },

    setYAxisTranslation: function (chartUnit) {
      this.chartOptions.scales.y.title.text = `${this.$t(`${this.activityCode}FullName`)} (${this.$t(chartUnit)})`;
    },

    initializeChartDatasets() {
      let chartData = this.chartData;

      if (Object.keys(this.thresholds).length) {
        chartData.datasets.push(
          ...updateAndGetChartThresholdsLegendItems(
            this.chartType,
            this.activityCode,
            this.showOrHideAnnotationTooltipCallback,
            this.chartOptions,
            this.thresholds
          )
        );
      }

      chartData.datasets.push(getChartLegendTriggeredAlertValue(this.uniqueKey));

      if (this.values.length) {
        updateChartValues(chartData, this.chartOptions, this.chartType, this.values, this.activityCode);
      }

      this.chartData.datasets = chartData.datasets;
    },

    showOrHideAnnotationTooltipCallback: function (data, event, thresholdIndex, showAnnotationTooltip) {
      showOrHideAnnotationTooltip(this.chartOptions, data, event, thresholdIndex, showAnnotationTooltip);
      this.$refs.labelLineChart.chart.update('none'); // Need this to update the chart and show the tooltip ('none' to avoid animation)
    },

    adjustDateRange(dateFrom, dateTo) {
      let adjustedMinDate = dateFrom;
      let adjustedMaxDate = dateTo;

      if (this.fromNotification) {
        let parsedMinDate = new Date(dateFrom);
        let parsedMaxDate = new Date(dateTo);

        parsedMinDate = addHours(parsedMinDate, 11);
        parsedMaxDate = addHours(parsedMaxDate, -11);

        adjustedMinDate = parsedMinDate;
        adjustedMaxDate = parsedMaxDate;
      }

      return { adjustedMinDate, adjustedMaxDate };
    },

    updateXAxisLimits: function () {
      let xMinDate;
      let xMaxDate;
      let originalXMinDate;
      let originalXMaxDate;

      const currentDate = new Date();
      const currentDateFormatted = currentDate;

      if (this.filters.timeFilterType === 'range') {
        originalXMinDate = this.filters.dateFrom;
        originalXMaxDate = this.filters.dateTo;

        ({ adjustedMinDate: xMinDate, adjustedMaxDate: xMaxDate } = this.adjustDateRange(
          originalXMinDate,
          originalXMaxDate
        ));

        if (this.fromNotification && originalXMaxDate > currentDateFormatted) {
          originalXMaxDate = addMinutes(currentDate, this.spacingMinutes);
        }
      } else {
        originalXMaxDate = currentDateFormatted;

        let timeFilterValue;
        let timeFilterType;

        if (!this.filters.timeFilterType || this.filters.timeFilterType === '24h') {
          timeFilterValue = 24;
          timeFilterType = 'hours';
        } else {
          timeFilterValue = this.filters.timeFilterValue;
          timeFilterType = this.filters.timeFilterType;
        }

        originalXMinDate = removeChartTime(new Date(currentDate), timeFilterType, timeFilterValue);

        xMinDate = originalXMinDate;
        xMaxDate = originalXMaxDate;
      }

      xMaxDate = addMinutes(new Date(xMaxDate), this.spacingMinutes);
      xMinDate = addMinutes(new Date(xMinDate), this.spacingMinutes * -1);

      setChartXLimits(this.chartOptions, xMaxDate, xMinDate);

      this.chartOptions.plugins.zoom.limits = {
        x: {
          max: new Date(this.chartOptions.scales.x.max),
          min: new Date(this.chartOptions.scales.x.min),
        },
      };
    },

    updateChartValuesIfNewValues: function (chartData = this.chartData, newValues, oldValues) {
      if (chartData.datasets && JSON.stringify(newValues) !== JSON.stringify(oldValues)) {
        updateChartValues(chartData, this.chartOptions, this.chartType, newValues, this.activityCode);
      }
    },

    updateAllChartTranslations: function () {
      this.setTranslations();
      updateAnnotationsTranslations(this.chartOptions);
    },

    setSpaceMinutes: function () {
      this.spacingMinutes = this.getSpaceMinutes();
    },

    getSpaceMinutes: function () {
      return this.filters?.timeFilterType === 'days' && this.filters?.timeFilterValue > 3 ? 600 : 45;
    },

    getVariationDetails: function (data) {
      const alertDetails = data?.raw?.alertDetails;
      if (!alertDetails?.length) return null;

      return alertDetails.map((alert) => {
        const unitType =
          alert.unitType === 'percentageVariation'
            ? '%'
            : ` ${
                alert.unitType === 'lbs' && (this.getLanguage() === 'fr' || alert.threshold <= 1)
                  ? 'lb'
                  : alert.unitType
              }`;

        const durationType = this.$t(
          alert.lengthType === 'days' ? (alert.length === 1 ? 'day' : 'days') : alert.lengthType
        ).toLowerCase();

        const thresholdValue = `${alert.threshold}${unitType}`;
        const duration = alert.length + ' ' + durationType;

        return `${this.$t('variationAlertMessage')}${this.getLanguage() === 'fr' ? ' :' : ':'} ${this.$t(
          'variationAlertContent'
        )
          .replace('{{threshold}}', thresholdValue)
          .replace('{{duration}}', duration)}`;
      });
    },

    showOrHideSelectedElementCallback: function (legendItem) {
      showOrHideSelectedElement(this.chartData, this.chartOptions, legendItem);
    },

    updateAxisVisibleTicks: function (time) {
      const visibleXAxis = this.$refs.labelLineChart.chart?.scales?.x;
      return getLineChartAxisVisibleTicks(visibleXAxis, time);
    },

    updateAxisUnit: function (chart) {
      const xMin = chart.scales.x.min;
      const xMax = chart.scales.x.max;

      updateAxisUnit(this.chartOptions, xMax, xMin);
    },
  },
};
</script>

<style scoped>
.height-545 {
  height: 545px;
}

.height-500 {
  height: 500px;
}
</style>
