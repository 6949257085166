<template>
  <v-card class="monitoring-details-chart-card">
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="!chartParameters" class="pt-8" />

    <template v-if="chartParameters">
      <ManualEntryButton
        v-if="chartParameters.hasPatientIotDevice === false"
        :patient-id="patientId"
        :activity-type-codes="orderedActivities.map((activity) => activity.code)"
        @updateChartData="updateChartDataOnManualEntry"
      />

      <div v-for="(activity, chartIndex) in orderedActivities" :key="chartIndex" class="py-5">
        <MonitoringChartContainer
          :ref="`chart-${activity.id}`"
          :activity-code="activity.code"
          :activity-id="activity.id"
          :activity-name="activity.name"
          :chart-group="chartGroup"
          :chart-type="
            chartParameters.duration === '24h' ? chartTypes.monitoringLineChart : chartTypes.monitoringBarChart
          "
          :chart-duration="chartParameters.duration"
          :filters="filters"
          :from-notification="fromNotification"
          :is-processing="isChartProcessing(activity.id)"
          :patient-id="patientId"
          :scroll-to-view="chartIndex > 0 && selectedActivityId == activity.id"
          :thresholds="
            vitalSignsData[activity.id]
              ? vitalSignsData[activity.id].thresholds
                ? vitalSignsData[activity.id].thresholds
                : {}
              : {}
          "
          :unique-key="getChartUniqueKey(activity, chartIndex)"
          :values="
            vitalSignsData[activity.id]
              ? vitalSignsData[activity.id].results
                ? vitalSignsData[activity.id].results
                : []
              : []
          "
          @isChartLoading="onChartLoading"
        />
      </div>
    </template>
  </v-card>
</template>

<script>
import ManualEntryButton from '@/components/PatientMonitoring/ManualEntry/ManualEntryButton.vue';
import MonitoringChartContainer from '../Chart/MonitoringChartContainer.vue';

import filterMixin from '@/components/PatientMonitoring/filterMixin';
import patientMonitoringService from '@/services/patientMonitoringService';
import translationMixin from '@/translationMixin';

import { ActivityMonitoringValues, ActivityTypes } from '@/components/PatientMonitoring/constants';
import { chartTypes } from '@/components/PatientMonitoring/Chart/utils/constants.js';

export default {
  name: 'PatientVitalSigns',
  components: { ManualEntryButton, MonitoringChartContainer },
  mixins: [filterMixin, translationMixin],

  props: {
    activitiesData: {
      type: Array,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },

    patientId: {
      type: Number,
      required: true,
    },

    selectedActivityId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      activityTypes: ActivityTypes,
      chartGroup: `vitalSigns-${this.patientId}`,
      chartParameters: null,
      chartTypes: chartTypes,
      error: {},
      fromNotification: false,
      isProcessing: false,
      requestFilters: null,
      showVitalSignsManualEntry: false,
      vitalSignsData: {},
    };
  },

  computed: {
    dateFrom() {
      return this.calculateChartDate('start');
    },

    dateTo() {
      return this.calculateChartDate('end');
    },

    orderedActivities: function () {
      return [...this.activitiesData]
        .sort((a, b) => (a.order !== b.order ? a.order - b.order : a.name.localeCompare(b.name)))
        .map((x) => ({ id: x.id, code: x.code, name: x.name }));
    },
  },

  watch: {
    filters: async function () {
      this.isProcessing = true;

      for (const key in this.vitalSignsData) {
        this.vitalSignsData[key].isLoading = true;
        this.vitalSignsData[key].results = [];
      }

      await this.loadAllChartsData();
      this.isProcessing = false;
    },

    patientId: async function () {
      this.isProcessing = true;

      this.chartParameters = null;
      this.vitalSignsData = {};
      await this.loadAllChartsData();
      this.isProcessing = false;
    },

    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          if ((oldVal && oldVal.fromNotification) || (newVal && newVal.fromNotification)) {
            this.$emit('update:request-filters', newVal);
            this.fromNotification = true;
          }
        }
      },
      deep: true,
    },
  },

  async created() {
    this.fromNotification = this.requestFilters !== null;
    await this.loadAllChartsData();
  },

  methods: {
    loadAllChartsData: async function (monitoredActivityTypeCodes = this.activitiesData.map((x) => x.code)) {
      this.requestFilters = this.setRequestFilters();

      await this.getPatientMonitoringChartParameters(monitoredActivityTypeCodes);
      await this.getPatientVitalSignsData();
    },

    async getPatientVitalSignsData() {
      const allMonitoringPromises = this.getMonitoringVitalSignsPromises({
        ...this.requestFilters,
        ...this.chartParameters,
      });

      await this.updateVitalSignsData(allMonitoringPromises);
    },

    updateVitalSignsData: async function (promises) {
      const updates = promises.map(async ({ promise, activityId }) => {
        try {
          const result = await promise;

          result.thresholds?.[ActivityMonitoringValues.BodyTemperature]?.forEach((threshold) => {
            if (threshold.unit === 'fahrenheit') {
              threshold.value = parseFloat((((threshold.value - 32) * 5) / 9).toFixed(1));
              threshold.convertedFrom = threshold.unit;
              threshold.unit = 'celsius';
            }
          });

          result.isLoading = true;

          this.$set(this.vitalSignsData, activityId, result);
        } catch (error) {
          this.error = error;
        }
      });

      await Promise.allSettled(updates);
    },

    getPatientMonitoringChartParameters: async function (monitoredActivityTypeCodes) {
      try {
        this.chartParameters = await patientMonitoringService.getPatientMonitoringChartParameters({
          ...this.requestFilters,
          monitoredActivityTypeCodes,
        });
      } catch (error) {
        this.error = error;
      }
    },

    updateChartDataOnManualEntry: async function (activityCodes) {
      for (const key in this.vitalSignsData) {
        if (activityCodes.includes(key)) {
          this.vitalSignsData[key].isLoading = true;
          this.vitalSignsData[key].results = [];
        }
      }

      await this.loadAllChartsData(activityCodes);
    },

    getMonitoringVitalSignsPromises: function (requestFilter) {
      const promiseOnActivityCode = {
        [ActivityTypes.APR]: patientMonitoringService.getPatientBloodPressure,
        [ActivityTypes.CFR]: patientMonitoringService.getPatientCardiacFrequency,
        [ActivityTypes.SAT]: patientMonitoringService.getPatientSaturation,
        [ActivityTypes.RES]: patientMonitoringService.getPatientRespiratoryRate,
        [ActivityTypes.BDT]: patientMonitoringService.getPatientBodyTemperature,
        [ActivityTypes.ECG]: patientMonitoringService.getPatientMonitoringEcg,
      };

      const createPromisesForActivitiesData = () => {
        return this.activitiesData.map((activity) => {
          const promiseToResolve = promiseOnActivityCode?.[activity.code];

          const activityThresholds = this.activitiesData
            .filter((x) => x.id === activity.id)
            .flatMap((y) => y.alerts?.map((alert) => alert.alertId));

          return promiseToResolve
            ? {
                activityId: activity.id,
                promise: promiseToResolve({
                  ...requestFilter,
                  activityId: activity.id,
                  activityThresholds: activityThresholds,
                }),
              }
            : Promise.resolve([]);
        });
      };

      return createPromisesForActivitiesData();
    },

    isChartProcessing: function (activityId) {
      return this.isProcessing || this.vitalSignsData?.[activityId]?.isLoading !== false;
    },

    onChartLoading: function (activityId, state) {
      if (this.vitalSignsData?.[activityId]) {
        this.vitalSignsData[activityId].isLoading = state;
      }
    },

    getChartUniqueKey: function (activity, chartIndex) {
      return `${activity.code}-${chartIndex}`;
    },
  },
};
</script>
