import api from './baseApiService';

export class PatientMonitoringService {
  async getPatientBloodPressure(data) {
    return await api.post('patientmonitoringarterialpressure/', data);
  }

  async getPatientBodyTemperature(data) {
    return await api.post('patientmonitoringbodytemperature/', data);
  }

  async getPatientCardiacFrequency(data) {
    return await api.post('patientmonitoringcardiacfrequency/', data);
  }

  async getPatientSaturation(data) {
    return await api.post('patientmonitoringsaturation/', data);
  }

  async getPatientRespiratoryRate(data) {
    return await api.post('patientmonitoringrespiratoryrate/', data);
  }

  async getPatientWeight(data) {
    return await api.post('patientmonitoringweight/', data);
  }

  async getPatientFallDetection(data) {
    return await api.post('patientmonitoringfalldetection/', data);
  }

  async getPatientPrescriptiveLearning(data) {
    return await api.post('patientmonitoringprescriptivelearning/', data);
  }

  async getPatientIncontinence(data) {
    return await api.post('patientmonitoringincontinence/', data);
  }

  async getPatientPrescription(data) {
    return await api.post('patientmonitoringprescription/', data);
  }

  async getPatientCapillaryGlycemia(data) {
    return await api.post('patientmonitoringcapillaryglycemia/', data);
  }

  async getPatientDialoguesResults(data) {
    return await api.post('patientdialogueresults/', data);
  }

  async getPatientMonitoringSummary(data) {
    return await api.post('patientmonitoringsummary/', data);
  }

  async getPatientPhotosResults(data) {
    return await api.post('patientphotoresults/', data);
  }

  async getPatientPhotoResultsPictureUrl(patientPictureResultId) {
    return await api.get(`patientphotoresults/pictureurl/${patientPictureResultId}`);
  }

  async getPatientMonitoringChartParameters(data) {
    return await api.post('patientmonitoringchartparameters/', data);
  }

  async getPatientMonitoringEcg(data) {
    return await api.post('patientmonitoringecg/', data);
  }

  async getStatusLevels() {
    return await api.get('statuslevels');
  }

  async saveManualEntryVitalSigns(data) {
    return await api.post('patientmanualentry/', data);
  }
}

export default new PatientMonitoringService();
