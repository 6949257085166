<template>
  <BarChart
    ref="barChart"
    :chart-data="chartData"
    :chart-options="chartOptions"
    class="monitoring-details-line-chart"
  />
</template>

<script>
import { Bar as BarChart } from 'vue-chartjs';

import ChartJS from './utils/chartConfig.js';

import translationMixin, { LanguageVue } from '@/translationMixin';

import { BarElement, LineController, LineElement, PointElement } from 'chart.js';
import { differenceInHours, differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';

import 'chartjs-adapter-date-fns';
import { StatsDuration } from '../constants';
import { chartTypes } from '@/components/PatientMonitoring/Chart/utils/constants.js';
import EventBus from './utils/monitoringChartEventBus.js';

import {
  BaseChart,
  getBarChartXAxisValues,
  getBarChartX2AxisValues,
  showOrHideAnnotationTooltip,
  getChartLegendTriggeredAlertValue,
  monitoringChartDefaultYAxis,
  showOrHideSelectedElement,
  updateAnnotationsTranslations,
  updateChartThresholds,
  updateChartValues,
  updateYAxisLimits,
  updateXAxisLimitsForStatChart,
  updateAndGetChartThresholdsLegendItems,
} from './utils/chartUtils';

ChartJS.register(BarElement, LineController, LineElement, PointElement);

export default {
  name: 'MonitoringBarChart',
  components: { BarChart },
  mixins: [translationMixin],
  props: {
    activityCode: { type: String, required: true },
    chartGroup: { type: String, required: true },
    duration: { type: String, default: null },
    filters: { type: Object, required: true },
    isProcessing: { type: Boolean, required: true },
    scrollToView: { type: Boolean, required: true },
    thresholds: { type: Object, required: true },
    uniqueKey: { type: String, required: true },
    values: { type: Array, required: true },
  },

  data() {
    const baseChart = new BaseChart(this.activityCode, chartTypes.monitoringBarChart, {
      chartGroup: this.chartGroup,
      onPanCallback: this.emitMouseMove,
      onZoomCallback: this.reactOnZoom,
      showOrHideSelectedElementCallback: this.showOrHideSelectedElementCallback,
      uniqueKey: this.uniqueKey,
      xAxisCallback: (time) => getBarChartXAxisValues(time, this.duration, true),
      x2AxisCallback: (time) => getBarChartX2AxisValues(time, this.duration),
    });

    return {
      chartData: baseChart.createChartDatasets(),
      chartOptions: baseChart.createChartOptions(),

      chartType: chartTypes.monitoringBarChart,
      isChartHovered: false,
      maximumTimeCategoryPercentage: null,
    };
  },

  watch: {
    duration: function (newDuration) {
      this.chartOptions.duration = newDuration;
      updateXAxisLimitsForStatChart(this.chartOptions, this.filters, newDuration);
      this.setMaximumTimeCategoryPercentage();
    },
    filters: function (filters) {
      updateXAxisLimitsForStatChart(this.chartOptions, filters, this.duration);
    },
    thresholds: function (newThresholds, oldThresholds) {
      updateChartThresholds(
        this.showOrHideAnnotationTooltipCallback,
        this.chartType,
        this.chartData,
        this.chartOptions,
        this.activityCode,
        newThresholds,
        oldThresholds
      );
    },
    values: function (newValues, oldValues) {
      this.updateChartValuesIfNewValues(this.chartData, newValues, oldValues);
    },
  },

  created() {
    EventBus.$on('mouse-move', this.updateXAxis);
    LanguageVue.$on('projectLanguage', this.updateAllChartTranslations);

    this.initializeChartConfig();
    this.initializeChartDatasets();
  },

  mounted() {
    const chartCanvas = this.$refs.barChart?.chart?.canvas;

    chartCanvas?.addEventListener('mouseenter', () => this.handleMouseEnter());
    chartCanvas?.addEventListener('mouseleave', () => this.handleMouseLeave());

    if (this.scrollToView) {
      this.$el.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  },

  beforeDestroy() {
    const chartCanvas = this.$refs.barChart?.chart?.canvas;

    chartCanvas?.removeEventListener('mouseenter', () => this.handleMouseEnter());
    chartCanvas?.removeEventListener('mouseleave', () => this.handleMouseLeave());

    EventBus.$off('mouse-move', this.updateXAxis);
    LanguageVue.$off('projectLanguage', this.updateAllChartTranslations);
  },

  methods: {
    initializeChartConfig: function () {
      this.setTranslations();
      updateXAxisLimitsForStatChart(this.chartOptions, this.filters, this.duration);
      updateYAxisLimits(this.chartOptions, monitoringChartDefaultYAxis[this.activityCode]);
      this.chartOptions.duration = this.duration;
      this.setMaximumTimeCategoryPercentage();
    },

    emitMouseMove: function (chart) {
      EventBus.$emit('mouse-move', chart);
    },

    reactOnZoom: function (chart) {
      this.emitMouseMove(chart);
      this.setSpaceBetweensBarAndAlertBars(chart.scales.x.options);
    },

    handleMouseEnter: function () {
      this.isChartHovered = true;
    },
    handleMouseLeave: function () {
      this.isChartHovered = false;
    },

    updateAllChartTranslations: function () {
      this.$emit('isChartLoading', true);

      this.setTranslations();

      this.chartData.datasets
        ?.filter((dataset) => dataset.legendType === 'threshold')
        ?.forEach((dataset) => {
          dataset.label = this.$t(dataset.untranslatedLabel);
        });

      updateAnnotationsTranslations(this.chartOptions);
      this.$emit('isChartLoading', false);
    },

    initializeChartDatasets: function () {
      let chartData = this.chartData;

      if (Object.keys(this.thresholds).length) {
        chartData.datasets.push(
          ...updateAndGetChartThresholdsLegendItems(
            this.chartType,
            this.activityCode,
            this.showOrHideAnnotationTooltipCallback,
            this.chartOptions,
            this.thresholds
          )
        );
      }

      chartData.datasets.push(getChartLegendTriggeredAlertValue(this.uniqueKey));

      const values = this.values;

      if (values.length) {
        updateChartValues(chartData, this.chartOptions, this.chartType, this.values, this.activityCode);
      }

      this.chartData.datasets = chartData.datasets;

      if (values.length) {
        this.$emit('isChartLoading', false);
      }
    },

    updateChartValuesIfNewValues: function (chartData = this.chartData, newValues, oldValues) {
      if (chartData.datasets && JSON.stringify(newValues) !== JSON.stringify(oldValues)) {
        updateChartValues(chartData, this.chartOptions, this.chartType, newValues, this.activityCode);
      }

      this.$emit('isChartLoading', false);
    },

    updateYAxisLimits: function (yAxisLimits) {
      this.chartOptions.scales.y.min = yAxisLimits.yMin;
      this.chartOptions.scales.y.max = yAxisLimits.yMax;
      this.chartOptions.scales.y.ticks.stepSize = yAxisLimits.yStepSize;
    },

    setTranslations: function () {
      this.chartOptions.scales.y.title.text = `${this.$t(`${this.activityCode}FullName`)} (${this.$t(
        `${this.activityCode}Unit`
      )})`;
    },

    getDatasetModel: function () {
      return {
        label: null,
        backgroundColor: [],
        hoverBackgroundColor: [],
        data: [],
        borderRadius: 8,
        borderSkipped: false,
        minBarLength: 8,
        maxBarThickness: 15,
        categoryPercentage: 0.15,
        legendType: 'data',
        normalized: false,
        hidden: false,
        parsing: true,
      };
    },

    updateXAxis(chart) {
      const position = chart.scales.x;

      this.chartOptions.scales.x.min = position.min;
      this.chartOptions.scales.x.max = position.max;

      this.chartOptions.scales.x2.min = position.min;
      this.chartOptions.scales.x2.max = position.max;
    },

    setSpaceBetweensBarAndAlertBars: function (chartXOptions) {
      if (this.values?.length === 0 || this.duration === StatsDuration.WEEK) return;
      let differenceTimeXAxis;

      switch (this.duration) {
        case StatsDuration.HOUR:
          differenceTimeXAxis = differenceInHours(new Date(chartXOptions.max), new Date(chartXOptions.min));
          break;

        case StatsDuration.DAY:
          differenceTimeXAxis = differenceInDays(new Date(chartXOptions.max), new Date(chartXOptions.min));
          break;

        case StatsDuration.MONTH:
          differenceTimeXAxis = differenceInMonths(new Date(chartXOptions.max), new Date(chartXOptions.min));
          break;

        case StatsDuration.YEAR:
          differenceTimeXAxis = differenceInYears(new Date(chartXOptions.max), new Date(chartXOptions.min));
          break;
      }

      for (const dataset of this.chartData?.datasets || []) {
        if (dataset.data?.length) {
          dataset.categoryPercentage = differenceTimeXAxis >= this.maximumTimeCategoryPercentage ? 0.6 : 0.15;
        }
      }
    },

    formatXAxisValues: function (value) {
      return getBarChartXAxisValues(value, this.duration, true);
    },

    showOrHideAnnotationTooltipCallback: function (data, event, thresholdIndex, showAnnotationTooltip) {
      if (!this.isChartHovered) return;

      showOrHideAnnotationTooltip(this.chartOptions, data, event, thresholdIndex, showAnnotationTooltip);
      this.$refs.barChart.updateChart();
    },

    showOrHideSelectedElementCallback: function (legendItem) {
      showOrHideSelectedElement(this.chartData, this.chartOptions, legendItem);
    },

    setMaximumTimeCategoryPercentage: function () {
      switch (this.duration) {
        case StatsDuration.HOUR:
          this.maximumTimeCategoryPercentage = 9;
          break;

        case StatsDuration.DAY:
          this.maximumTimeCategoryPercentage = 8;
          break;

        case StatsDuration.WEEK: {
          this.maximumTimeCategoryPercentage = 9;
          break;
        }

        case StatsDuration.MONTH: {
          this.maximumTimeCategoryPercentage = 9;
          break;
        }

        case StatsDuration.YEAR:
          this.maximumTimeCategoryPercentage = 15;
          break;
      }
    },
  },
};
</script>
