import { CaregiverFormData } from './CaregiverFormData';
import { BaselinesFormData } from './BaselinesFormData';
import { HealthInfoFormData } from './HealthInfoFormData';

class PatientFormData {
  constructor() {
    this.reset();
  }

  reset() {
    this.firstName = '';
    this.lastName = '';
    this.mrn = null;
    this.birthDate = null;
    this.nasm = '';
    this.phone = '';
    this.email = '';
    this.address = '';
    this.city = '';
    this.postalCode = '';
    this.countryId = null;
    this.gender = null;
    this.language = null;
    this.patientType = null;
    this.provinceId = null;
    this.active = true;
    this.organizationId = null;
    this.facilityId = null;
    this.roombedId = null;
    this.startDate = null;
    this.endDate = null;
    this.baselines = new BaselinesFormData();
    this.healthInfo = new HealthInfoFormData();
    this.caregiverInfo = [new CaregiverFormData()];
    this.createdDate = '';
    this.createdBy = '';
    this.modifiedDate = '';
    this.modifiedBy = '';
  }

  setData(patient) {
    this.firstName = patient.firstName;
    this.lastName = patient.lastName;
    this.mrn = patient.mrn;
    this.birthDate = patient.birthDate;
    this.nasm = patient.nasm;
    this.phone = patient.phone;
    this.email = patient.email;
    this.address = patient.address;
    this.city = patient.city;
    this.postalCode = patient.postalCode;
    this.countryId = patient.country != null ? patient.country.id : null;
    this.gender = patient.gender != null ? patient.gender.id : null;
    this.language = patient.language != null ? patient.language.id : null;
    this.patientType = patient.patientType != null ? patient.patientType.id : null;
    this.provinceId = patient.province != null ? patient.province.id : null;
    this.active = patient.active;
    this.organizationId = patient.organization != null ? patient.organization.id : null;
    this.facilityId = patient.facility != null ? patient.facility.id : null;
    this.roombedId = patient.roombed != null ? patient.roombed.id : null;
    this.startDate = patient.startDate;
    this.endDate = patient.endDate;
    this.createdDate = patient.created_at;
    this.baselines = patient.baselines ? patient.baselines : new BaselinesFormData();
    this.healthInfo = patient.healthInfo ? patient.healthInfo : new HealthInfoFormData();
    this.caregiverInfo = patient.caregiverInfo.length > 0 ? patient.caregiverInfo : [new CaregiverFormData()];
    this.createdBy = patient.created_by != null ? patient.created_by.username : null;
    this.modifiedDate = patient.updated_at;
    this.modifiedBy = patient.updated_by != null ? patient.updated_by.username : null;
  }
}

export { PatientFormData };
