export default {
  en: {
    chatListTab: 'Chat List',
    notificationListTab: 'Notifications',
    newChat: 'New Chat',
    activeChats: 'Active Chats',
    archivedChats: 'Archived Chats',
    noChatHistory: 'No conversation history to display',
    noPreviewAvailable: 'No preview available',
    chat: 'Chat',
    patientVideoCall: 'Video Call',
    joinedTheCall: '{name} joined the call',
    leftTheCall: '{name} left the call',
    endChat: 'End Conversation',
    confirmEndChat: 'If you end the conversation, it will be in read-only mode and no new messages can be added.',
    lastReadMessage: 'Last Read Messages',
    typeMessage: 'Type a message',
    today: 'Today',
    multipleHours: '"multiples hours"',
    takenAt: 'taken at',
    caregiver: 'Caregiver',
    caregivers: 'Caregivers',
    incontinenceValues: 'Incontinence Values',
    trainingNotCompleted: 'Training not completed',
    trainingCompletedAt: 'Training completed at',

    'patientVideoCall.camera': 'Camera',
    'patientVideoCall.cameraOn': 'Camera\n(on)',
    'patientVideoCall.microphone': 'Microphone',
    'patientVideoCall.microphoneOn': 'Microphone\n(on)',
    'patientVideoCall.RotateCamera': 'Rotate\ncamera',
    'patientVideoCall.endCall': 'End\ncall',
    'patientVideoCall.userDisconnected': 'User disconnected...',
    'patientVideoCall.waitingForParticipant': 'You are currently the only one on the call',
    'patientVideoCall.connectionMessage': 'An error occurred while connecting the call.',
    'patientVideoCall.disconnectedMessage': 'An error has occurred and you have been disconnected from the call.',
    'patientVideoCall.videoError': 'An error occurred while activating the camera',
    'patientVideoCall.microphoneError': 'An error occurred while activating the microphone',

    'patientDetails.fromGustav': 'The patient information comes from the Gustav software',
    'patientDetails.openInGustav': 'View in Gustav',
    'patientDetails.patientSummary': 'Care Summary',

    'patientChartUnit.heartRate': 'bpm',
    'patientChartUnit.bloodPressure': 'mmHg',
    'patientChartUnit.saturation': '%',
    'patientChartUnit.respiratoryRate': 'Breathing cycles',
    'patientChartUnit.respiratoryRateLegendary': 'Breathing cycle',
    'patientChartUnit.bodyTemperature': '°C',
    'patientChartUnit.triggeredAlert': 'Triggered Alerts',
    'patientChartUnit.biggerThanTriggeredAlert': "'Bigger than' triggered alerts",
    'patientChartUnit.lesserThanTriggeredAlert': "'Lesser than' triggered alerts",
    'patientChartUnit.thresholdValue': 'Alert threshold:',
    'patientChartUnit.threshold.SAPR': 'systolic',
    'patientChartUnit.threshold.DAPR': 'diastolic',
    'patientChartUnit.threshold.BeatsPerMin': 'heart rate',
    'patientChartUnit.threshold.SAT': 'saturation',
    'patientChartUnit.threshold.Breathing': 'breathing',
    'patientChartUnit.threshold.BodyTemperature': 'body temperature',
    'patientChartUnit.threshold.CapillaryGlycemia': 'glycemia',
    'patientChartUnit.threshold.Weight': 'weight',
    'patientChartUnit.threshold.ECG': 'ECG',

    'patientChartUnit.BloodPressure': 'Blood Pressure',
    'patientChartUnit.Systolic': 'Systolic blood pressure',
    'patientChartUnit.Diastolic': 'Diastolic blood pressure',
    'patientChartUnit.CapillaryGlycemia': 'Capillary glycemia',
    'patientChartUnit.Weight': 'Weight',
    'patientChartUnit.Saturation': 'Saturation',
    'patientChartUnit.BodyTemperature': 'Body temperature',
    'patientChartUnit.Pulse': 'Heart rate',
    'patientChartUnit.RespiratoryRate': 'Respiratory rate',
    'patientChartUnit.ECG': 'ECG',

    'chartLegend.Systolic': 'Systolic pressure',
    'chartLegend.Diastolic': 'Diastolic pressure',
    'chartLegend.CapillaryGlycemia': 'Capillary blood glucose',
    'chartLegend.Weight': 'Weight',
    'chartLegend.Saturation': 'Saturation',
    'chartLegend.BodyTemperature': 'Body temperature',
    'chartLegend.Pulse': 'Heart rate',
    'chartLegend.RespiratoryRate': 'Respiratory rate',
    'chartLegend.ECG': 'ECG',

    'chartThreshold.convertedFrom': 'converted from',
    'chartThreshold.convertedTo': 'to',

    CapillaryGlycemiaValue: 'Capillary glycemia value',
    WeightValue: 'Weight value',
    DiastolicValue: 'Diastolic value',
    SystolicValue: 'Systolic value',
    PulseValue: 'Heart rate value',
    RespiratoryRateValue: 'Respiratory rate value',
    SaturationValue: 'Saturation value',
    BodyTemperatureValue: 'Body temperature value',

    oneOfPatientObservationAlreadyExists: 'One of the observations already exists for the patient at the selected time',
    patientWeighingAlreadyExists: 'There is already a weight measurement for the patient at the selected time',

    ConversationCompletedException: 'This conversation is archived',

    question: 'Question',
    response: 'Answer',
    answeredAt: 'answered at',
    notAnswered: 'Anamnesis not answered',
    showAnswers: 'Show all answers',
    hideAnswers: 'Hide all answers',
    expressAnswer: 'The patient used the choice "my situation has not changed"',

    PRN: 'PRN',

    patientNotFound: 'Patient not found',

    manualEntry: 'Manual Entry',
    addManualEntryData: 'Add data',
    entryData: 'Enter Data',
    SystolicSuffix: 'mmHg',
    DiastolicSuffix: 'mmHg',
    PulseSuffix: 'bpm',
    SaturationSuffix: '%',
    BodyTemperatureSuffix: '°C',
    RespiratoryRateSuffix: 'rpm',
    CapillaryGlycemiaSuffix: 'mmol/L',
    dataDateTime: 'Data Time',
    valueManuallyEnteredByPatient: 'Value manually entered by the patient',
    valueManuallyEnteredByUser: 'Value manually entered by {{userName}}',

    'patientPicture.notTaken': 'Picture not taken',
    'patientPicture.takenAt': 'Picture taken at',

    'patientNote.notes': 'Notes',
    'patientNote.note': 'Note',
    'patientNote.noteType': 'Note type',
    'patientNote.consult': 'Consult',
    'patientNote.createNote': 'Add a note',
    'patientNote.createdAt': 'Created At',
    'patientNote.createdBy': 'Created By',
    'patientNote.consultNote': 'Consult note',
    'patientNote.proNote': 'Professional note',
    'patientNote.intervention': 'Intervention',
    'patientNote.startTime': 'Start time',
    'patientNote.endTime': 'End time',
    'patientNote.time': 'Intervention time',
    'patientNote.timeHint': 'In minutes',
    'patientNote.interventionDate': 'Intervention date',
    'patientNote.noteDate': 'Date of note',
    'patientNote.noteOrInterventionDate': 'Date of note/intervention',

    CFRDetails: 'Heart Rate Details',
    BDTChartTitle: 'Body Temperature Details',
    saturation: 'Saturation',
    SATChartTitle: 'Saturation Details',
    RESChartTitle: 'Respiratory Rate Details',
    WEIChartTitle: 'Weight Details',
    capillaryGlycemia: 'Capillary Glycemia',
    GLYChartTitle: 'Capillary Glycemia Details',
    BloodPressure: 'Blood Pressure',
    APRChartTitle: 'Blood Pressure Details',
    Systolic: 'Systolic Blood Pressure',
    Diastolic: 'Diastolic Blood Pressure',
    dateAndTime: 'Date and Time',
    spaceColon: '{label}: {value}',

    singularBeatsPerMinThreshold: 'Heart Rate Threshold',
    singularBodyTemperatureThreshold: 'Body Temperature Threshold',
    singularBreathingThreshold: 'Respiratory Rate Threshold',
    singularWeightThreshold: 'Weight Threshold',
    singularCapillaryGlycemiaThreshold: 'Capillary Glycemia Threshold',
    singularSATThreshold: 'Saturation Threshold',
    singularSAPRThreshold: 'Systolic Threshold',
    singularDAPRThreshold: 'Diastolic Threshold',
    singularWEIThreshold: 'Weight Threshold',
    fahrenheit: '°F',

    pluralBeatsPerMinThreshold: 'Heart Rate Thresholds',
    pluralBodyTemperatureThreshold: 'Body Temperature Thresholds',
    pluralBreathingThreshold: 'Respiratory Rate Thresholds',
    pluralWeightThreshold: 'Weight Threshold',
    pluralCapillaryGlycemiaThreshold: 'Capillary Glycemia Thresholds',
    pluralSATThreshold: 'Saturation Thresholds',
    pluralSAPRThreshold: 'Systolic Thresholds',
    pluralDAPRThreshold: 'Diastolic Thresholds',
    pluralWEIThreshold: 'Weight Threshold',
  },
  fr: {
    chatListTab: 'Conversations',
    notificationListTab: 'Notifications',
    newChat: 'Nouvelle conversation',
    activeChats: 'Conversations actives',
    archivedChats: 'Conversations terminées',
    noChatHistory: 'Aucun historique de conversation à afficher',
    noPreviewAvailable: 'Aucun aperçu disponible',
    chat: 'Clavardage',
    patientVideoCall: 'Appel vidéo',
    joinedTheCall: "{name} a joint l'appel",
    leftTheCall: "{name} a quitté l'appel",
    endChat: 'Terminer la conversation',
    confirmEndChat:
      'Si vous terminez la conversation, celle-ci sera en mode lecture et aucun nouveau message ne pourra être ajoutée.\n\nSouhaitez-vous vraiment mettre fin à la conversation ?',
    lastReadMessage: 'Dernière lecture',
    typeMessage: 'Saisissez votre réponse',
    today: "Aujourd'hui",
    multipleHours: '"heures multiples"',
    takenAt: 'pris à',
    caregiver: 'Proche aidant',
    caregivers: 'Proches aidants',
    incontinenceValues: 'Incontinence',
    trainingNotCompleted: 'Formation non-complétée',
    trainingCompletedAt: 'Formation complétée à',

    'patientVideoCall.camera': 'Caméra',
    'patientVideoCall.cameraOn': 'Caméra\n(activée)',
    'patientVideoCall.microphone': 'Microphone',
    'patientVideoCall.microphoneOn': 'Microphone\n(activé)',
    'patientVideoCall.RotateCamera': 'Rotation\ncaméra',
    'patientVideoCall.endCall': "Terminer\nl'appel",
    'patientVideoCall.userDisconnected': 'Utilisateur déconnecté...',
    'patientVideoCall.waitingForParticipant': "Vous êtes présentement seul sur l'appel",
    'patientVideoCall.connectionMessage': "Une erreur est survenue lors de la connexion à l'appel.",
    'patientVideoCall.disconnectedMessage': "Une erreur est survenue et vous avez été déconnecté de l'appel.",
    'patientVideoCall.videoError': "Une erreur est survenue lors de l'activation de la caméra",
    'patientVideoCall.microphoneError': "Une erreur est survenue lors de l'activation du microphone",

    'patientDetails.fromGustav': 'Les informations du patient proviennent du logiciel Gustav',
    'patientDetails.openInGustav': 'Consulter dans Gustav',
    'patientDetails.patientSummary': 'Sommaire des soins',

    'patientChartUnit.heartRate': 'bpm',
    'patientChartUnit.bloodPressure': 'mmHg',
    'patientChartUnit.saturation': '%',
    'patientChartUnit.respiratoryRate': 'Cycles de respiration',
    'patientChartUnit.respiratoryRateLegendary': 'Cycle de respiration',
    'patientChartUnit.bodyTemperature': '°C',
    'patientChartUnit.triggeredAlert': 'Alertes déclenchées',
    'patientChartUnit.biggerThanTriggeredAlert': "Alertes 'plus grand que' déclenchées",
    'patientChartUnit.lesserThanTriggeredAlert': "Alertes 'plus petit que' déclenchées",
    'patientChartUnit.thresholdValue': 'Seuil alerte :',
    'patientChartUnit.threshold.SAPR': 'systolique',
    'patientChartUnit.threshold.DAPR': 'diastolique',
    'patientChartUnit.threshold.BeatsPerMin': 'fréquence cardiaque',
    'patientChartUnit.threshold.SAT': 'saturation',
    'patientChartUnit.threshold.Breathing': 'respiration',
    'patientChartUnit.threshold.BodyTemperature': 'température corporelle',
    'patientChartUnit.threshold.CapillaryGlycemia': 'glycémie',
    'patientChartUnit.threshold.Weight': 'poids',
    'patientChartUnit.threshold.ECG': 'ECG',

    'patientChartUnit.BloodPressure': 'Pression Artérielle',
    'patientChartUnit.Systolic': 'Pression artérielle systolique',
    'patientChartUnit.Diastolic': 'Pression artérielle diastolique',
    'patientChartUnit.CapillaryGlycemia': 'Glycémie capillaire',
    'patientChartUnit.Weight': 'Poids',
    'patientChartUnit.Saturation': 'Saturation',
    'patientChartUnit.BodyTemperature': 'Température corporelle',
    'patientChartUnit.Pulse': 'Fréquence cardiaque',
    'patientChartUnit.RespiratoryRate': 'Fréquence respiratoire',
    'patientChartUnit.ECG': 'ECG',

    'chartLegend.Systolic': 'Pression systolique',
    'chartLegend.Diastolic': 'Pression diastolique',
    'chartLegend.CapillaryGlycemia': 'Glycémie capillaire',
    'chartLegend.Weight': 'Poids',
    'chartLegend.Saturation': 'Saturation',
    'chartLegend.BodyTemperature': 'Température corporelle',
    'chartLegend.Pulse': 'Fréquence cardiaque',
    'chartLegend.RespiratoryRate': 'Fréquence respiratoire',
    'chartLegend.ECG': 'ECG',

    'chartThreshold.convertedFrom': 'converti de',
    'chartThreshold.convertedTo': 'en',

    CapillaryGlycemiaValue: 'Valeur glycémie capillaire',
    WeightValue: 'Valeur poids',
    DiastolicValue: 'Valeur diastolique',
    SystolicValue: 'Valeur systolique',
    PulseValue: 'Valeur fréquence cardiaque',
    RespiratoryRateValue: 'Valeur fréquence respiratoire',
    SaturationValue: 'Valeur saturation',
    BodyTemperatureValue: 'Valeur température corporelle',

    oneOfPatientObservationAlreadyExists: "Une des observations existe déjà pour le patient à l'heure choisie",
    patientWeighingAlreadyExists: "Une pesée est déjà enregistrée pour le patient à l'heure choisie",

    ConversationCompletedException: 'Cette conversation est terminée',

    question: 'Question',
    response: 'Réponse',
    answeredAt: 'répondu à',
    notAnswered: 'Anamnèse non-répondue',
    showAnswers: 'Afficher toutes les réponses',
    hideAnswers: 'Masquer toutes les réponses',
    expressAnswer: `Le patient a utilisé le choix "ma situation n'a pas changé"`,

    PRN: 'PRN',

    patientNotFound: 'Patient non-trouvé',

    manualEntry: 'Entrée manuelle',
    addManualEntryData: 'Ajouter une donnée',
    entryData: 'Entrer une donnée',
    SystolicSuffix: 'mmHg',
    DiastolicSuffix: 'mmHg',
    PulseSuffix: 'bpm',
    SaturationSuffix: '%',
    BodyTemperatureSuffix: '°C',
    RespiratoryRateSuffix: 'rpm',
    CapillaryGlycemiaSuffix: 'mmol/L',
    dataDateTime: 'Heure de la donnée',
    valueManuallyEnteredByPatient: 'Valeur entrée manuellement par le patient',
    valueManuallyEnteredByUser: 'Valeur entrée manuellement par {{userName}}',

    'patientPicture.notTaken': 'Photo non-prise',
    'patientPicture.takenAt': 'Photo prise à',

    'patientNote.notes': 'Notes',
    'patientNote.note': 'Note',
    'patientNote.consult': 'Consulter',
    'patientNote.noteType': 'Type de note',
    'patientNote.createNote': 'Ajouter une note',
    'patientNote.createdAt': 'Créée le',
    'patientNote.createdBy': 'Créée par',
    'patientNote.consultNote': 'Consulter la note',
    'patientNote.proNote': 'Note professionnelle',
    'patientNote.intervention': 'Intervention',
    'patientNote.startTime': 'Heure de début',
    'patientNote.endTime': 'Heure de fin',
    'patientNote.time': "Durée d'intervention",
    'patientNote.timeHint': 'En minutes',
    'patientNote.interventionDate': "Date de l'intervention",
    'patientNote.noteDate': 'Date de la note',
    'patientNote.noteOrInterventionDate': 'Date de la note/intervention',

    dateAndTime: 'Date et heure',
    spaceColon: '{label} : {value}',

    singularBeatsPerMinThreshold: 'Seuil fréquence cardiaque',
    singularBodyTemperatureThreshold: 'Seuil température corpo.',
    singularBreathingThreshold: 'Seuil fréquence respiratoire',
    singularWeightThreshold: 'Seuil poids',
    singularCapillaryGlycemiaThreshold: 'Seuil glycémie capillaire',
    singularSATThreshold: 'Seuil saturation',
    singularSAPRThreshold: 'Seuil systolique',
    singularDAPRThreshold: 'Seuil diastolique',
    singularWEIThreshold: 'Seuil poids',
    fahrenheit: '°F',

    pluralBeatsPerMinThreshold: 'Seuils fréquence cardiaque',
    pluralBodyTemperatureThreshold: 'Seuils température corporelle',
    pluralBreathingThreshold: 'Seuils fréquence respiratoire',
    pluralWeightThreshold: 'Seuils poids',
    pluralCapillaryGlycemiaThreshold: 'Seuils glycémie capillaire',
    pluralSATThreshold: 'Seuils saturation',
    pluralSAPRThreshold: 'Seuils systolique',
    pluralDAPRThreshold: 'Seuils diastolique',
    pluralWEIThreshold: 'Seuils poids',
  },
};
