<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="'1800px'">
      <v-card>
        <ModalTitle :title="$t('patientActivitiesProtocols')" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <PatientAssignDetails ref="patientDetails" class="px-6 mb-4" :patient-info="patientInfo" />

        <v-tabs v-model="selectedActivityTab" class="px-6" background-color="transparent">
          <v-tab> {{ $t('activity') }} </v-tab>
          <v-tab> {{ $t('alertCustomization') }} </v-tab>
          <v-tab> {{ $t('protocolsHistory') }} </v-tab>
        </v-tabs>

        <v-card-text>
          <v-tabs-items v-model="selectedActivityTab">
            <v-form ref="patientAssignForm">
              <v-tab-item :value="0" class="pt-0">
                <div class="">
                  <v-radio-group ref="allRadioButtons" v-model="selectedCategory" row>
                    <v-radio ref="allActAndProtocols" :label="$t('allActAndProtocols')" value="all"></v-radio>
                    <v-radio ref="activitiesButton" :label="$t('activities')" value="activities"></v-radio>
                    <v-radio ref="protocolsButton" :label="$t('protocols')" value="protocols"></v-radio>
                  </v-radio-group>
                </div>

                <PatientActivityAssign
                  :patient-id="patientId"
                  :selected-category="selectedCategory"
                  :all-activities="allActivities"
                  :all-protocols="allProtocols"
                  :load-protocol-activities="loadProtocolActivities"
                  :assigned-activities="formData.assignedActivities"
                  :assigned-protocols="formData.assignedProtocols"
                  :assigned-items-change="assignedItemsChange"
                  :show="showWaitModal"
                  :data-loading="showWaitModal"
                ></PatientActivityAssign>
              </v-tab-item>

              <v-tab-item :value="1" eager class="pt-4">
                <PatientActivityCustomize
                  :customized-activities="formData.customizedActivities"
                  :is-activity-dialogue="isActivityDialogue"
                  :is-activity-appointment="isActivityAppointment"
                  :is-activity-picture="isActivityPicture"
                ></PatientActivityCustomize>
              </v-tab-item>

              <v-tab-item :value="2" class="pt-0">
                <PatientProtocolsHistory :patient-id="patientId"></PatientProtocolsHistory>
              </v-tab-item>
            </v-form>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="savePatientActivitiesAndProtocols"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import PatientAssignDetails from '@/components/Patient/PatientAssignDetails';
import SaveButton from '@/components/SaveButton.vue';
import PatientActivityAssign from './PatientActivityAssign';
import PatientActivityCustomize from './PatientActivityCustomize';
import PatientProtocolsHistory from './PatientProtocolsHistory';
import activityService from '@/services/activityService';
import protocolService from '@/services/protocolService';
import { ActivityTypes } from '@/components/PatientMonitoring/constants';

export default {
  name: 'PatientActivityModal',

  components: {
    PatientAssignDetails,
    PatientActivityAssign,
    PatientActivityCustomize,
    PatientProtocolsHistory,
    SaveButton,
  },
  mixins: [translation, accessibility, virtuoseMixin],

  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },

    patientInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      defaultPatientId: null,
      loadedPatientId: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,

      selectedActivityTab: 0,

      error: null,

      formData: {
        assignedActivities: [],
        assignedProtocols: [],
        customizedActivities: [],
      },
      originalFormData: {},
      tempActivities: [],
      allActivities: [],
      selectedActivity: null,

      allProtocols: [],
      selectedProtocol: null,

      selectedCategory: 'all',
    };
  },
  computed: {
    computedPatientId: function () {
      return this.patientId || this.defaultPatientId;
    },
  },

  watch: {
    patientId: function () {
      this.init();
    },
    show() {
      if (this.show) {
        this.init();
      } else {
        this.loadedPatientId = null;
      }
    },
  },
  methods: {
    async init() {
      if (!this.patientId || !this.show || this.loadedPatientId === this.patientId) {
        return;
      }
      this.selectedActivityTab = 0;

      this.loadedPatientId = this.patientId;
      this.showWaitModal = true;
      this.selectedCategory = 'all';
      this.formData.assignedActivities = [];
      this.formData.assignedProtocols = [];
      this.active = [];
      this.formData.customizedActivities = [];
      try {
        await Promise.all([this.loadAvailableActivities(), this.loadAvailableProtocols()]);
        await Promise.all([this.loadPatientActivities(), this.loadPatientProtocols()]);
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
      this.showWaitModal = false;
    },

    async loadAvailableActivities() {
      try {
        const allActivitiesResponse = await activityService.getActivities();
        this.allActivities = allActivitiesResponse.map((item) => {
          return {
            ...item,
            uniqueId: 'activity' + item.id,
            type: 'activity',
          };
        });
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
    },

    async loadAvailableProtocols() {
      try {
        const allProtocolsResponse = await protocolService.getProtocols();
        this.allProtocols = allProtocolsResponse.map((item) => {
          return {
            ...item,
            uniqueId: 'protocol' + item.id,
            type: 'protocol',
            assignedProtocolActivities: [],
          };
        });
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
    },

    isActivityDialogue(activityType) {
      return activityType.code === ActivityTypes.DLG;
    },

    isActivityAppointment(activityType) {
      return activityType.code === ActivityTypes.APPT;
    },

    isActivityPicture(activityType) {
      return activityType.code === ActivityTypes.PHT;
    },

    async isThereActivity(activity) {
      const foundActivity = this.allActivities.find((x) => x.id === activity.activityId);

      if (!foundActivity || this.formData.customizedActivities.some((x) => x.activityId === activity.activityId)) {
        return;
      }

      const newActivity = this.createActivityFromFoundActivity(foundActivity, activity);

      if (newActivity.activityType.code === ActivityTypes.VID) {
        newActivity.overriddenParameters = {};
      } else {
        this.addMissingAlerts(newActivity);
      }
      this.addMissingDialogues(newActivity);
      this.addMissingTriggers(newActivity);

      if (this.isActivityDialogue(newActivity.activityType)) {
        if (!newActivity.overriddenParameters) {
          newActivity.overriddenParameters = JSON.parse(JSON.stringify(newActivity.defaultParameters));
        }
        if (!newActivity.overriddenDialogues) {
          newActivity.overriddenDialogues = JSON.parse(JSON.stringify(newActivity.defaultDialogues));
        }
        if (!newActivity.overriddenTriggers) {
          newActivity.overriddenTriggers = JSON.parse(JSON.stringify(newActivity.defaultTriggers));
        }
      }

      const existingActivityIndex = this.formData.customizedActivities.findIndex(
        (x) => x.activityId === newActivity.activityId
      );

      if (existingActivityIndex === -1) {
        this.formData.customizedActivities.push(newActivity);
      }
    },

    createActivityFromFoundActivity(foundActivity, activity) {
      const dialogues = foundActivity?.dialogues || null;
      const triggers = foundActivity?.triggers || null;
      const vocalMessages = foundActivity?.vocalMessages;

      const mappedDialogues = dialogues
        ? dialogues.map((dialogue) => ({
            ...dialogue,
            enabled: true,
          }))
        : null;

      const defaultParameters = JSON.parse(JSON.stringify(foundActivity.parameters));
      const defaultDialogues = JSON.parse(JSON.stringify(mappedDialogues));
      const defaultTriggers = JSON.parse(JSON.stringify(triggers));

      const customizable = activity.parameters || activity.dialogues || activity.triggers;
      const overridden = customizable ? true : false;

      const overriddenParameters = overridden ? activity.parameters : foundActivity.parameters;
      const overriddenDialogues = overridden ? activity.dialogues : mappedDialogues;
      const overriddenTriggers = overridden ? activity.triggers : triggers;

      return {
        id: activity.activityId,
        activityId: activity.activityId,
        name: foundActivity.name,
        activityType: foundActivity.activityType,
        overridden,
        defaultParameters,
        overriddenParameters,
        defaultDialogues,
        overriddenDialogues,
        defaultTriggers,
        overriddenTriggers,
        vocalMessages,
      };
    },

    addMissingAlerts(newActivity) {
      if (newActivity.activityType.code === ActivityTypes.APPT) {
        newActivity.overriddenParameters = null;
        return;
      }

      const overriddenAlerts = newActivity.overriddenParameters?.alerts;
      const defaultAlerts = newActivity.defaultParameters?.alerts;

      if (!overriddenAlerts) {
        newActivity.overriddenParameters = {
          alerts: [...defaultAlerts],
        };
        return;
      }
      // Ajouter les alertes manquantes
      defaultAlerts?.forEach((defaultParam) => {
        const matchingAlert = overriddenAlerts.find(
          (overriddenParam) => overriddenParam.alertId === defaultParam.alertId
        );

        if (!matchingAlert) {
          overriddenAlerts?.push({ ...defaultParam });
        } else if (!this.areObjectsEqual(defaultParam, matchingAlert)) {
          matchingAlert.overriddenAlert = true;
        }
      });

      // Trier les alertes en se basant sur l'ordre de defaultParameters
      overriddenAlerts.sort((a, b) => {
        const idxA = defaultAlerts?.findIndex((x) => x.alertId === a.alertId);
        const idxB = defaultAlerts?.findIndex((x) => x.alertId === b.alertId);

        if (idxA < idxB) return -1;
        if (idxA > idxB) return 1;
        return 0;
      });
    },

    addMissingDialogues(newActivity) {
      const overriddenDials = newActivity?.overriddenDialogues;
      const defaultDials = newActivity?.defaultDialogues;

      // Ajouter les dialogues manquants
      defaultDials?.forEach((defaultDial) => {
        const matchingDial = overriddenDials?.find((overriddenDial) => overriddenDial.id === defaultDial.id);

        if (!matchingDial) {
          overriddenDials?.push({ ...defaultDial });
        } else if (!this.areObjectsEqual(defaultDial, matchingDial)) {
          matchingDial.enabled = false;
        }
      });

      // Trier les dialogues en se basant sur l'ordre de default
      overriddenDials?.sort((a, b) => {
        const idxA = defaultDials.findIndex((x) => x.id === a.id);
        const idxB = defaultDials.findIndex((x) => x.id === b.id);

        if (idxA < idxB) return -1;
        if (idxA > idxB) return 1;
        return 0;
      });
    },

    addMissingTriggers(newActivity) {
      const overriddenTriggers = newActivity?.overriddenTriggers;
      const defaultTriggers = newActivity?.defaultTriggers;

      // Ajouter les triggers manquantes
      defaultTriggers?.forEach((defaultTrigger) => {
        const matchingTrigger = overriddenTriggers?.find(
          (overriddenTrigger) => overriddenTrigger.id === defaultTrigger.id
        );

        if (!matchingTrigger) {
          overriddenTriggers?.push({ ...defaultTrigger });
        } else if (!this.areObjectsEqual(defaultTrigger, matchingTrigger)) {
          matchingTrigger.overriddenTrigger = true;
        }
      });

      // Trier les triggers en se basant sur l'ordre de default
      overriddenTriggers?.sort((a, b) => {
        const idxA = defaultTriggers.findIndex((x) => x.id === a.id);
        const idxB = defaultTriggers.findIndex((x) => x.id === b.id);

        if (idxA < idxB) return -1;
        if (idxA > idxB) return 1;
        return 0;
      });
    },

    handleActivity: function (activity) {
      this.isThereActivity({
        id: activity.id,
        activityId: activity.id,
        name: activity.name,
        activityType: activity.activityType,
      });
    },

    async loadPatientActivities() {
      try {
        const activitiesResponse = await activityService.getPatientActivities(this.computedPatientId);
        this.formData.assignedActivities = this.allActivities.filter((availableItem) =>
          activitiesResponse.some(
            (assignedItem) => assignedItem.activityId === availableItem.id && !assignedItem.protocolActivityId
          )
        );
        activitiesResponse.forEach((assignedActivity) => {
          this.isThereActivity(assignedActivity);
        });
        this.formData.assignedActivities.forEach((activity) => {
          const patientActivity = activitiesResponse.find(
            (x) => x.activityId === activity.id && x.protocolActivityId === null
          );
          activity.startDate = patientActivity.startDate;
          activity.endDate = patientActivity.endDate;
          activity.patientId = patientActivity.patientId;
        });
        this.selectedActivity = null;
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
    },

    async loadProtocolActivities(item) {
      try {
        item.assignedProtocolActivities = await protocolService.getProtocolActivities(item.id);
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
    },

    async loadPatientProtocols() {
      try {
        const protocolsResponse = await protocolService.getPatientProtocols(this.computedPatientId);

        this.formData.assignedProtocols = this.allProtocols.filter((availableItem) =>
          protocolsResponse.some((assignedItem) => assignedItem.protocolId === availableItem.id)
        );

        this.formData.assignedProtocols.forEach((element) => {
          let patientProtocol = protocolsResponse.find((x) => x.protocolId === element.id);

          element.startDate = patientProtocol.startDate;
          element.endDate = patientProtocol.endDate;
          element.patientId = patientProtocol.patientId;
        });

        this.selectedProtocol = null;
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    closeDialog: function (refresh) {
      this.$emit('update:show', false);
      if (refresh) {
        this.$emit('saved');
      }
    },

    areObjectsEqual(obj1, obj2) {
      if (obj1 === obj2) {
        return true;
      }

      if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
          return false;
        }

        if (!this.areObjectsEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    },

    filterModifiedObjects(overriddenObjects, defaultObjects) {
      if (!overriddenObjects) {
        return [];
      }

      return overriddenObjects.filter((overriddenObj) => {
        let matchingDefaultObj = defaultObjects.find((defaultObj) => {
          return this.areObjectsEqual(defaultObj, overriddenObj);
        });
        return !matchingDefaultObj;
      });
    },

    async savePatientActivitiesAndProtocols() {
      let isValid = this.$refs.patientAssignForm.validate();

      if (!isValid || this.isProcessing) return;
      if (
        this.formData.assignedActivities.some((activity) => !activity.startDate) ||
        this.formData.assignedProtocols.some((protocol) => !protocol.startDate)
      ) {
        this.selectedCategory = 'all';
        return;
      }
      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        const dataActivities = {
          patientActivities: this.formData.assignedActivities.map((activity) => {
            return {
              activity_id: activity.id,
              startDate: activity.startDate,
              endDate: activity.endDate,
              patientId: activity.patientId,
            };
          }),
        };

        const dataCustomized = {
          customizedActivities: this.formData.customizedActivities
            .filter((x) => x.overridden)
            .map((x) => {
              let modifiedParameters = this.filterModifiedObjects(
                x.overriddenParameters?.alerts,
                x.defaultParameters?.alerts
              ).map((param) => {
                if (param.type === 'biggerThan' || param.type === 'lesserThan') {
                  if (param.lengthType) delete param.lengthType;
                  if (param.length) delete param.length;
                }

                return param;
              });
              let modifiedDialogues = this.filterModifiedObjects(x?.overriddenDialogues, x?.defaultDialogues);
              let modifiedTriggers = this.filterModifiedObjects(x?.overriddenTriggers, x?.defaultTriggers);

              /* eslint-disable */
              let filteredParameters = modifiedParameters.map(({ overriddenAlert, ...rest }) => rest);
              let filteredTriggers = modifiedTriggers.map(({ overriddenTrigger, ...rest }) => rest);

              return {
                id: x.id,
                parameters: { alerts: filteredParameters },
                dialogues: modifiedDialogues,
                triggers: filteredTriggers,
              };
            }),
        };

        const dataProtocols = {
          patientProtocols: this.formData.assignedProtocols.map((protocol) => {
            return {
              protocolId: protocol.id,
              startDate: protocol.startDate,
              endDate: protocol.endDate,
              patientId: protocol.patientId,
            };
          }),
        };

        const allDataActivities = {
          patientActivities: [...dataActivities.patientActivities],
          customizedActivities: dataCustomized.customizedActivities,
          patientProtocols: dataProtocols.patientProtocols,
        };

        await activityService.updatePatientActivitiesAndProtocols(this.patientId, allDataActivities);

        this.closeDialog(true);
        this.$emit('refresh');
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
      this.showWaitModal = false;
      this.isProcessing = false;
    },

    async assignedItemsChange(items) {
      let protocols = items.filter((x) => x.type === 'protocol');
      let activitiesIds = new Set();

      for (let protocol of protocols) {
        if (protocol.assignedProtocolActivities.length === 0) {
          try {
            await this.loadProtocolActivities(protocol);
            for (let activity of protocol.assignedProtocolActivities) {
              this.handleActivity(activity);
              activitiesIds.add(activity.id);
            }
          } catch (error) {
            this.defaultErrorCallBack(error);
          }
        } else {
          protocol.assignedProtocolActivities.forEach((activity) => {
            this.handleActivity(activity);
            activitiesIds.add(activity.id);
          });
        }
      }

      let assignedActivities = items.filter((x) => x.type === 'activity');

      assignedActivities.forEach((activity) => {
        this.handleActivity(activity);
        activitiesIds.add(activity.id);
      });
      this.formData.customizedActivities = this.formData.customizedActivities.filter((x) =>
        activitiesIds.has(x.activityId)
      );

      if (this.selectedCategory !== 'protocols') {
        this.formData.assignedActivities = items.filter((x) => x.type === 'activity');
      }

      if (this.selectedCategory !== 'activities') {
        this.formData.assignedProtocols = items.filter((x) => x.type === 'protocol');
      }
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>
