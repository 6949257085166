import { render, staticRenderFns } from "./IotRealtimeDetails.vue?vue&type=template&id=5ff5c3bd&scoped=true&"
import script from "./IotRealtimeDetails.vue?vue&type=script&lang=js&"
export * from "./IotRealtimeDetails.vue?vue&type=script&lang=js&"
import style0 from "./IotRealtimeDetails.vue?vue&type=style&index=0&id=5ff5c3bd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff5c3bd",
  null
  
)

export default component.exports