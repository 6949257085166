import Vue from 'vue';

import { Chart as ChartJS, CategoryScale, Interaction, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import CrosshairPlugin from '@/components/PatientMonitoring/Chart/utils/chartjs-plugin-crosshair';

import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

import { getRelativePosition } from 'chart.js/helpers';

import translationMixin from '@/translationMixin';
import { VitalSignsActivityCodes } from '@/components/PatientMonitoring/constants.js';

import { chartColor } from './constants';

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, _, options) {
    const legendContainer = document.getElementById(options.containerID);

    if (!legendContainer) return;

    let ul = legendContainer.querySelector('ul');

    if (!ul) {
      ul = document.createElement('ul');
      legendContainer.appendChild(ul);
    }

    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    const items = chart.options.plugins.legend.labels.generateLabels(chart);
    const defaultOnClick = chart.options.plugins.legend.onClick;

    items
      .filter((item) => !!item.text)
      .forEach((item) => {
        const li = document.createElement('li');

        li.onclick = () => {
          defaultOnClick?.(item);
        };

        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;

        const dataset = chart.data.datasets[item.datasetIndex];

        if (dataset.borderDash?.length > 0) {
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderStyle = 'dashed';
          boxSpan.style.borderSpacing = '12px 5px';
        } else {
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderStyle = 'solid';
        }

        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
  },
};

ChartJS.register(
  annotationPlugin,
  CategoryScale,
  CrosshairPlugin,
  htmlLegendPlugin,
  Legend,
  LinearScale,
  TimeScale,
  Tooltip,
  zoomPlugin
);

const translationInstance = new Vue({
  mixins: [translationMixin],
});

const originalGenerateLabels = ChartJS.defaults.plugins.legend.labels.generateLabels;

ChartJS.defaults.plugins.legend.labels.generateLabels = function (chart) {
  const labels = originalGenerateLabels.call(this, chart);

  labels.forEach((label) => {
    label.text = translationInstance.$t(label.text);
  });

  return labels;
};

ChartJS.defaults.plugins.tooltip.external = function (context) {
  const chartUniqueKey = context.chart.options.chartUniqueKey;
  const tooltipEl = document.getElementById(`${chartUniqueKey}-chartjs-tooltip`);

  if (!context.tooltip.opacity || !tooltipEl) {
    if (tooltipEl?.style?.opacity) tooltipEl.style.opacity = 0;
    return;
  }

  tooltipEl.style.opacity = 0;
  tooltipEl.style.position = 'absolute';

  const title = context.tooltip.title || '';
  const footer = context.tooltip.footer || '';
  const hasAnyTriggeredAlertOnCrossHair = context.tooltip?.dataPoints?.some((point) => point?.raw?.hasTriggeredAnAlert);

  const tooltipBorderColor = hasAnyTriggeredAlertOnCrossHair
    ? chartColor.incorrectDataColor
    : context.tooltip.labelColors[0].backgroundColor;

  tooltipEl.style.border = '1px solid ' + tooltipBorderColor;

  tooltipEl.style.setProperty('--chart-tooltip-color', tooltipBorderColor);

  document.getElementById(`${chartUniqueKey}-tooltip-title`).innerHTML = title;
  document.getElementById(`${chartUniqueKey}-tooltip-label`).innerHTML = '';
  document.getElementById(`${chartUniqueKey}-tooltip-footer`).innerHTML = footer;

  const allLabels = context.tooltip?.body?.flatMap((x) => x.lines.flatMap((y) => y));
  const isStatChart = context.chart.config._config.type === 'bar';

  allLabels?.forEach((label, index) => {
    const colorBox = `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${
      context.tooltip?.dataPoints?.[index]?.raw?.hasTriggeredAnAlert
        ? chartColor.incorrectDataColor
        : context.tooltip.labelColors[index].backgroundColor
    }; margin-right: 5px; vertical-align: middle;"></span>`;

    if (typeof label === 'object' && isStatChart) {
      document.getElementById(`${chartUniqueKey}-tooltip-label`).innerHTML += `${colorBox}${translationInstance.$t(
        `patientChartUnit.${label.observationType}`
      )}<br/>`;

      document.getElementById(`${chartUniqueKey}-tooltip-label`).innerHTML += `
        <span style="margin-left: 16px;">${label.maximum}</span><br/>
        <span style="margin-left: 16px;">${label.minimum}</span><br/>
        <span style="margin-left: 16px;">${label.average}</span><br/>
        <span style="margin-left: 16px;">${label.count}</span><br/>`;
    } else {
      document.getElementById(`${chartUniqueKey}-tooltip-label`).style.textAlign = 'center';
      document.getElementById(`${chartUniqueKey}-tooltip-label`).innerHTML += `${colorBox}${label}<br/>`;
    }
  });

  const afterLabels = context.tooltip.body.flatMap((x) => x.after.flatMap((y) => y));
  afterLabels?.forEach((afterLabel) => {
    document.getElementById(
      `${chartUniqueKey}-tooltip-label`
    ).innerHTML += `<div style="text-align: center; padding-left: 15px; padding-top: 1px;">${afterLabel}</div>`;
  });

  requestAnimationFrame(() => {
    const tooltipWidth = tooltipEl.offsetWidth;
    const tooltipHeight = tooltipEl.offsetHeight;
    const chartWidth = context.chart.width;
    const chartHeight = context.chart.height;

    let tooltipX = context.tooltip.caretX + 10;
    let tooltipY = context.tooltip.caretY - tooltipHeight / 2;

    if (tooltipX + tooltipWidth > chartWidth) {
      tooltipX = context.tooltip.caretX - tooltipWidth - 10;
      tooltipEl.classList.add('tooltip-right');
      tooltipEl.classList.remove('tooltip-left');
    } else {
      tooltipEl.classList.add('tooltip-left');
      tooltipEl.classList.remove('tooltip-right');
    }

    if (tooltipY < 0) {
      tooltipY = 10;
    } else if (tooltipY + tooltipHeight > chartHeight) {
      tooltipY = chartHeight - tooltipHeight - 10;
    }

    tooltipEl.style.left = `${tooltipX}px`;
    tooltipEl.style.top = `${tooltipY}px`;
    tooltipEl.style.opacity = 1;
  });
};

Interaction.modes.customVerticalTooltipMode = function (chart, e, _, useFinalPosition) {
  const position = getRelativePosition(e, chart);
  const items = [];

  if (!VitalSignsActivityCodes.some((code) => chart.config._config.options.chartUniqueKey.includes(code))) {
    Interaction.evaluateInteractionItems(chart, 'x', position, (element, datasetIndex, index) => {
      if (element.inXRange(position.x, useFinalPosition) && element.inYRange(position.y, useFinalPosition)) {
        items.push({ element, datasetIndex, index });
        return items;
      }
    });
  }

  if (items.length === 0) {
    Interaction.evaluateInteractionItems(chart, 'x', position, (element, datasetIndex, index) => {
      if (
        element.inXRange(position.x, useFinalPosition) &&
        ((chart.config._config.type === 'bar' && chart.config._config?.options?.activityCode !== 'ECG') ||
          !items.some((x) => x.element?.raw?.observationType === element?.raw?.observationType))
      ) {
        items.push({ element, datasetIndex, index });
      }
    });
  }

  return items;
};

export default ChartJS;
