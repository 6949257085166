<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-dialog v-if="show" value="show" scrollable persistent max-width="550">
      <v-card>
        <ModalTitle :title="$t('changePassword')" />

        <WaitModal :show="showWaitModal" />

        <v-card-text>
          <v-form ref="changePasswordForm" lazy-validation @submit.prevent>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="formData.oldPassword"
                  autofocus
                  type="password"
                  autocomplete="password"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                  :label="$t('password.oldPassword')"
                ></v-text-field>
                <v-text-field
                  v-model="formData.newPassword"
                  autofocus
                  type="password"
                  autocomplete="new-password"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required, validatePasswordStructure]"
                  :label="$t('newPassword')"
                ></v-text-field>
                <v-text-field
                  v-model="formData.newPasswordConfirm"
                  filled
                  type="password"
                  autocomplete="new-password"
                  class="required-indicator"
                  :rules="[validationRules.required, validateNewPassword]"
                  :label="$t('newPasswordConfirm')"
                ></v-text-field>
                <div class="wrap-newline">{{ $t('passwordRequirements') }}</div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="changePassword"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import validationRulesMixin from '@/validationRulesMixin';
import auth, { AuthResult } from '@/auth/auth';
import SaveButton from '@/components/SaveButton.vue';
export default {
  name: 'UserChangePassword',
  components: { SaveButton },
  mixins: [translation, validationRulesMixin],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      error: null,
      formData: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    closeDialog: function () {
      this.$emit('update:show', false);
      this.$refs.changePasswordForm.reset();
    },

    async changePassword() {
      let isValid = this.$refs.changePasswordForm.validate();

      if (!isValid || this.isProcessing) return;
      this.isProcessing = true;

      let result = await auth.changePassword(this.formData.oldPassword, this.formData.newPassword);

      if (result === AuthResult.InvalidPasswordStructure) {
        this.error = this.$t('invalidCredentials');
      }

      this.isProcessing = false;

      if (result === AuthResult.Successs) {
        this.closeDialog();
      }
    },
    validatePasswordStructure(value) {
      const passwordExpression =
        /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
      return passwordExpression.test(value) || this.$t('invalidPasswordStructure');
    },
    validateNewPassword(value) {
      return this.formData.newPassword === value || this.$t('passwordMismatch');
    },
  },
};
</script>
