<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="isCallingPatient" />

    <img
      v-if="getStatusLevelIcon(getWorstStatusLevelCode(patientData.alertsByDatetime)) === changeEssity"
      class="mr-1 mt-3"
      size="16px"
      alt="change-incontinence-icon"
      :src="changeEssity"
    />

    <v-icon v-else small :color="getStatusLevelColor(getWorstStatusLevelCode(patientData.alertsByDatetime))"
      >{{ getStatusLevelIcon(getWorstStatusLevelCode(patientData.alertsByDatetime)) }}
    </v-icon>

    <span class="text-body-1 text--primary font-weight-bold">
      {{ patientData.patient.lastName }}, {{ patientData.patient.firstName }}
    </span>

    <span v-if="patientData.patient.roombed.room" class="text--secondary text-body-2 ml-2">
      {{ $t('roombed') + (getLanguage() === 'fr' ? ' :' : ':') }} {{ patientData.patient.roombed.room }} -
      {{ patientData.patient.roombed.bed }}
    </span>

    <span class="mr-4 text-right" :class="{ grow: $vuetify.breakpoint.mdAndDown }">
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            :disabled="isCallingPatient"
            @click="resolvePatientMonitoringAlert(patientData.patient.patientId, patientData.alertsByDatetime)"
          >
            <v-list-item-icon><v-icon>mdi-check</v-icon></v-list-item-icon>
            <v-list-item-title>{{
              $t(
                patientData.alertsByDatetime.length > 0
                  ? 'patientMonitoringAlert.resolveAll'
                  : 'patientMonitoringAlert.resolved'
              )
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isAnyPriorityAlert(patientData.alertsByDatetime)"
            :disabled="isCallingPatient || !patientData.patient.hasVirtualAssistant"
            @click="videoCallPatient(patientData.patient.patientId)"
          >
            <v-list-item-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
            <v-list-item-title>{{ $t('patientMonitoringAlert.contactPatient') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';
import monitoringAlertRealtimeMixin from '@/components/PatientMonitoringAlertRealtime/monitoringAlertRealtimeMixin';
import messageService from '@/services/messageService';
import videoCallService from '@/services/videoCallService';

export default {
  name: 'MonitoringAlertRealtimeContentHeader',
  mixins: [monitoringAlertRealtimeMixin, translationMixin],
  props: {
    patientData: {
      type: Object,
      required: true,
    },

    statusLevelsForActivityType: {
      // Props used in the monitoringAlertRealtimeMixin
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      isCallingPatient: false,
      changeEssity: '/static/incontinenceicon/change_essity.svg',
    };
  },

  methods: {
    resolvePatientMonitoringAlert: function (patientId, patientMonitoringAlerts) {
      const monitoringAlertsIds = patientMonitoringAlerts.flatMap((x) =>
        x.alerts.flatMap((y) => y.patientMonitoringAlertId)
      );

      this.$emit('resolveAlert', patientId, monitoringAlertsIds);
    },

    isAnyPriorityAlert: function (alertsByDatetime) {
      return alertsByDatetime.some((x) => x.alerts.some((y) => !!y?.alert?.isPriorityAlert));
    },

    videoCallPatient: async function (patientId) {
      this.isCallingPatient = true;

      const chatData = {
        subject: this.$t('patientMonitoringAlert.checkInCall') + ' - ' + this.$t(this.title).toLowerCase(),
        chatType: 'priorityAlert',
        patientId: patientId,
        participants: {
          includePatient: true,
        },
      };

      try {
        const chatId = await messageService.createConversation(chatData);

        const videoCallData = {
          patientId: patientId,
          conversationId: chatId,
        };

        const videoCall = await videoCallService.createVideoCall(videoCallData);

        this.$router.push({
          name: 'PatientVideoCall',
          params: { patientId: patientId, callId: videoCall.id, conversationId: chatId },
        });
      } catch (error) {
        this.error = error;
      }

      this.isCallingPatient = false;
    },
  },
};
</script>
