<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <UserMFAModal :show.sync="mfa.showModal" :is-mfa-active.sync="mfa.isActive" />
    <UserChangePasswordModal :show.sync="showChangePasswordModal" />
    <v-dialog v-if="show" value="show" scrollable persistent max-width="550">
      <v-card>
        <ModalTitle :title="$t('userPreferences')" />
        <v-card-text>
          <v-form ref="userPreferencesForm">
            <v-row>
              <v-col>
                <span>{{ $t('language') }}</span>

                <v-radio-group ref="preferencesRadioButtons" v-model="formData.userLanguage" row>
                  <v-radio ref="languageRadioButtonFr" label="Français" value="fr" color="primary"></v-radio>
                  <v-radio ref="languageRadioButtonEn" label="English" value="en" color="primary"></v-radio>
                </v-radio-group>

                <span>{{ $t('notifications') }}</span>

                <v-checkbox
                  ref="emailNotifications"
                  v-model="formData.emailNotifications"
                  hide-details
                  color="primary"
                  :label="$t('userPreferences.emailNotificationsQuestion')"
                />
                <v-checkbox
                  ref="SMSNotifications"
                  v-model="formData.SMSNotifications"
                  hide-details
                  color="primary"
                  :label="$t('userPreferences.SMSNotificationsQuestion')"
                  @change="checkSMSNotificationEligibility"
                />

                <v-col cols="auto">
                  <p v-if="showMessage" ref="noPhoneNumber" border="left" class="pl-5 text-caption">
                    {{ $t('userPreferences.SMSNotificationsQuestion.noPhoneNumber') }}
                  </p>
                </v-col>

                <div class="mt-6">
                  <span>{{ $t('mfaTitle') }}</span>
                  <v-chip v-if="!mfa.isLoading && mfa.isActive" small outlined class="ml-2" label color="green">
                    {{ $t('mfa.active') }}
                  </v-chip>
                  <v-chip v-else-if="!mfa.isLoading && !mfa.isActive" small outlined class="ml-2" label color="red">
                    {{ $t('mfa.inactive') }}
                  </v-chip>
                  <v-row class="mt-1">
                    <v-col cols="auto">
                      <v-btn
                        ref="activateMFA"
                        small
                        :disabled="mfa.isLoading || isProcessing"
                        :loading="mfa.isLoading"
                        :color="!mfa.isActive ? 'primary' : undefined"
                        @click="mfa.showModal = true"
                        >{{ mfa.isActive ? $t('mfa.deactivate') : $t('mfa.activate') }}</v-btn
                      >
                    </v-col>
                    <v-col class="pt-2"
                      ><span v-if="!mfa.isLoading" class="text-body-2">{{ $t('mfa.description') }} </span>
                    </v-col>
                  </v-row>
                </div>

                <div class="mt-12">
                  <v-btn small @click="showChangePasswordModal = true">{{ $t('changePassword') }}</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="cancel()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn ref="ok" text color="primary" :disabled="disableButton" @click="savePreferences()">{{
            $t('ok')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import userService from '@/services/userService';
import UserMFAModal from '@/components/User/UserMFAModal.vue';
import UserChangePasswordModal from '@/components/User/UserChangePasswordModal.vue';
import auth from '@/auth/auth';
export default {
  name: 'UserPreferences',
  components: { UserMFAModal, UserChangePasswordModal },
  mixins: [translation],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      availableLanguages: [],
      error: null,
      isProcessing: false,
      showMessage: false,
      mfa: {
        isLoading: true,
        isActive: false,
        showModal: false,
      },
      showChangePasswordModal: false,
    };
  },
  computed: {
    disableButton() {
      return this.mfa.isLoading || this.isProcessing || this.mfa.showModal;
    },
  },
  watch: {
    show: async function () {
      if (this.show) {
        await this.getUserPreferences();
        await this.loadIsMFAActive();
      }
    },
    'formData.hasPhoneNumber'() {
      this.checkSMSNotificationEligibility();
    },
    'formData.SMSNotifications'() {
      this.checkSMSNotificationEligibility();
    },
    'formData.isHealthWorker'() {
      this.checkSMSNotificationEligibility();
    },
  },

  methods: {
    async getUserPreferences() {
      try {
        let userPreferences = await userService.getUserPreferences();
        this.formData = userPreferences;
      } catch (error) {
        this.error = error;
      }
    },
    async loadIsMFAActive() {
      this.mfa.isLoading = true;
      this.mfa.isActive = await auth.isMFAActivated();
      this.mfa.isLoading = false;
    },

    cancel: function () {
      this.$emit('update:show', false);
    },

    async savePreferences() {
      let isValid = this.$refs.userPreferencesForm.validate();

      if (!isValid || this.isProcessing) return;
      this.changeLanguage(this.formData.userLanguage);
      this.isProcessing = true;

      try {
        let data = {
          emailNotifications: this.formData.emailNotifications,
          SMSNotifications: this.formData.SMSNotifications,
          userLanguage: this.formData.userLanguage,
        };

        await userService.updateUserPreferences(data);
      } catch (error) {
        this.error = error;
      }
      this.isProcessing = false;
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },

    checkSMSNotificationEligibility() {
      if (this.formData.isHealthWorker === true && !this.formData.hasPhoneNumber && this.formData.SMSNotifications) {
        this.showMessage = true;
      } else {
        this.showMessage = false;
      }
    },
  },
};
</script>
