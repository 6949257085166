<template>
  <LineChart
    ref="lineChart"
    :chart-data="chartData"
    :chart-options="chartOptions"
    class="monitoring-details-line-chart"
  />
</template>

<script>
import { Line as LineChart } from 'vue-chartjs';

import ChartJS from './utils/chartConfig.js';

import { LineController, LineElement, PointElement } from 'chart.js';

import {
  BaseChart,
  getChartLegendTriggeredAlertValue,
  getLineChartAxisVisibleTicks,
  monitoringChartDefaultYAxis,
  removeChartTime,
  setChartXLimits,
  showOrHideAnnotationTooltip,
  showOrHideSelectedElement,
  updateAndGetChartThresholdsLegendItems,
  updateAnnotationsTranslations,
  updateChartThresholds,
  updateChartValues,
  updateYAxisLimits,
  updateLineChartXAxisType,
} from './utils/chartUtils';

import { chartTypes } from '@/components/PatientMonitoring/Chart/utils/constants.js';

import MonitoringChartEventBus from './utils/monitoringChartEventBus.js';

import { addHours, addMinutes, differenceInHours } from 'date-fns';
import { chartColor } from '@/components/PatientIotRealtime/Details/chartColor.js';

import translationMixin, { LanguageVue } from '@/translationMixin';

ChartJS.register(LineController, LineElement, PointElement);

export default {
  name: 'MonitoringLineChart',
  components: { LineChart },
  mixins: [translationMixin],

  props: {
    activityCode: { type: String, required: true },
    chartGroup: { type: String, required: true },
    filters: { type: Object, required: true },
    fromNotification: { type: Boolean, default: false },
    isProcessing: { type: Boolean, required: true },
    scrollToView: { type: Boolean, required: true },
    thresholds: { type: Object, required: true },
    uniqueKey: { type: String, required: true },
    values: { type: Array, required: true },
  },

  data() {
    const baseChart = new BaseChart(this.activityCode, chartTypes.monitoringLineChart, {
      chartGroup: this.chartGroup,
      onPanCallback: this.emitMouseMove,
      onZoomCallback: this.reactOnZoom,
      showOrHideSelectedElementCallback: this.showOrHideSelectedElementCallback,
      uniqueKey: this.uniqueKey,
      xAxisCallback: this.updateAxisVisibleTicks,
    });

    return {
      chartData: baseChart.createChartDatasets(),
      chartOptions: baseChart.createChartOptions(),

      chartType: chartTypes.monitoringLineChart,
      error: null,
      isChartHovered: false,
      spacingMinutes: 30,
    };
  },

  watch: {
    filters: function () {
      this.updateXAxisLimits();
    },
    thresholds: function (newThresholds, oldThresholds) {
      updateChartThresholds(
        this.showOrHideAnnotationTooltipCallback,
        this.chartType,
        this.chartData,
        this.chartOptions,
        this.activityCode,
        newThresholds,
        oldThresholds
      );
    },
    values: function (newValues, oldValues) {
      this.updateChartValuesIfNewValues(this.chartData, newValues, oldValues);
    },
  },

  created() {
    MonitoringChartEventBus.$on('mouse-move', this.updateZoom);
    LanguageVue.$on('projectLanguage', this.updateAllChartTranslations);

    this.initializeChartConfig();
    this.initializeChartDatasets();
  },

  mounted() {
    const chartCanvas = this.$refs.lineChart?.chart?.canvas;

    chartCanvas?.addEventListener('mouseenter', () => this.handleMouseEnter());
    chartCanvas?.addEventListener('mouseleave', () => this.handleMouseLeave());

    if (this.scrollToView) {
      this.$el.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  },

  beforeDestroy() {
    const chartCanvas = this.$refs.lineChart?.chart?.canvas;

    chartCanvas?.removeEventListener('mouseenter', () => this.handleMouseEnter());
    chartCanvas?.removeEventListener('mouseleave', () => this.handleMouseLeave());

    MonitoringChartEventBus.$off('mouse-move', this.updateZoom);
    LanguageVue.$off('projectLanguage', this.updateAllChartTranslations);
  },

  methods: {
    updateAllChartTranslations: function () {
      this.$emit('isChartLoading', true);
      this.setTranslations();

      this.chartData.datasets
        ?.filter((dataset) => dataset.legendType === 'threshold')
        ?.forEach((dataset) => {
          dataset.label = this.$t(dataset.untranslatedLabel);
        });

      updateAnnotationsTranslations(this.chartOptions);
      updateLineChartXAxisType(this.chartOptions, this.chartOptions.scales.x.max, this.chartOptions.scales.x.min);

      this.$emit('isChartLoading', false);
    },

    initializeChartConfig: function () {
      this.setTranslations();
      this.updateXAxisLimits();
      updateYAxisLimits(this.chartOptions, monitoringChartDefaultYAxis[this.activityCode]);
    },

    setTranslations: function () {
      this.chartOptions.scales.x.time.displayFormats = {
        hour: this.getLanguage() === 'fr' ? 'HH:mm' : 'h:mm aa',
        minute: this.getLanguage() === 'fr' ? 'HH:mm' : 'h:mm aa',
        second: this.getLanguage() === 'fr' ? 'HH:mm:ss' : 'HH:mm:ss aa',
        millisecond: this.getLanguage() === 'fr' ? 'HH:mm:ss.SSS' : 'HH:mm:ss.SSS aa',
      };

      this.chartOptions.scales.y.title.text = `${this.$t(`${this.activityCode}FullName`)} (${this.$t(
        `${this.activityCode}Unit`
      )})`;
    },

    updateZoom(chart) {
      const position = chart.scales.x;

      updateLineChartXAxisType(this.chartOptions, position.max, position.min);

      this.chartOptions.scales.x.min = position.min;
      this.chartOptions.scales.x.max = position.max;

      this.chartOptions.scales.x2.min = position.min;
      this.chartOptions.scales.x2.max = position.max;

      this.showPointRadiusOnZoom(chart.scales.x.options);
    },

    updateXAxisLimits: function () {
      const isFilterTypeRange = this.filters?.timeFilterType === 'range';
      const dateTo = this.filters?.dateTo && isFilterTypeRange ? this.filters.dateTo : new Date();

      const dateFrom =
        this.filters?.dateFrom && isFilterTypeRange
          ? this.filters.dateFrom
          : removeChartTime(
              new Date(),
              'hours',
              !this.filters?.timeFilterType || this.filters?.timeFilterType === '24h'
                ? 24
                : this.filters?.timeFilterValue
            );

      let adjustedMinDate;
      let adjustedMaxDate;

      if (this.fromNotification) {
        let parsedMinDate = new Date(dateFrom);
        let parsedMaxDate = new Date(dateTo);

        if (this.filters?.timeFilterType !== '24h') {
          parsedMinDate = addHours(parsedMinDate, 11);
          parsedMaxDate = addHours(parsedMaxDate, -11);
        }

        adjustedMinDate = addMinutes(parsedMinDate, -this.spacingMinutes);
        adjustedMaxDate = addMinutes(parsedMaxDate, this.spacingMinutes);
      } else {
        adjustedMinDate = addMinutes(new Date(dateFrom), -this.spacingMinutes);
        adjustedMaxDate = addMinutes(new Date(dateTo), this.spacingMinutes);
      }

      const xMin = adjustedMinDate;
      const xMax = adjustedMaxDate;

      updateLineChartXAxisType(this.chartOptions, xMax, xMin);

      setChartXLimits(this.chartOptions, xMax, xMin);

      this.chartOptions.plugins.zoom.limits = {
        x: {
          min: new Date(this.chartOptions.scales.x.min).getTime(),
          max: new Date(this.chartOptions.scales.x.max).getTime(),
        },
      };
    },

    initializeChartDatasets() {
      let chartData = this.chartData;

      if (Object.keys(this.thresholds).length) {
        chartData.datasets.push(
          ...updateAndGetChartThresholdsLegendItems(
            this.chartType,
            this.activityCode,
            this.showOrHideAnnotationTooltipCallback,
            this.chartOptions,
            this.thresholds
          )
        );
      }

      chartData.datasets.push(getChartLegendTriggeredAlertValue(this.uniqueKey));

      const values = this.values;

      if (values.length) {
        updateChartValues(chartData, this.chartOptions, this.chartType, this.values, this.activityCode);
      }

      this.chartData.datasets = chartData.datasets;

      if (values.length) {
        this.$emit('isChartLoading', false);
      }
    },

    updateChartValuesIfNewValues: async function (chartData = this.chartData, newValues, oldValues) {
      if (chartData.datasets && JSON.stringify(newValues) !== JSON.stringify(oldValues)) {
        try {
          await updateChartValues(chartData, this.chartOptions, this.chartType, newValues, this.activityCode);
        } catch (error) {
          this.$emit('onError', error);
        }
      }

      this.$emit('isChartLoading', false);
    },

    handleMouseEnter: function () {
      this.isChartHovered = true;
    },
    handleMouseLeave: function () {
      this.isChartHovered = false;
    },

    showPointRadiusOnZoom: function (chartXOptions) {
      if (!chartXOptions || this.values?.length === 0 || !(this.chartData?.datasets?.length > 0)) return;

      const differenceHoursXAxis = differenceInHours(new Date(chartXOptions.max), new Date(chartXOptions.min));
      const timeFilterThreshold = this.filters?.timeFilterValue === 24 ? 6 : 5;

      this.chartData.datasets.forEach((y) => {
        if (y.data?.length > 0) {
          for (let pointIndex = 0; pointIndex < y.pointRadius.length; pointIndex++) {
            if (y.backgroundColor[pointIndex] !== chartColor.incorrectDataColor) {
              y.pointRadius[pointIndex] = differenceHoursXAxis < timeFilterThreshold || y.data.length === 1 ? 1.5 : 0;
            }
          }
        }
      });
    },

    showOrHideAnnotationTooltipCallback: function (data, event, thresholdIndex, showAnnotationTooltip) {
      if (!this.isChartHovered) return;

      showOrHideAnnotationTooltip(this.chartOptions, data, event, thresholdIndex, showAnnotationTooltip);
      this.$refs.lineChart.chart.update('none'); // Need this to update the chart and show the tooltip ('none' to avoid animation)
    },

    showOrHideSelectedElementCallback: function (legendItem) {
      showOrHideSelectedElement(this.chartData, this.chartOptions, legendItem);
    },

    reactOnZoom: function (chart) {
      this.emitMouseMove(chart);
    },

    emitMouseMove: function (chart) {
      MonitoringChartEventBus.$emit('mouse-move', chart);
    },

    updateAxisVisibleTicks: function (time) {
      const visibleXAxis = this.$refs.lineChart.chart?.scales?.x;
      return getLineChartAxisVisibleTicks(visibleXAxis, time);
    },
  },
};
</script>
