<template>
  <EcgChart
    ref="ecgChart"
    :chart-data="chartData"
    :chart-options="chartOptions"
    class="monitoring-details-line-chart"
  />
</template>

<script>
import { Bar as EcgChart } from 'vue-chartjs';

import ChartJS from './utils/chartConfig.js';

import { Decimation, LineController, LineElement } from 'chart.js';

import 'chartjs-adapter-date-fns';
import EventBus from './utils/monitoringChartEventBus.js';

import { addMinutes, differenceInMinutes, format, setSeconds } from 'date-fns';

import { chartTypes } from '@/components/PatientMonitoring/Chart/utils/constants.js';

import iotRealtimeService from '@/services/iotRealtimeService';

import translationMixin, { LanguageVue } from '@/translationMixin';

import {
  BaseChart,
  getBarChartXAxisValues,
  getBarChartX2AxisValues,
  getChartLegendTriggeredAlertValue,
  getLineChartAxisVisibleTicks,
  monitoringChartDefaultYAxis,
  removeChartTime,
  showOrHideSelectedElement,
  updateChartValues,
  updateLineChartXAxisType,
  updateXAxisLimitsForStatChart,
  updateYAxisLimits,
} from './utils/chartUtils';

ChartJS.register(Decimation, LineController, LineElement);

export default {
  name: 'MonitoringElectrocardiogramChart',
  components: { EcgChart },
  mixins: [translationMixin],

  props: {
    activityCode: { type: String, required: true },
    chartGroup: { type: String, required: true },
    duration: { type: String, default: null },
    filters: { type: Object, required: true },
    patientId: { type: Number, required: true },
    scrollToView: { type: Boolean, required: true },
    uniqueKey: { type: String, required: true },
    values: { type: Array, required: true },
  },

  data() {
    const baseChart = new BaseChart(this.activityCode, chartTypes.monitoringLineChart, {
      afterLabelCallback: this.getAfterLabelCallback,
      footerCallback: this.getFooterLabelCallback,
      chartGroup: this.chartGroup,
      onPanCallback: this.reactOnZoom,
      onPanCompleteCallback: this.insertWorkerInQueue,
      onZoomCallback: this.reactOnZoom,
      showOrHideSelectedElementCallback: this.showOrHideSelectedElementCallback,
      uniqueKey: this.uniqueKey,
      xAxisCallback: this.updateAxisVisibleTicks,
      x2AxisCallback: (time) => getBarChartX2AxisValues(time, this.duration),
    });

    return {
      chartData: baseChart.createChartDatasets(),
      chartOptions: baseChart.createChartOptions(),

      chartType: chartTypes.monitoringLineChart,
      ecgMillivoltDivisionConstant: 2621.44, // Biobeat constant to convert data into mV
      ecgWorker: null,
      ecgWorkerPath: '/static/workers/ecgChartWorker.js',
      spacingMinutes: 30,
      workerQueue: [],
    };
  },

  computed: {
    isChartOnLineMode: function () {
      return this.chartData?.datasets?.some((x) => x.type === 'line');
    },
  },

  watch: {
    duration: function (newDuration) {
      this.workerQueue = [];
      this.chartOptions.duration = newDuration;
      updateXAxisLimitsForStatChart(this.chartOptions, this.filters, newDuration);
    },
    filters: function () {
      this.workerQueue = [];
      this.chartData.datasets = this.chartData.datasets.filter((x) => x.type !== 'line');

      this.updateXAxisLimits();
    },
    values: function (newValues, oldValues) {
      this.updateChartValuesIfNewValues(this.chartData, newValues, oldValues);
    },
  },

  created() {
    EventBus.$on('mouse-move', this.updateZoom);
    LanguageVue.$on('projectLanguage', this.updateAllChartTranslations);

    this.initializeChartConfig();
    this.initializeChartDatasets();
  },

  mounted() {
    if (this.scrollToView) {
      this.$el.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  },

  beforeDestroy() {
    EventBus.$off('mouse-move', this.updateZoom);
    LanguageVue.$off('projectLanguage', this.updateAllChartTranslations);
  },

  methods: {
    updateXAxisLimits: function () {
      if (this.duration !== '24h') {
        updateXAxisLimitsForStatChart(this.chartOptions, this.filters, this.duration);
        return;
      }

      const isFilterTypeRange = this.filters?.timeFilterType === 'range';

      const dateTo = this.filters?.dateTo && isFilterTypeRange ? this.filters.dateTo : new Date();

      const dateFrom =
        this.filters?.dateFrom && isFilterTypeRange
          ? this.filters.dateFrom
          : removeChartTime(
              new Date(),
              'hours',
              !this.filters?.timeFilterType || this.filters?.timeFilterType === '24h'
                ? 24
                : this.filters?.timeFilterValue
            );

      let adjustedMinDate;
      let adjustedMaxDate;

      adjustedMinDate = addMinutes(new Date(dateFrom), -this.spacingMinutes);
      adjustedMaxDate = addMinutes(new Date(dateTo), this.spacingMinutes);

      const xMin = adjustedMinDate;
      const xMax = adjustedMaxDate;

      updateLineChartXAxisType(this.chartOptions, xMax, xMin);

      const updateAxis = (axis) => {
        this.chartOptions.scales[axis].min = xMin;
        this.chartOptions.scales[axis].max = xMax;
      };

      updateAxis('x');
      updateAxis('x2');

      this.chartOptions.plugins.zoom.limits = {
        x: {
          min: xMin.getTime(),
          max: xMax.getTime(),
        },
      };
    },

    initializeChartDatasets() {
      let chartData = this.chartData;

      chartData.datasets.push(getChartLegendTriggeredAlertValue(this.uniqueKey));

      const values = this.values;

      if (values.length) {
        updateChartValues(chartData, this.chartOptions, this.chartType, this.values, this.activityCode);
      }

      this.chartData.datasets = chartData.datasets;

      if (values.length) {
        this.$emit('isChartLoading', false);
      }
    },

    initializeChartConfig: function () {
      this.setTranslations();
      this.updateXAxisLimits();
      updateYAxisLimits(this.chartOptions, monitoringChartDefaultYAxis[this.activityCode]);
      this.chartOptions.duration = this.duration;
    },

    updateAllChartTranslations: function () {
      this.$emit('isChartLoading', true);
      this.setTranslations();
      this.$emit('isChartLoading', false);
    },

    setTranslations: function () {
      this.chartOptions.scales.x.time.displayFormats = {
        hour: this.getLanguage() === 'fr' ? 'HH:mm' : 'h:mm aa',
        minute: this.getLanguage() === 'fr' ? 'HH:mm' : 'h:mm aa',
        second: this.getLanguage() === 'fr' ? 'HH:mm:ss' : 'HH:mm:ss aa',
        millisecond: this.getLanguage() === 'fr' ? 'HH:mm:ss.SSS' : 'HH:mm:ss.SSS aa',
      };

      this.chartOptions.scales.y.title.text = `${this.$t(`${this.activityCode}FullName`)} (${this.$t(
        `${this.activityCode}Unit`
      )})`;
    },

    updateChartValuesIfNewValues: async function (chartData = this.chartData, newValues, oldValues) {
      if (chartData.datasets && JSON.stringify(newValues) !== JSON.stringify(oldValues)) {
        try {
          this.workerQueue = [];
          updateChartValues(chartData, this.chartOptions, this.chartType, newValues, this.activityCode);
        } catch (error) {
          this.$emit('onError', error);
        }
      }

      this.$emit('isChartLoading', false);
    },

    updateAxisVisibleTicks: function (time) {
      if (this.duration !== '24h') {
        return getBarChartXAxisValues(time, this.duration, false);
      }

      const visibleXAxis = this.$refs.ecgChart.chart?.scales?.x;
      return getLineChartAxisVisibleTicks(visibleXAxis, time);
    },

    reactOnZoom: function (chart) {
      EventBus.$emit('mouse-move', chart);
    },

    updateZoom: function (chart) {
      const position = chart.scales.x;

      const xMin = position.min;
      const xMax = position.max;

      this.chartOptions.scales.x.min = xMin;
      this.chartOptions.scales.x.max = xMax;

      this.chartOptions.scales.x2.min = xMin;
      this.chartOptions.scales.x2.max = xMax;

      if (this.duration !== '24h') return;

      updateLineChartXAxisType(this.chartOptions, xMax, xMin);

      this.executeWorkerToGetData(differenceInMinutes(new Date(xMax), new Date(xMin)), xMax, xMin);
    },

    executeWorkerToGetData: async function (xAxisdifferenceInMinutes, max, min) {
      // Fetch data if enough zoom else reset the original dataset

      if (xAxisdifferenceInMinutes <= 3) {
        if (this.ecgWorker) {
          return;
        }

        if (!this.chartData.datasets.some((x) => x.legendType === 'data' && x.type === 'bar' && !!x?.data?.length)) {
          return;
        }

        const xMax = new Date(max).getTime();
        const xMin = new Date(setSeconds(new Date(min), 0)).getTime();

        const lineChartIndex = this.chartData.datasets.findIndex((x) => x.legendType === 'data' && x.type === 'line');

        const areDataNotVisible =
          !this.chartData.datasets?.[lineChartIndex] ||
          !this.chartData.datasets?.[lineChartIndex]?.data?.some((data) => xMax < data.x) ||
          !this.chartData.datasets?.[lineChartIndex]?.data?.some((data) => xMin > data.x);

        if (areDataNotVisible) {
          this.processWorkerRequest({ xMax, xMin });
        }
      } else if (this.isChartOnLineMode) {
        const barChartIndex = this.chartData.datasets.findIndex((x) => x.legendType === 'data' && x.type === 'bar');
        this.chartData.datasets = this.chartData.datasets.filter((x) => x.type !== 'line');

        this.chartData.datasets[barChartIndex].hidden = false;
      }
    },

    processWorkerRequest: async function (request) {
      // Async code to avoid an infinite loop when fetching data
      // When Async code is done the worker.onmessage function is executed

      this.$emit('isChartLoading', true);

      const { xMax, xMin } = request;

      this.ecgWorker = new Worker(this.ecgWorkerPath); // Create the worker

      this.ecgWorker.onerror = (error) => {
        this.$emit('onError', error);
        this.ecgWorker.terminate();
        this.ecgWorker = null;
        this.$emit('isChartLoading');
        this.processNextWorkerRequest();
      };

      const data = {
        dateTo: format(new Date(xMax), 'yyyy-MM-dd HH:mm:ss'),
        dateFrom: format(new Date(xMin), 'yyyy-MM-dd HH:mm:ss'),
      };

      try {
        const ecgData = await iotRealtimeService.getPatientEcg(this.patientId, data);

        this.ecgWorker.postMessage({
          newData: ecgData,
          millivoltDivisionConstant: this.ecgMillivoltDivisionConstant,
        });
      } catch (error) {
        this.$emit('onError', error);
        this.processNextWorkerRequest();
        return;
      }

      this.ecgWorker.onmessage = (event) => {
        // If no error in postMessage, this code is executed
        const results = event.data.results;

        const barChartIndex = this.chartData.datasets.findIndex((x) => x.legendType === 'data' && x.type === 'bar');

        this.chartData.datasets[barChartIndex].hidden = true;
        this.chartData.datasets.push(...results);

        this.ecgWorker.terminate();
        this.ecgWorker = null;
        this.$emit('isChartLoading');

        this.processNextWorkerRequest();
      };
    },

    getAfterLabelCallback: function (data) {
      if (this.isChartOnLineMode) return '';

      const minutesTranslation = this.$t(data.raw.count > 1 ? 'minutes' : 'minute');

      return (
        this.$t('valuesNumber') +
        (this.getLanguage() === 'fr' ? ' : ' : ': ') +
        data.raw.count +
        ' ' +
        minutesTranslation.toLowerCase()
      );
    },

    getFooterLabelCallback: function () {
      return this.duration === '24h' && !this.isChartOnLineMode ? this.$t('iotRealtime.zoomInToSeeData') : '';
    },

    insertWorkerInQueue: function (chart) {
      // If there is already a worker in execution, insert this one into the queue

      const position = chart.scales.x;

      const xMin = position.min;
      const xMax = position.max;

      if (this.workerQueue.length > 1) {
        this.workerQueue.slice(this.workerQueue.length - 2);
      }

      this.workerQueue.push({ xMax, xMin });
    },

    showOrHideSelectedElementCallback: function (legendItem) {
      showOrHideSelectedElement(this.chartData, this.chartOptions, legendItem);
    },

    processNextWorkerRequest: function () {
      // If there is another worker we execute the next one

      if (this.workerQueue.length > 0) {
        const nextWorkerRequest = this.workerQueue.shift();
        this.processWorkerRequest(nextWorkerRequest);
      }
    },
  },
};
</script>
