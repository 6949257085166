<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="800px" style="z-index: 999">
      <v-card>
        <ModalTitle :title="$t('monitoringEnd')" />

        <WaitModal :show="showWaitModal" />

        <v-card-text>
          <PatientInfoDetails ref="patientDetails" class="mb-4" :patient-info="patient" />
          <v-form ref="patientMonitoringEndForm" lazy-validation>
            <v-row dense>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <DateTimePickerField
                  ref="startDate"
                  :value="patient.startDate"
                  :label="$t('monitoringStartDate')"
                  picker-type="date"
                  read-only
                  disabled
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <DateTimePickerField
                  ref="endDate"
                  v-model="formData.monitoringEndDate"
                  :label="$t('monitoringEndDate')"
                  picker-type="date"
                  clearable
                  :max-date="new Date()"
                  required
                  :read-only="!!patient.endDate"
                  @clearDate="clearDate"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text>
          <ul>
            <li>
              {{ $t('monitoringEnd.activitiesAndProtocolsWarning') }}
            </li>
            <li>{{ $t('monitoringEnd.connectedDevicesWarning') }}</li>
          </ul>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text :disabled="isProcessing" @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <SaveButton
            :is-loading="isLoading"
            :is-processing="isProcessing"
            :show-wait-modal="showWaitModal"
            :handle-click="submitPatientMonitoringEnd"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import patientService from '@/services/patientService';
import validationRulesMixin from '@/validationRulesMixin';
import SaveButton from '@/components/SaveButton.vue';
import PatientInfoDetails from '@/components/Patient/PatientAssignDetails';

export default {
  name: 'PatientMonitoringEndModal',
  components: { PatientInfoDetails, SaveButton },
  mixins: [translation, accessibility, validationRulesMixin],
  props: {
    patient: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      isProcessing: false,
      isLoading: false,
      formData: {
        monitoringEndDate: null,
      },
      originalFormData: {},
    };
  },
  watch: {
    show() {
      if (this.show) {
        this.init();
      }
    },
  },
  methods: {
    init() {
      this.formData.monitoringEndDate = this.patient.endDate;
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    async submitPatientMonitoringEnd() {
      if (this.isProcessing) {
        return;
      }

      let isFormValid = this.$refs.patientMonitoringEndForm.validate();
      if (!isFormValid) {
        return;
      }

      this.showWaitModal = true;
      this.isProcessing = true;

      try {
        await patientService.updatePatientMonitoringEnd(this.patient.id, {
          monitoringEndDate: this.formData.monitoringEndDate,
        });
        this.closeDialog(true);
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
      this.isProcessing = false;

      this.closeDialog();
    },
    closeDialog: function (refresh) {
      this.$emit('close', refresh);
    },

    clearDate: function () {
      this.formData.monitoringEndDate = null;
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>
